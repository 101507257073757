import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'

const PLUGINS_NAME = 'admin-statistic'

export const getWaitingTicketStatistic = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list-new-ticket`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getWaitingTicketStatisticSummary = async (token, query) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-summary-new-ticket`, {
        ...query
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getWaitingTicketStatisticCsv = async (token, query, page, totalPage) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-csv-new-ticket`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE,
        totalPage: totalPage
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })
    
    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}
