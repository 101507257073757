import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-vlurl'

export const getLink = async (token) => {
    const response = await axios.get(`${host}/${PLUGINS_NAME}/get`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data ? response.data.url : ''
    }
    return ''
}

export const updateLink = async (token, url) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update`, {
        url: url
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return true
    }
    return false
}
