import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getList, deleteTicket } from '../../../../controller/api/ticket'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../Ticket'
import TicketDetail from "../sub-screen/ticket-detail/TicketDetail"
import ImagePreview from '../sub-screen/image-preview/ImagePreview'

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [modalShow, setModalShow] = useState(false)
  
  const [modalImageShow, setModalImageShow] = useState(false)

  const numberRowsRef = useRef([])

  const ticketIdRef = useRef('')

  const gameIdRef = useRef('')

  const gamePeriodIdRef = useRef('')
  
  const imagesRef = useRef([])
  
  const selectedImageRef = useRef('')
  
  const previewTitleRef = useRef('')
  
  const statusRef = useRef(0)

  const rewardRef = useRef(null)

  const getAllFunc = useCallback(async () => {
    const response = await getList(cookies.lottmekong, {
      ticketId: state.ticketId,
      cartId: state.cartId,
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.ticketId, state.cartId, page])

  const deleteTicketFunc = async () => {
    const response = await deleteTicket(cookies.lottmekong, ticketIdRef.current)

    if (response && response.status === 'success') {
      let newData = JSON.parse(JSON.stringify(data))

      newData.list = newData.list.map(it => {
        if (it.id === ticketIdRef.current) {
          it.status = 6
        }
        return it
      })

      setData(newData)

      setModalShow(false)
    }
  }

  const onShowDetailPress = (ticketId, numberRows, gameId, gamePeriodId, status, images, reward) => {
    numberRowsRef.current = numberRows
    ticketIdRef.current = ticketId
    gameIdRef.current = gameId
    gamePeriodIdRef.current = gamePeriodId
    statusRef.current = status
    imagesRef.current = images
    rewardRef.current = reward
    setModalShow(true)
  }

  const showImagePreview = (title, image) => {
    previewTitleRef.current = title

    selectedImageRef.current = image

    setModalImageShow(true)
  }

  useEffect(() => {
    if (state.cartId !== '' || state.ticketId !== '') {
      getAllFunc()
    }
  }, [getAllFunc, state.cartId, state.ticketId])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Mã đơn</th>
              <th scope="col">Mã vé</th>
              <th scope="col">Người chơi</th>
              <th scope="col">Thông tin vé</th>
              <th scope="col">Giá trị</th>
              <th scope="col">Trạng thái</th>
              <th scope="col">Ngày mua</th>
              <th scope="col">Ngày thanh toán</th>
              <th scope="col">Ngày hủy</th>
              <th scope="col">Ngày in</th>
              <th scope="col">Nhân viên</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow onShowDetailPress={onShowDetailPress} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <TicketDetail
        show={modalShow}
        ticket-id={ticketIdRef.current}
        status={statusRef.current}
        game-id={gameIdRef.current}
        rows={numberRowsRef.current}
        images={imagesRef.current}
        reward={rewardRef.current}
        handleDeleteTicket={deleteTicketFunc}
        handleShowImagePreview={showImagePreview}
        onHide={() => setModalShow(false)}
      />
      <ImagePreview
        show={modalImageShow}
        title={previewTitleRef.current}
        image={selectedImageRef.current}
        onHide={() => setModalImageShow(false)}
      />
    </>
  );
};

export default React.memo(_Table)