import React, { useCallback, useRef } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { addPeriods } from "../../../../../controller/api/gamePeriod";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const _onHide = () => {

    props.onHide()

  }

  const _name = useRef('');
  
  const _from = useRef();

  const _handleChangeFrom = useCallback((payload) => {
    _from.current = payload
  }, [])
  
  const _handleChangeName = useCallback((payload) => {
    _name.current = payload.target.value
  }, [])

  const onSubmit = async () => {
    const resp = await addPeriods(cookies.lottmekong, _name.current, 'keno', moment(_from.current).format("DD/MM/YYYY"), '06:10', 95)
    if (resp) {
      window.location.reload()
      _onHide()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm kỳ quay Keno
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={12} className="mb-2">
              <Form.Group id="firstName">
                <Form.Label>Tên kỳ</Form.Label>
                <Form.Control required type="number" placeholder="Nhập tên kỳ" onChange={_handleChangeName} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-2">
              <Form.Label>Ngày</Form.Label>
              <Datetime
                timeFormat={false} 
                onChange={_handleChangeFrom}
                renderInput={(props, openCalendar, closeCalendar) => (
                  <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      value={_from.current ? moment(_from.current).format("DD/MM/YYYY") : ""}
                      placeholder="dd/mm/yyyy"
                      onFocus={openCalendar}
                      onSubmit={closeCalendar}
                      onChange={() => { }} />
                  </InputGroup>
                )}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}
