import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import { useCookies } from "react-cookie";
import { getList } from "../../../controller/api/__loto__/result";
import { calculatePage } from "../../../helper/helper";

export const Context = React.createContext(null);

let next7Days = new Date()

next7Days.setDate(next7Days.getDate() + 7)

const initialData = {
  filter: {
    from: localStorage.getItem('from_date') ? localStorage.getItem('from_date') : new Date(),
    to: localStorage.getItem('to_date') ? localStorage.getItem('to_date') : next7Days,
    channelId: '',
    page: 0
  },
  data: {
    list: [],
    total: 0,
    classicUser: [],
    totalPage: 0
  },
  filterPressCount: 0,
  exportPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    const response = await getList(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
    }, state.filter.page)

    if (response) {
      dispatch({
        type: 'UPDATE_DATA',
        payload: {
          list: response.data,
          total: response.total,
          classicUser: response.classicUser,
          totalPage: calculatePage(response.total)
        }
      })
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.channelId, state.filter.page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {
    document.title = 'Kỳ quay'
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Table />
    </Context.Provider>
  );
};
