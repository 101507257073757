import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import UploadForm from "./partials/UploadForm";
import { useCookies } from "react-cookie";
import { calculatePage, downloadCsv, downloadZip } from "../../../helper/helper";
import { doisoat, getMBDoisoatHistory, downloadDoiSoat, uploadMBTCAuto, uploadMBTCManual, uploadDoiSoat } from "../../../controller/api/mb";
import UploadAutoForm from "./partials/UploadAutoForm";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    page: 0
  },
  data: {
    list: [],
    total: 0,
    totalPage: 0
  },
  loading: false,
  file: null,
  date: null,
  id: null,
  action: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPLOAD_DATA':
      return {
        ...state,
        loading: true,
        file: action.payload.file,
        date: null
      }
    case 'UPLOAD_DATA_AUTO':
      return {
        ...state,
        loading: true,
        date: action.payload.date,
        file: null
      }
    case 'DOI_SOAT':
      return {
        ...state,
        loading: true,
        id: action.payload.id,
        action: 'DOI_SOAT'
      }
    case 'UPLOAD':
      return {
        ...state,
        loading: true,
        id: action.payload.id,
        action: 'UPLOAD'
      }
    case 'DOWNLOAD':
      return {
        ...state,
        loading: true,
        id: action.payload.id,
        action: 'DOWNLOAD'
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    case 'RESET':
      return {
        ...state,
        loading: false,
        file: null,
        date: null,
        id: null,
        action: '',
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {

    const response = await getMBDoisoatHistory(cookies.lottmekong, state.filter.page)

    if (response) {
      dispatch({
        type: 'UPDATE_DATA',
        payload: {
          list: response.data,
          total: response.total,
          totalPage: calculatePage(response.total)
        }
      })
    }
  }, [cookies.lottmekong, state.filter.page])

  const uploadAutoFunc = useCallback(async () => {
    if (state.date) {
      const response = await uploadMBTCAuto(cookies.lottmekong, state.date)

      if (response) {
        getAllFunc()
        dispatch({
          type: 'RESET'
        })
      }
    }

  }, [cookies.lottmekong, state.date, getAllFunc])

  const uploadManualFunc = useCallback(async () => {

    if (state.file) {
      const response = await uploadMBTCManual(cookies.lottmekong, state.file)

      if (response) {
        getAllFunc()
        dispatch({
          type: 'RESET'
        })
      }
    }

  }, [cookies.lottmekong, state.file, getAllFunc])

  const doisoatFunc = useCallback(async () => {
    if (state.id) {
      const response = await doisoat(cookies.lottmekong, state.id)


      if (response) {
        getAllFunc()
        dispatch({
          type: 'RESET'
        })
      }
    }

  }, [cookies.lottmekong, state.id, getAllFunc])

  const downloadDoisoatFunc = useCallback(async () => {
    if (state.id) {
      const response = await downloadDoiSoat(cookies.lottmekong, state.id)

      if (response) {
        getAllFunc()
        dispatch({
          type: 'RESET'
        })
      }
    }

  }, [cookies.lottmekong, state.id, getAllFunc])
  
  const uploadDoisoatFunc = useCallback(async () => {
    if (state.id) {
      const response = await uploadDoiSoat(cookies.lottmekong, state.id)

      if (response) {
        getAllFunc()
        dispatch({
          type: 'RESET'
        })
      }
    }

  }, [cookies.lottmekong, state.id, getAllFunc])

  useEffect(() => {
    if (state.loading) {
      if (state.file) {
        uploadManualFunc()
      } else if (state.date) {
        uploadAutoFunc()
      } else if (state.id) {
        if (state.action === 'DOWNLOAD') {
          downloadDoisoatFunc()
        } else if (state.action === 'UPLOAD') {
          uploadDoisoatFunc()
        } else if (state.action === 'DOI_SOAT') {
          doisoatFunc()
        }
      }
    }
  }, [uploadManualFunc, uploadAutoFunc, doisoatFunc, state.file, state.loading, state.date, state.id, state.action])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filter.page])

  useEffect(() => {
    document.title = "Đối soát MB"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      {state.loading ? <h1 className="text-center">Đang xử lý</h1> :
        <>

          <UploadForm />

          <br />

          <UploadAutoForm />

          <br />

        </>
      }
      <Table />
    </Context.Provider>
  );
};
