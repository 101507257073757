import { Button } from '@themesberg/react-bootstrap';
import React from 'react'

export const TableRow = (props) => {
  const { user, ClassicCartId, ClassicResult, onShowDetailPress, amount, id, ClassicLotteryChannel, ClassicLotteryStorage, status, rewardAmount, rewards, rewardNumbers, ClassicTransactionPayReward = [] } = props;

  let statusName = 'Không trúng'

  switch (status) {
    case 1:
      statusName = 'Đã mua'
      break;
    case 2:
      statusName = 'Đang xử lý'
      break;
    case 3:
      statusName = 'Đã xuất vé'
      break;
    case 4:
      statusName = 'Trúng thưởng'
      break;
    default:
      break;
  }

  if (status === 1) {
    statusName = 'Đã mua'
  }

  return (
    <tr>
      <td>
        <Button variant="info" className="m-1" onClick={() => onShowDetailPress(id, ClassicLotteryStorage, ClassicResult, ClassicLotteryChannel, status, ClassicTransactionPayReward, rewards, rewardNumbers, amount)}>Chi tiết</Button>
      </td>
      <td>{ClassicCartId}</td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td>
        {ClassicLotteryChannel.name}<br />
        {ClassicResult.resultDate}
      </td>
      <td>{ClassicLotteryStorage.numbers}</td>
      <td className="align-items-right text-center">{statusName}</td>
      <td className="align-items-right text-center">{new Intl.NumberFormat('vn-VI', {}).format(amount) || ""}</td>
      <td className="align-items-right text-center">{new Intl.NumberFormat('vn-VI', {}).format(rewardAmount) || ""}</td>
    </tr>
  );
};
