import React, { useCallback, useEffect, useReducer, useState } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import { useCookies } from "react-cookie";
import { searchClassicTicket } from "../../../controller/api/__classic__/ticket";
import { calculatePage } from "../../../helper/helper";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    numbers: '',
    cartId: '',
    page: 0
  },
  data: {
    list: [],
    total: 0,
    totalPage: 0
  },
  filterPressCount: 0,
  exportPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'EXPORT_DATA':
      return {
        ...state,
        exportPressCount: state.exportPressCount + 1
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [showProcessingModal, setShowProcessingModal] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    if (state.filter.cartId.length > 0 || state.filter.numbers.length === 6) {
      const response = await searchClassicTicket(cookies.lottmekong, {
        numbers: state.filter.numbers,
        cartId: state.filter.cartId,
      }, state.filter.page)
  
      if (response) {
        dispatch({
          type: 'UPDATE_DATA',
          payload: {
            list: response.data,
            total: response.total,
            totalPage: calculatePage(response.total)
          }
        })
      }
    }
  }, [cookies.lottmekong, state.filter.page, state.filter.cartId, state.filter.numbers])

  useEffect(() => {

    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {
    if (state.exportPressCount > 0) {
      setShowProcessingModal(true)
    }
  }, [state.exportPressCount])

  useEffect(() => {
    document.title = "Tra cứu"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Table />
    </Context.Provider>
  );
};
