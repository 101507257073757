import moment from 'moment';
import React, {  } from 'react'

export const BankTableRow = (props) => {
  const { netValue, user, createdAt, bank } = props;

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  return (
    <tr className='text-center'>
      <td>
        {(user && user.name) || ""}<br />
        <span ><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className="text-center">
        {new Intl.NumberFormat('vn-VI', {}).format(netValue) || ""}
      </td>
      <td className='text-center'>
        <img alt='' src={bank.logo} className="img-thumbnail" width={80} height={40}></img>
      </td>
      <td>{datedString}</td>
    </tr>
  );
}