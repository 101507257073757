import axios from 'axios'
import { host } from '../../../helper/config'

const PLUGINS_NAME = 'admin-loto-channel'

export const getAllChannel = async (token) => {
    const response = await axios.get(`${host}/${PLUGINS_NAME}/get-list`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}
