import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import { AddBannerForm } from "./partials/AddBannerForm";
import { useCookies } from "react-cookie";
import { add, uploadBanner } from "../../../../../controller/api/banner";
import { storage } from "../../../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [progress, setProgress] = useState(0);

  const [error, setError] = useState(false)

  const [imageUrl, setImageUrl] = useState('')

  const imageRef = useRef('')

  const onImageChange = (image) => {
    imageRef.current = image
  }

  const validate = async () => {

    const uploaded = await uploadBanner(imageRef.current, `banner${new Date().getTime()}`)

    const storageRef = ref(storage, `banners/${imageRef.current.name}`) //storage.ref(`banners/${imageRef.current.name}`).put(imageRef.current)
    const uploadTask = uploadBytesResumable(storageRef, imageRef.current)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setImageUrl(downloadURL)
        });
      }
    )
  }

  const onSubmit = useCallback(async (imgUrl) => {
    try {
      const resp = await add(cookies.lottmekong, imgUrl, 1)
      if (resp) {
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } catch (e) {
      setError(true)
      alert('error')
    }
  }, [cookies.lottmekong])

  useEffect(() => {
    if (imageUrl.length > 0) {
      onSubmit(imageUrl)
    }
  }, [imageUrl, onSubmit])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm quảng cáo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <AddBannerForm
          setImage={onImageChange}
        />
        <hr />
        <h6>Uploading done {progress}%</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={validate}>Thêm quảng cáo</Button>
      </Modal.Footer>
    </Modal>
  );
}
