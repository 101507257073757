import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../Cart'
import { getSummary } from '../../../../controller/api/__loto__/cart'
import { useCookies } from 'react-cookie';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [summary, setSummary] = useState({
    total: 0,
    count: 0,
    totalApp: 0,
    countApp: 0,
    totalWeb: 0,
    countWeb: 0
  })

  const getSummaryFunc = useCallback(async () => {

    const response = await getSummary(cookies.lottmekong, {
      isDone: 0,
    })

    if (response) {
      const res = {
        count: response.count,
        total: response.total,
        countApp: response.countApp,
        totalApp: response.totalApp,
        countWeb: response.countWeb,
        totalWeb: response.totalWeb
      }
      setSummary(res || {})
    }
  }, [cookies.lottmekong])

  const listSumary = useMemo(() => {
    return (
      <Row>
        <Col md={4} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Tổng vé"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.count) || ""}</Form.Label>
          </Row>
        </Col>
        <Col md={4} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"APP"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.countApp) || ""}</Form.Label>
          </Row>
        </Col>
        <Col md={4} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"WEB"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.countWeb) || ""}</Form.Label>
          </Row>
        </Col>
        <Col md={4} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Tổng giá trị"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(parseInt(summary.total)) || ""}</Form.Label>
          </Row>
        </Col>
        <Col md={4} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"APP"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(parseInt(summary.totalApp)) || ""}</Form.Label>
          </Row>
        </Col>
        <Col md={4} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"WEB"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(parseInt(summary.totalWeb)) || ""}</Form.Label>
          </Row>
        </Col>
      </Row>
    )
  }, [summary])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row>
            {listSumary}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)