import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Table, Button } from '@themesberg/react-bootstrap'
import { getAll, deleteNotification } from '../../../../controller/api/notification'
import AddNotification from '../sub-screen/add-notification/AddNotification';
import { useCookies } from 'react-cookie';

const TableRow = (props) => {
  const { message, title, user } = props;

  return (
    <tr>
      {/* <td style={{ width: 150 }}>
        <Button variant="danger" className="m-1" onClick={() => onDelete(id)}>Xóa thông báo</Button>
      </td> */}
      <th style={{ width: 300 }}>{title || "Không có tiêu đề"}</th>
      <td>{message || "Không có nội dung"}</td>
      <td style={{ width: 150 }}>
        {
          !user ? 'Tất cả' : user.phone.replace('+84', '0')
        }
      </td>
    </tr>
  );
};

export const NotificationTable = () => {

  const [listNotification, setListNotification] = useState({})

  const [cookies] = useCookies(['lottmekong'])

  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    const getAllNotificationFunc = async () => {
      const list = await getAll(cookies.lottmekong)

      if (list) {
        const res = {
          data: list.data,
          total: list.total,
          isOff: list.data.filter(item => item.status === 0).length,
          isOn: list.data.filter(item => item.status === 1).length,
        }
        setListNotification(res || {})
      }
    }
    getAllNotificationFunc()
  }, [cookies.lottmekong])

  const onAddNewNotification = async () => {
    setModalShow(true)
  }

  const onDelete = async (id) => {
    const resp = await deleteNotification(cookies.lottmekong, id)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Thông báo ({listNotification.total})</h5>
            </Col>
            <Col className="text-end">
              <Button variant="primary" className="m-1" onClick={onAddNewNotification}>Thêm thông báo</Button>
              {/* <Button variant="danger" className="m-1" onClick={onDeleteAll}>Xóa tất cả thông báo</Button> */}
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              {/* <th scope="col">Thao tác</th> */}
              <th scope="col">Tiêu đề</th>
              <th scope="col">Nội dung</th>
              <th scope="col">Đối tượng thông báo</th>
            </tr>
          </thead>
          <tbody>
            {listNotification.data && listNotification.data.map(pv => <TableRow onDelete={onDelete} key={`Notification-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
      <AddNotification
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};