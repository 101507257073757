import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Form, Modal, Table } from '@themesberg/react-bootstrap';
import { updatePeriodTicket } from "../../../../../controller/api/ticket";

export default (props) => {

  const ticketId = props.ticketId

  const periodIdRef = useRef(props.periodId.split('-')[1])

  const [cookies] = useCookies(['lottmekong'])

  const [status, setStatus] = useState(-1)

  const paidPress = async () => {
    setStatus(-1)
    await handlePaid()
  }

  const handlePaid = async () => {
    const response = await updatePeriodTicket(cookies.lottmekong, ticketId, props.periodId, `keno-${periodIdRef.current}`)

    if (response) {
      setStatus(1)
    } else {
      setStatus(2)
    }
  }

  useEffect(() => {
    if (status == 1) {
      props.onHide(status)
    }
  }, [status])

  useEffect(() => {
    if (props.show) {
      periodIdRef.current = props.periodId.split('-')[1]
      setStatus(0)
    } else {
      periodIdRef.current = ''
      setStatus(-1)
    }
  }, [props.show, props.periodId])

  const onChangePeriod = (evt) => {
    periodIdRef.current = evt.target.value
  }

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {`${ticketId}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive className="align-items-center table-bordered table-hover">

          <tbody>
            <tr>
              <td className="text-center"><b>KỲ QUAY</b></td>
              <td className={"text-center"}>
                <Form.Control required type="text" defaultValue={periodIdRef.current} onChange={onChangePeriod} />
              </td>
            </tr>
          </tbody>
        </Table>

      </Modal.Body>
      <Modal.Footer>
        <b>{status === 1 ? 'Xong' : status === 2 ? 'Không thành công' : status === -1 ? 'Đang xử lý' : ''}</b> &nbsp;
        <Button variant="success" onClick={paidPress}>Xác nhận</Button>
        <Button variant="danger" onClick={() => {
          props.onHide(status)
        }}
        >Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
