import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { id, value, method, user, status, message, createdAt, accountName, accountNumber, Bank } = props;

  let methodName = <></>

  if (method === 'GPAY') {
    methodName = <td className='text-center'>
      GPAY
    </td>
  } else if (method === 'BANKING') {
    let bankName = ''
    let bankAccountNumber = ''
    let bankAccountName = ''
    if (Bank) {
      bankName = Bank.shortName
    } else if (user.bank) {
      bankName = user.bank.shortName
    }
    bankAccountName = accountName || user.bankAccountName
    bankAccountNumber = accountNumber || user.bankAccountNumber

    methodName = (
      <td className='text-center'>
        <span className={`fw-900`}>Ngân hàng: <strong>{bankName || ""}</strong></span><br />
        <span>Chủ tài khoản: <strong>{bankAccountName || ""}</strong></span><br />
        <span >Số tài khoản: <strong className={`text-success`}>{bankAccountNumber || ""}</strong></span>
      </td>
    )
  }

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  return (
    <tr className='text-center'>
      {status === 1 ?
        <td className='text-center'>
          <FontAwesomeIcon className='text-success' icon={faCheckCircle} size="4x" />
        </td>
        :
        <td className='text-center'>
          <FontAwesomeIcon className='text-danger' icon={faMinusCircle} size="4x" />
        </td>
      }
      <td className="text-left">
        <strong className={`text-primary`}>Số tiền:</strong> {new Intl.NumberFormat('vn-VI', {}).format(value) || ""}<br />
        <span ><strong className={`text-primary`}>Phí:</strong> {new Intl.NumberFormat('vn-VI', {}).format(value * 0 / 100) || ""}</span> <br />
        <span ><strong className={`text-primary`}>Thực nhận:</strong> {new Intl.NumberFormat('vn-VI', {}).format(value - value * 0 / 100) || ""}</span> <br />
      </td>
      <td>
        <span ><strong className={`text-primary`}>{(user && user.endPointId) || ""}</strong></span><br />
        {(user && user.name) || ""}<br />
        <span ><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      {methodName}
      <td>{message || ''}</td>
      <td>{datedString}</td>
    </tr>
  );
};