import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-withdraw-request'

export const getList = async (token, query, page, endPointId) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE,
            endPointId: endPointId
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getListConfirmed = async (token, query, page, endPointId) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/get-list-confirmed`,
        {
            ...query,
            page: page,
            itemPerPage: ITEM_PER_PAGE,
            endPointId: endPointId
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const update = async (token, id, status, message) => {
    const response = await axios.post(
        `${host}/${PLUGINS_NAME}/update`,
        {
            id: id,
            status: status,
            message: message
        },
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    )

    if (response && response.status === 200) {
        return response.data
    }
    return null
}
