
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Form, Button, Alert } from '@themesberg/react-bootstrap';
import { getUserByPhone } from "../../../../../controller/api/user";
import { useCookies } from "react-cookie";
import { addRefund } from "../../../../../controller/api/refund";
import CurrencyInput from 'react-currency-input-field';

export const AddRefundForm = () => {

  const [cookies] = useCookies(['lottmekong'])

  const [detectedUser, setDetectedUser] = useState(null)

  const [reason, setReason] = useState('')

  const valueRef = useRef('')

  const reasonRef = useRef('')

  const getUser = async (evt) => {
    if (evt.target.value.length === 10) {
      const phone = evt.target.value

      const response = await getUserByPhone(cookies.lottmekong, phone, 'APP')

      if (response) {
        setDetectedUser(response)
      }
    } else if (evt.target.value.length > 10) {
      return false
    }
  }

  const onValueChange = (value) => {
    valueRef.current = value
  }

  const onReasonChange = (evt) => {
    setReason(evt.target.value)
  }

  const onOtherReasonChange = (evt) => {
    reasonRef.current = evt.target.value
  }

  useEffect(() => {
    if (reason !== 'OTHER' && reason !== '') {
      reasonRef.current = reason
    }
  }, [reason])

  const onCommitCharge = async () => {
    if (validate()) {
      const resp = await addRefund(cookies.lottmekong, valueRef.current, reasonRef.current, detectedUser.id)
      if (resp) {
        alert('Đã thu hồi thành công')
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } else {
      alert('Dữ liệu ko đúng')
      return false
    }
  }

  const validate = () => {
    if (valueRef.current.length > 0 && detectedUser && parseInt(detectedUser.amount) > 0 && reasonRef.current.length > 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Thu hồi tiền</h5>
        <Form>
          <Row>
            <Col md={12} className="mb-2">
              <Form.Group id="firstName">
                <Form.Label>Số điện thoại</Form.Label>
                <Form.Control required type="text" placeholder="Nhập số điện thoại" onChange={getUser} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          </Row>
          {detectedUser &&
            <Alert variant="success">
              {detectedUser.name} - Số dư hiện tại: {new Intl.NumberFormat('vn-VI', {}).format(detectedUser.amount)}
            </Alert>
          }
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Số tiền</Form.Label>
                <CurrencyInput
                  className="form-control"

                  id="input-example"
                  name="input-name"
                  placeholder="Nhập số tiền"
                  min={0}
                  decimalsLimit={3}
                  onValueChange={(value) => onValueChange(value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Lý do hoàn tiền</Form.Label>
                <Form.Select id="account-id" defaultValue={0} onChange={onReasonChange}>
                  <option value={''}>Chọn lý do thu hồi</option>
                  <option value={'CUSTOMER_REQUIRED'}>Khách hàng yêu cầu</option>
                  <option value={'WRONG_CHARGE'}>Nhầm lẫn trong quá trình nạp tiền</option>
                  <option value={'OTHER'}>Lý do khác</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {reason === 'OTHER' &&
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group id="addressNumber">
                  <Form.Label>Lý do khác</Form.Label>
                  <Form.Control required type="text" placeholder="Nhập lý do" onChange={onOtherReasonChange} />
                </Form.Group>
              </Col>
            </Row>
          }
          <div className="mt-3">
            <Button variant="primary" type="button" onClick={onCommitCharge}>Thu hồi</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};