import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-results'

// keno
export const payReward = async (token, transId, method, bankId, accountName, accountNumber) => {
    const response = await axios.post(`${host}/admin-statistic/sumit-big-reward`, {
        id: transId,
        method: method,
        bankId: bankId,
        accountName: accountName,
        accountNumber: accountNumber
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200 && response.data.status === 1) {
        return true
    }
    return false
}

export const paySmallReward = async (token, transId, userId) => {
    const response = await axios.post(`${host}/admin-results/pay-reward`, {
        transId: transId,
        userId: userId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200 && response.data == 'done') {
        return true
    }
    return false
}

export const payAllSmallReward = async (token) => {
    const response = await axios.post(`${host}/admin-results/pay-reward-all`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200 && response.data == 'done') {
        return true
    }
    return false
}

// keno
export const calculateKeno = async (token, numberTickets, periodId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward-keno`, {
        periodId: periodId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// max3d
export const calculateMax3d = async (token, numberTickets, periodId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward-max3d`, {
        periodId: periodId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// max3dpro
export const calculateMax3dPro = async (token, numberTickets, periodId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward-max3dpro`, {
        periodId: periodId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// mega
export const calculateMega = async (token, numberTickets, periodId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward-mega`, {
        periodId: periodId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// power
export const calculatePower = async (token, numberTickets, periodId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/calculate-reward-power`, {
        periodId: periodId,
        numberTickets: numberTickets
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}
