import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap';
import { getAllBanks } from "../../../../../controller/api/bank";
import { payLotoReward } from "../../../../../controller/api/__loto__/result";

export default (props) => {

  const phone = props.phone
  const transId = props.transId
  const ticketId = props.ticketId

  const [cookies] = useCookies(['lottmekong'])

  const [status, setStatus] = useState(0)

  const [method, setMethod] = useState('REWARD')

  const [listBank, setListBank] = useState([])

  const selectedBank = useRef(0)
  
  const accountNameRef = useRef('')
  
  const accountNumberRef = useRef('')

  const paidPress = async () => {
    setStatus(-1)
    await handlePaid()
  }

  const handlePaid = async () => {
    const response = await payLotoReward(cookies.lottmekong, transId, method, parseInt(selectedBank.current), accountNameRef.current, accountNumberRef.current)

    if (response) {
      setStatus(1)
    } else {
      setStatus(2)
    }
  }

  const onAccountNameChange = (evt) => {
    accountNameRef.current = evt.target.value
  }

  const onAccountNumberChange = (evt) => {
    accountNumberRef.current = evt.target.value
  }

  const onMethodSelect = (evt) => {
    setMethod(evt.target.value)
  }

  const onBankSelect = (evt) => {
    selectedBank.current = evt.target.value;
  }

  useEffect(() => {
    if (props.show) {
      setStatus(0)
    }
  }, [props.show])

  useEffect(() => {
    const getParamsFunc = async () => {
      const response = await getAllBanks(cookies.lottmekong, 0)

      if (response && response.data && response.data.length > 0) {
        const res = {
          data: response.data,
          total: response.total
        }
      
        setListBank(res.data || [])
      }
    }
    getParamsFunc()
  }, [props, cookies.lottmekong])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {`${phone} - ${ticketId}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3} className='text-left align-self-center'>
            <Form.Label>Hình thức</Form.Label>
          </Col>
          <Col md={9}>
            <Form.Select onChange={onMethodSelect}>
              <option defaultValue value={'REWARD'}>Chuyển về ví thưởng</option>
              <option value={'INTERNAL'}>Chuyển về ví chơi</option>
              <option value={'BANKING'}>Ngân hàng</option>
            </Form.Select>
          </Col>
        </Row>
        {method === 'BANKING' &&
          <>
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Chọn ngân hàng</Form.Label>
                  <Form.Select id="bank-id" defaultValue={0} onChange={onBankSelect}>
                    {listBank.map(item => <option key={item.id} value={item.id}>{item.shortName}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Tên tài khoản</Form.Label>
                  <Form.Control type="text" placeholder="NGUYEN VAN A" onChange={onAccountNameChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group id="addressNumber">
                  <Form.Label>Số tài khoản</Form.Label>
                  <Form.Control type="text" placeholder="1234567890" onChange={onAccountNumberChange} />
                </Form.Group>
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <b>{status === 1 ? 'Xong' : status === 2 ? 'Không thành công' : status === -1 ? 'Đang xử lý' : ''}</b> &nbsp;
        <Button variant="success" onClick={paidPress}>Trả thưởng</Button>
        <Button variant="danger" onClick={() => {
          props.onHide(status)
        }}
        >Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
