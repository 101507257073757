
import React, { useEffect, useReducer } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";

export const Context = React.createContext(null);

const initialFilterData = {
  from: new Date(),
  to: new Date(),
  gameId: '',
  phone: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialFilterData);

  useEffect(() => {
    document.title = "Đơn hàng"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Table />
    </Context.Provider>
  );
};
