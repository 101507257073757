
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Form, Button, Alert } from '@themesberg/react-bootstrap';
import { getAllAccounts } from "../../../../../controller/api/account";
import { getUserByPhone } from "../../../../../controller/api/user";
import { useCookies } from "react-cookie";
import { addCharge } from "../../../../../controller/api/charge";
import CurrencyInput from 'react-currency-input-field';

export const AddChargeForm = () => {

  const [cookies] = useCookies(['lottmekong'])

  const [listAccount, setListAccount] = useState([])

  const [detectedUser, setDetectedUser] = useState(null)

  const valueRef = useRef('')

  const refNumberRef = useRef('')

  const accountRef = useRef(-1)

  useEffect(() => {
    const getParamsFunc = async () => {
      const response = await getAllAccounts(cookies.lottmekong)

      if (response && response.data && response.data.length > 0) {
        const res = {
          data: response.data,
          total: response.total
        }
        accountRef.current = res.data[0].id
        setListAccount(
          [
            ...res.data,
            {
              id: 0,
              bank: {
                shortName: 'GPAY'
              }
            }
          ] || [])
      }
    }
    getParamsFunc()
  }, [cookies.lottmekong])

  const getUser = async (evt) => {
    if (evt.target.value.length === 10) {
      const phone = evt.target.value

      const response = await getUserByPhone(cookies.lottmekong, phone, 'APP')

      if (response) {
        setDetectedUser(response)
      }
    } else if (evt.target.value.length > 10) {
      return false
    }
  }

  const onValueChange = (value) => {
    valueRef.current = value
  }

  const onRefNumberChange = (evt) => {
    refNumberRef.current = evt.target.value
  }

  const onAccountSelect = (evt) => {
    accountRef.current = evt.target.value
  }

  const onCommitCharge = async () => {
    if (validate()) {
      const defaultMethod = accountRef.current === '0' ? 'ATM' : 'BANKING'

      const resp = await addCharge(cookies.lottmekong, valueRef.current, defaultMethod, accountRef.current, detectedUser.id, refNumberRef.current)
      if (resp) {
        alert('Đã nạp tiền thành công')
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } else {
      alert('Dữ liệu ko đúng')
      return false
    }
  }

  const validate = () => {
    if (accountRef.current > -1 && valueRef.current.length > 0 && detectedUser) {
      return true
    } else {
      return false
    }
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Nạp tiền App</h5>
        <Form>
          <Row>
            <Col md={12} className="mb-2">
              <Form.Group id="firstName">
                <Form.Label>Số điện thoại</Form.Label>
                <Form.Control required type="text" placeholder="Nhập số điện thoại" onChange={getUser} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          </Row>
          {detectedUser &&
            <Alert variant="success">
              {detectedUser.name} - {detectedUser.phone.replace('+84', '0')}
            </Alert>
          }
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Tài khoản công ty</Form.Label>
                <Form.Select id="account-id" defaultValue={accountRef.current} onChange={onAccountSelect}>
                  {listAccount.map(item => <option key={item.id} value={item.id}>{item.bank.shortName}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Nội dung</Form.Label>
                <Form.Control type="text" placeholder="Nhập nội dung" onChange={onRefNumberChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Số tiền</Form.Label>
                <CurrencyInput
                  className="form-control"

                  id="input-example"
                  name="input-name"
                  placeholder="Nhập số tiền"
                  min={0}
                  decimalsLimit={3}
                  onValueChange={(value) => onValueChange(value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3">
            <Button variant="primary" type="button" onClick={onCommitCharge}>Nạp tiền</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};