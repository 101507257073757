import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { userName, userPhone, numbers, cartId, value, status, confirmAt, lotoType } = props;

  return (
    <tr>
      <td>
        {userName || ""}<br />
        <span><strong className={`text-primary`}>{userPhone || ""}</strong></span>
      </td>
      <td className='text-center'>{cartId}</td>
      <td className='text-center'><strong>{lotoType}</strong></td>
      <td className='text-center'><strong>{numbers.split('').join(' ')}</strong></td>
      <td className='text-center'>{new Intl.NumberFormat('vn-VI', {}).format(value)}</td>
      <td className={`text-center${status === 4 ? ' text-danger' : ''}`}>{status === 4 ? 'Trúng thưởng' : 'Không trúng'}</td>
      <td className='text-center'>{moment(confirmAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
    </tr>
  );
};