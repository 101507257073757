import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import { useCookies } from "react-cookie";
import { calculatePage, parseQuery } from "../../../helper/helper";
import { useLocation } from "react-router-dom";
import { getEwalletAndPaymentGatewayStatisticList } from '../../../controller/api/statistic/ewalletAndPaymentGateway';

export const Context = React.createContext(null);

const initialData = {
  filter: {
    from: new Date(),
    to: new Date(),
    method: '',
    subMethod: '',
    page: 0
  },
  data: {
    // totalValue: '0',
    // count: 0,
    list: [],
    total: 0,
    totalPage: 0
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    // case 'UPDATE_SUMMARY':
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       count: action.count,
    //       totalValue: action.totalValue
    //     }
    //   }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const location = useLocation();

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    if (state.filter.method) {
      const response = await getEwalletAndPaymentGatewayStatisticList(cookies.lottmekong, {
        from: state.filter.from,
        to: state.filter.to,
        method: state.filter.method,
        subMethod: state.filter.subMethod,
      }, state.filter.page)
  
      if (response) {
        dispatch({
          type: 'UPDATE_DATA',
          payload: {
            list: response.res,
            total: response.count[0].count_by_method,
            totalPage: calculatePage(response.count[0].count_by_method)
          }
        })
      }
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.page, state.filter.methodId, state.filter.phone, state.filter.gameId])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filter.page])

  useEffect(() => {
    
    const queryObj = parseQuery(location.search);

    if (queryObj) {
      // dispatch({
      //   type: 'UPDATE_SUMMARY',
      //   count: queryObj.count,
      //   totalValue: queryObj.totalValue
      // })

      dispatch({
        type: 'SUBMIT_FILTER',
        payload: {
          from: queryObj.from,
          to: queryObj.to,
          method: queryObj.method,
          subMethod: queryObj.subMethod
        }
      })
    }

  }, [location.search]) // not dependency of state => avoid loop forever

  useEffect(() => {
    document.title = "Chi tiết"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>
      {/* <Summary /> */}
      {/* <br /> */}
      <Table />
    </Context.Provider>
  );
};
