import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'

const PLUGINS_NAME = 'admin-statistic'

export const getWithdrawStatistic = async (token, query, page, endPointId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list-withdraw`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE,
        endPointId: endPointId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getWithdrawStatisticSummary = async (token, query, endPointId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-summary-withdraw`, {
        ...query,
        endPointId: endPointId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getWithdrawStatisticCsv = async (token, query, page, totalPage, endPointId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-csv-withdraw`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE,
        totalPage: totalPage,
        endPointId: endPointId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

