import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-game-results'

// count-printed-ticket
export const getCountTicket = async (token, gamePeriodId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/count-printed-ticket`, {
        gamePeriodId: gamePeriodId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data.count || 0
    }
    return 0
}

// confirm-number-ticket
export const confirmNumberTicket = async (token, gamePeriodId, numberTicket) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/confirm-number-ticket`, {
        gamePeriodId: gamePeriodId,
        numberTicket: numberTicket
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return true
    }
    return false
}

// keno
export const getKenoResults = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-keno`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addKenoResults = async (token, periodId, numbers) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-keno`, {
        periodId: periodId,
        numbers: numbers
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// max3d
export const getMax3dResults = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-max3d`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addMax3dResults = async (token, periodId, numbers) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-max3d`, {
        periodId: periodId,
        numbers: numbers
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// max3dpro
export const getMax3dProResults = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-max3dpro`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addMax3dProResults = async (token, periodId, numbers) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-max3dpro`, {
        periodId: periodId,
        numbers: numbers
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// mega
export const getMegaResults = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-mega`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// power
export const getPowerResults = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-power`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}
