import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-notification'

export const getAll = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-all`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addNewToUser = async (token, title, message, phone, userId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/to-user`, {
        userId: userId,
        phone: phone,
        title: title,
        message: message
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const addNewToAll = async (token, title, message) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/to-all`, {
        title: title,
        message: message
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const deleteNotification = async (token, id) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const deleteAllNotification = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete-all`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}
