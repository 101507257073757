import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const notHaveResultYet = <td className="text-warning text-center">{"Chưa có kết quả"}</td>

const cannotGetResult = <td className="text-danger text-center">{"Không lấy được kết quả"}</td>

export const TableRow = (props) => {
  const { id, resultDate, ClassicLotteryChannel, LotoTicket, totalLotoTickets, totalRewaredLotoTickets, totalUnRewardLotoTickets, result, status, onCalculateRewardPress, handleAddResult } = props;

  const [cookies] = useCookies(['lottmekong'])

  const linkToStatistic = `/loto/statistic-by-ticket?resultId="${id}"`

  const [statusState, setStatusState] = useState(status)

  const [resultData, setResultData] = useState(result)

  const viewResult = (<td className="text-center">
    <Button variant='info' onClick={() => handleAddResult(id, resultData)}>
      Xem kết quả
    </Button>
  </td>);

  const resultTd = useMemo(() => {
    let _resultTd = notHaveResultYet

    if (statusState === 1) {
      _resultTd = 'Chưa có kết quả'
    } else if (statusState === 3 || statusState === 4 || statusState === 5) {
      if (resultData && resultData.length > 0) {
        _resultTd = viewResult
      } else {
        _resultTd = cannotGetResult
      }
    }

    return _resultTd
  }, [statusState, resultData, viewResult])

  let newAmount = 0
  let doneAmount = 0

  LotoTicket.forEach(element => {
    if (element.status === 1 || element.status === 2) {
      newAmount += 1
    } else {
      doneAmount += 1
    }
  });

  const actionTd = useMemo(() => {
    switch (statusState) {
      case 2:
        return 'Đang chờ kết quả'
      case 3:
      case 4:
      case 5:
        if (LotoTicket.length === 0) {
          return 'Không kinh doanh'
        }
        if (totalRewaredLotoTickets + totalUnRewardLotoTickets < LotoTicket.length) {
          return <Button variant="primary" className="m-1" onClick={() => onCalculateRewardPress(id)}>Tính thưởng</Button>
        }
        return (
          <>
            <b className='text-success'>{totalRewaredLotoTickets}</b> | <b className='text-danger'>{totalUnRewardLotoTickets}</b><br />
            <Button variant="warning" className="m-1">
              <Link
                target={'_blank'}
                style={{ color: '#fff' }}
                to={linkToStatistic}
                key={id}
              >
                {'Xem chi tiết'}
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
              </Link>
            </Button>
          </>
        )
      default:
        return '';
    }
  }, [statusState, ClassicLotteryChannel.name, id, linkToStatistic, onCalculateRewardPress, resultDate])

  return (
    <tr>
      <td className="text-center">
        {actionTd}
      </td>
      <td className="text-center">
        {ClassicLotteryChannel.name}<br />
        {resultDate}
      </td>
      {resultTd}
      <>
        <td className="text-center">
          <b>Tổng cộng: {totalLotoTickets}</b> <br />
          Mới: {newAmount} <br />
          Đã hoàn thành: {doneAmount}
        </td>
      </>
    </tr>
  );
};