
import React, { useEffect, useReducer } from "react";

import Table from "./partials/Table";
import { parseQuery } from "../../../helper/helper";
import { useLocation } from 'react-router-dom'

export const Context = React.createContext(null);

const initialFilterData = {
  inputId: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialFilterData);

  const location = useLocation();

  useEffect(() => {
    const queryObj = parseQuery(location.search);
    if (queryObj.inputId) {
      dispatch({
        type: 'SUBMIT_FILTER',
        payload: {
          ...state,
          inputId: queryObj.inputId
        }
      })
    }

  }, [location.search]) // not dependency of state => avoid loop forever

  useEffect(() => {
    document.title = "Vé"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>
      <Table />
    </Context.Provider>
  );
};
