import React, { useCallback, useContext, useRef } from 'react'
import { Col, Row, Card, Form, Container, Button, InputGroup } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { Context } from '../Period'

const _FilterForm = ({region}) => {

  const { state, dispatch } = useContext(Context);

  const _from = useRef(localStorage.getItem('from_date') ? localStorage.getItem('from_date')  : state.filter.from);
  const _to = useRef(localStorage.getItem('to_date') ? localStorage.getItem('to_date') : state.filter.to);
  const _channelId = useRef(state.filter.channelId);

  const _handleChangeFrom = useCallback((payload) => {
    localStorage.setItem('from_date', payload)
    _from.current = payload
  }, [])

  const _handleChangeTo = useCallback((payload) => {
    localStorage.setItem('to_date', payload)
    _to.current = payload
  }, [])

  const _submitForm = useCallback(() => {
    dispatch({
      type: 'SUBMIT_FILTER',
      payload: {
        from: _from.current,
        to: _to.current,
        channelId: _channelId.current
      }
    })
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>{region === 'MN' ? 'Kỳ quay Miền Nam' : 'Kỳ quay Miền Trung'}</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tìm kiếm</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Col>
              <Row className='p-1'>
                <Col>
                  <Row>
                    <Col md={3} className='text-right align-self-center'>
                      <Form.Label>Từ ngày</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Datetime
                        timeFormat={false}
                        onChange={_handleChangeFrom}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={_from.current ? moment(_from.current).format("DD/MM/YYYY") : ""}
                              placeholder="dd/mm/yyyy"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md={3} className='text-right align-self-center'>
                      <Form.Label>Đến ngày</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Datetime
                        timeFormat={false}
                        onChange={_handleChangeTo}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={_to.current ? moment(_to.current).format("DD/MM/YYYY") : ""}
                              placeholder="dd/mm/yyyy"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)