import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../Cart'
import { getSummaryClassicCart } from '../../../../controller/api/__classic__/cart'
import { useCookies } from 'react-cookie';

const _Summary = ({ region }) => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [summary, setSummary] = useState({
    total: 0,
    data: []
  })

  const getSummaryFunc = useCallback(async () => {

    const response = await getSummaryClassicCart(cookies.lottmekong, {
      isDone: 0,
      region: region
    })

    if (response) {
      const res = {
        total: response.total,
        data: response.data
      }
      setSummary(res || {})
    }
  }, [cookies.lottmekong, region])

  const listSumary = useMemo(() => {
    return (
      <Row>
        <Col md={12} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Tổng vé"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.total) || ""}</Form.Label>
          </Row>
        </Col>
        {summary.data.map(it => {
          const t = it.userType === 1 ? 'Bán quầy' : it.userType === 0 ? 'Bán online' : 'Trả ế'
          return <Col key={it.name} md={3} sm={12}>
            <Row className='text-center align-self-center'>
              <Form.Label>{it.name} - {t}</Form.Label>
            </Row>
            <Row className='text-center align-self-center'>
              <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(it.sum) || ""}</Form.Label>
            </Row>
          </Col>
        })}
      </Row>
    )
  }, [summary])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row>
            {listSumary}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)