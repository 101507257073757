import React from "react";
import { Button, Modal } from '@themesberg/react-bootstrap';
import PowerTable from './game/power'
import MegaTable from './game/mega'
import KenoTable from './game/keno'
import Max3dTable from './game/max3d'
import Max3dProTable from './game/max3dpro'

export default (props) => {

  const reward = props.reward

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          Chi tiết giải thưởng {reward.ticketId && 'vé: ' + reward.ticketId} {reward.ticket ? ' - ' + reward.ticket.gameId : ''} {reward.ticket ? ' - Kỳ ' + reward.ticket.gamePeriod.name : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reward.ticket && reward.ticket.gameId === 'power' &&
          <PowerTable numberRows={reward.ticket.numberRows}
          />
        }
        {reward.ticket && reward.ticket.gameId === 'mega' &&
          <MegaTable numberRows={reward.ticket.numberRows}
          />
        }
        {reward.ticket && reward.ticket.gameId === 'keno' &&
          <KenoTable numberRows={reward.ticket.numberRows}
          />
        }
        {reward.ticket && reward.ticket.gameId === 'max3d' &&
          <Max3dTable numberRows={reward.ticket.numberRows}
          />
        }
        {reward.ticket && reward.ticket.gameId === 'max3dpro' &&
          <Max3dProTable numberRows={reward.ticket.numberRows}
          />
        }
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng:</div>
          <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.grossValue) || ""}</strong></div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Thuế:</div>
          <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.tax) || ""}</strong></div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Phí:</div>
          <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.fee) || ""}</strong></div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng thực nhận:</div>
          <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(reward.netValue) || ""}</strong></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
