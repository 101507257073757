import React, { useRef, useState } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import { AddDayOffForm } from "./partials/AddDayOffForm";
import { useCookies } from "react-cookie";
import { addDayOff } from "../../../../../controller/api/day-off";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [error, setError] = useState(false)

  const dateRef = useRef(new Date())
  
  const descriptionRef = useRef('')

  const gameIdsRef = useRef([])

  const onDateChange = (date) => {
    dateRef.current = date
  }

  const onDescriptionChange = (text) => {
    descriptionRef.current = text
  }
  
  const onGameIdsChange = (list) => {
    gameIdsRef.current = list
  }

  const validate = () => {
    return true
  }

  const onSubmit = async () => {
    try {
      if (validate()) {
        const resp = await addDayOff(cookies.lottmekong, dateRef.current, descriptionRef.current, gameIdsRef.current)
        if (resp) {
          window.location.reload()
        } else {
          alert('Có lỗi xảy ra')
          return false
        }
      } else {
        setError(true)
      }
    } catch (e) {
      alert('error')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm ngày nghỉ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <AddDayOffForm
          setDate={onDateChange}
          setDescription={onDescriptionChange}
          setGameIds={onGameIdsChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="success" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}
