import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { useCookies } from 'react-cookie';

// authen
import Signin from "./authenticate/Signin";
import Signup from "./authenticate/Signup";
import ForgotPassword from "./authenticate/ForgotPassword";
import ResetPassword from "./authenticate/ResetPassword";
import ServerError from "./authenticate/ServerError";

// trang chủ
import Overview from "./dashboard/DashboardOverview";
import CustomerOverview from "./__customer__/dashboard/DashboardOverview";
import SettingOverview from "./__setting__/dashboard/DashboardOverview";
import VietlottOverview from "./__vietlott__/dashboard/DashboardOverview";
import ClassicOverview from "./__classic__/dashboard/DashboardOverview";
import LotoOverview from "./__loto__/dashboard/DashboardOverview";

// nạp tiền
import AddChargeApp from './fund/charge/add-charge/AddCharge'
import AddChargeWeb from './fund/charge/add-charge-web/AddCharge'
import ChargeHistoryApp from './fund/charge/charge-history-app/ChargeHistory'
import ChargeHistoryWeb from './fund/charge/charge-history-web/ChargeHistory'
import IssueCharge from './fund/charge/issue-charge/IssueCharge'
import RefundApp from './fund/refund/add-refund/AddRefund'
import RefundWeb from './fund/refund/add-refund-web/AddRefund'
import RefundHistoryApp from './fund/refund/refund-history/RefundHistory'
import RefundHistoryWeb from './fund/refund/refund-history-web/RefundHistory'

// quản lý
import Cart from './management/cart/Cart'
import Ticket from './management/ticket/Ticket';
import IssueTicket from './management/issue-ticket/IssueTicket';
import MBRefund from './management/mb-refund/MBRefund';
import MBDoisoat from './management/mb-doisoat/MBDoisoat';
import WithdrawRequestApp from './management/withdraw-request/app/list/WithdrawRequest'
import WithdrawRequestAppConfirmed from './management/withdraw-request/app/confirmed/WithdrawRequest'

import WithdrawRequestWeb from './management/withdraw-request/web/list/WithdrawRequest'
import WithdrawRequestWebConfirmed from './management/withdraw-request/web/confirmed/WithdrawRequest'

// kết quả
import KenoResult from './result/keno/Keno'
import PowerResult from './result/power/Power'
import MegaResult from './result/mega/Mega'
import Max3dResult from './result/max3d/Max3d'
import Max3dProResult from './result/max3dpro/Max3dPro'

// thống kê
import CashBack from './statistic/cashback/CashBack'
import DeletedTicket from './statistic/by-deleted-ticket/ByDeletedTicket'
import RevenueByPrintedTicket from './statistic/by-printed-ticket/ByPrintedTicket'
import RevenueByDoneTicket from './statistic/by-done-ticket/ByDoneTicket'
import RevenueByWaitingTicket from './statistic/by-waiting-ticket/ByWaitingTicket'
import ChargeSummary from './statistic/charge-summary/ChargeSummary'
import TransactionSummary from './statistic/transaction-summary/TransactionSummary'
import Reward from './statistic/reward/Reward';
import BigReward from './statistic/big-reward/Reward';
import WIPReward from './statistic/wip-reward/Reward';
import WithdrawSummaryApp from './statistic/withdraw-summary-app/WithdrawSummary'
import WithdrawSummaryWeb from './statistic/withdraw-summary-web/WithdrawSummary'
import NewUserSummary from './statistic/new-user/NewUser'

// kỳ quay
import DayOff from './period/day-off/DayOff'
import KenoPeriod from './period/keno/Keno'
import PowerPeriod from './period/power/Power'
import MegaPeriod from './period/mega/Mega'
import Max3dPeriod from './period/max3d/Max3d'
import Max3dProPeriod from './period/max3dpro/Max3dPro'

// thông tin chung
import Devices from './commons/device/Devices'
import Games from './commons/game/Games'
import PaymentMethod from './commons/payment-method/PaymentMethod'
import Account from './commons/account/Account'
import Bank from './commons/bank/Bank'
import Banner from './commons/banner/Banner'
import Privacy from './commons/privacy/Privacy'
import Contact from './commons/contact/Contact'
import Invite from './commons/invite/Invite'
import Notification from './commons/notification/Notification'

// tài khoản
import FirstAgent from './account/first-agent/FirstAgent';
import SecondAgent from './account/second-agent/SecondAgent';
import Collaborator from './account/collaborator/Collaborator';
import User from './account/user/User'
import Employee from './account/employee/Employee'

import SystemNotification from './systems/notification/SystemNotification';

// classic
import ClassicListReward from './__classic__/list-reward/ListReward';
import ClassicChannel from './__classic__/channel/Channel';
import ClassicResult from './__classic__/result/Result';
import ClassicReward from './__classic__/reward/Reward';
import ClassicNewCart from './__classic__/new-cart/Cart';
import ClassicDoneCart from './__classic__/done-cart/Cart';
import ClassicTicket from './__classic__/ticket/Ticket';
import ClassicTicketStorage from './__classic__/ticket-storage/TicketStorage';
import ClassicTicketSales from './__classic__/sales-ticket-storage/SalesTicketStorage';
import ClassicStatistic from './__classic__/statistic/Statistic';
import ClassicStatisticByUnmarketableTicket from './__classic__/statistic-by-unmarketable-ticket/Statistic';
import ClassicStatisticByTicket from './__classic__/statistic-by-ticket/Statistic';
import ClassicSearch from './__classic__/search/Search';
import ClassicPeriod from './__classic__/period/Period';

import LotoChannel from './__loto__/channel/Channel';
import LotoPeriod from './__loto__/period/Period';
import LotoSearch from './__loto__/search/Search';
import LotoReward from './__loto__/reward/Reward';
import LotoNewCart from './__loto__/new-cart/Cart';
import LotoDoneCart from './__loto__/done-cart/Cart';
import LotoStatisticByTicket from './__loto__/statistic-by-ticket/Statistic';


import { checkToken } from '../controller/api/auth';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';
import { submitFbToken } from '../controller/api/messageToken';
import ClassicSidebar from '../components/ClassicSidebar';
import ChargeList from './statistic/charge-list/ChargeList';
import Maintained from './__vietlott__/maintained/Maintained';
import TransactionList from './statistic/transaction-list/TransactionList';
import BankSummary from './statistic/bank-summary/BankSummary';
import EwalletAndPaymentGatewaySummary from './statistic/ewallet-and-payment-gateway-summary/EwalletAndPaymentGatewaySummary';
import EwalletAndPaymentGatewayDetail from './statistic/ewallet-and-payment-gateway-detail/EwalletAndPaymentGatewayDetail';
import LotoSidebar from '../components/LotoSidebar';
import CustomerSidebar from '../components/CustomerSidebar';
import SettingSidebar from '../components/SettingSidebar';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

const RouteWithClassicSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <ClassicSidebar />

        <main className="content">
          <Navbar />
          <Component {...props} region={rest.region || ''} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

const RouteWithLotoSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <LotoSidebar />

        <main className="content">
          <Navbar />
          <Component {...props} region={rest.region || ''} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

const RouteWithCustomerSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <CustomerSidebar />

        <main className="content">
          <Navbar />
          <Component {...props} region={rest.region || ''} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

const RouteWithSettingSidebar = ({ component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <SettingSidebar />

        <main className="content">
          <Navbar />
          <Component {...props} region={rest.region || ''} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => {
  const [cookies] = useCookies(['lottmekong']);

  const history = useHistory()

  const checkTokenFunc = useCallback(async () => {
    if (!cookies.lottmekong) {
      history.replace(Routes.Signin.path)
    } else {
      const response = await checkToken(cookies.lottmekong)

      if (response.status === 'success') {
        if (history.location.pathname.includes(Routes.Signin.path)) {
          // const permission = response.permission

          history.replace(Routes.Overview.path)

          // if (permission === '100') {
          //   history.replace(Routes.VietlottOverview.path)
          // } else if (permission === '010') {
          //   history.replace(Routes.ClassicOverview.path)
          // } else if (permission === '001') {
          //   history.replace(Routes.LotoOverview.path)
          // } else if (permission === '000') {
          //   alert('Ko có quyền')
          // } else {
          //   history.replace(Routes.Overview.path)
          // }
        } else if (history.location.pathname === Routes.Overview.path) {
          const permission = response.permission

          history.replace(Routes.Overview.path)

          // if (permission === '100') {
          //   history.replace(Routes.VietlottOverview.path)
          // } else if (permission === '010') {
          //   history.replace(Routes.ClassicOverview.path)
          // } else if (permission === '001') {
          //   history.replace(Routes.LotoOverview.path)
          // } else if (permission === '000') {
          //   alert('Ko có quyền')
          // } else {
          //   history.replace(Routes.Overview.path)
          // }
        }
      } else {
        history.replace(Routes.Signin.path)
      }
    }
  }, [cookies.lottmekong, history])

  useEffect(() => {
    checkTokenFunc()
  }, [checkTokenFunc])

  useEffect(() => {
    getToken(messaging, {
      vapidKey: 'BOOzpS8OU5F3A9l6nTBZXIrrbjLxBKYHauMzejqh2HXjMPQIChneTSW7r9KW8rgpbNyFNBItfa5xKr0_kcCBs-M'
    }).then((currentToken) => {
      submitFbToken(cookies.lottmekong, currentToken)
    }).catch(() => {
      // alert(1)
    })
  }, [cookies.lottmekong])

  return (
    <Switch>
      {/* authen */}
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

      {/* trang chủ */}
      <Route exact path={Routes.Overview.path} component={Overview} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerOverview.path} component={CustomerOverview} />
      <RouteWithSettingSidebar exact path={Routes.SettingOverview.path} component={SettingOverview} />
      <RouteWithSidebar exact path={Routes.VietlottOverview.path} component={VietlottOverview} />
      <RouteWithClassicSidebar exact path={Routes.ClassicOverview.path} component={ClassicOverview} />
      <RouteWithLotoSidebar exact path={Routes.LotoOverview.path} component={LotoOverview} />

      {/* CUSTOMER */}
      <RouteWithCustomerSidebar exact path={Routes.CustomerAddChargeApp.path} component={AddChargeApp} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerAddChargeWeb.path} component={AddChargeWeb} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerChargeHistoryWeb.path} component={ChargeHistoryWeb} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerChargeHistoryApp.path} component={ChargeHistoryApp} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerIssueCharge.path} component={IssueCharge} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerRefundApp.path} component={RefundApp} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerRefundWeb.path} component={RefundWeb} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerRefundHistoryWeb.path} component={RefundHistoryWeb} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerRefundHistoryApp.path} component={RefundHistoryApp} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerWithdrawRequestWebConfirmed.path} component={WithdrawRequestWebConfirmed} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerWithdrawRequestAppConfirmed.path} component={WithdrawRequestAppConfirmed} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerWithdrawRequestWeb.path} component={WithdrawRequestWeb} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerWithdrawRequestApp.path} component={WithdrawRequestApp} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerFirstAgent.path} component={FirstAgent} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerSecondAgent.path} component={SecondAgent} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerCollaborators.path} component={Collaborator} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerUser.path} component={User} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerChargeSummary.path} component={ChargeSummary} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerNewUserSummary.path} component={NewUserSummary} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerWithdrawSummaryApp.path} component={WithdrawSummaryApp} />
      <RouteWithCustomerSidebar exact path={Routes.CustomerWithdrawSummaryWeb.path} component={WithdrawSummaryWeb} />

      <RouteWithCustomerSidebar exact path={Routes.MBRefund.path} component={MBRefund} />
      <RouteWithCustomerSidebar exact path={Routes.MBDoisoat.path} component={MBDoisoat} />

      {/* VIETLOTT */}
      <RouteWithSidebar exact path={Routes.VietlottCart.path} component={Cart} />
      <RouteWithSidebar exact path={Routes.VietlottTicket.path} component={Ticket} />
      <RouteWithSidebar exact path={Routes.VietlottKenoResult.path} component={KenoResult} />
      <RouteWithSidebar exact path={Routes.VietlottPowerResult.path} component={PowerResult} />
      <RouteWithSidebar exact path={Routes.VietlottMegaResult.path} component={MegaResult} />
      <RouteWithSidebar exact path={Routes.VietlottMax3dResult.path} component={Max3dResult} />
      <RouteWithSidebar exact path={Routes.VietlottMax3dProResult.path} component={Max3dProResult} />
      <RouteWithSidebar exact path={Routes.VietlottDeletedTicket.path} component={DeletedTicket} />
      <RouteWithSidebar exact path={Routes.VietlottPrintedTicket.path} component={RevenueByPrintedTicket} />
      <RouteWithSidebar exact path={Routes.VietlottDoneTicket.path} component={RevenueByDoneTicket} />
      <RouteWithSidebar exact path={Routes.VietlottWaitingTicket.path} component={RevenueByWaitingTicket} />
      <RouteWithSidebar exact path={Routes.VietlottDayOff.path} component={DayOff} />
      <RouteWithSidebar exact path={Routes.VietlottKenoPeriod.path} component={KenoPeriod} />
      <RouteWithSidebar exact path={Routes.VietlottPowerPeriod.path} component={PowerPeriod} />
      <RouteWithSidebar exact path={Routes.VietlottMegaPeriod.path} component={MegaPeriod} />
      <RouteWithSidebar exact path={Routes.VietlottMax3dPeriod.path} component={Max3dPeriod} />
      <RouteWithSidebar exact path={Routes.VietlottMax3dProPeriod.path} component={Max3dProPeriod} />
      <RouteWithSidebar exact path={Routes.VietlottCashBack.path} component={CashBack} />
      <RouteWithSidebar exact path={Routes.VietlottTransactionSummary.path} component={TransactionSummary} />
      <RouteWithSidebar exact path={Routes.VietlottTransactionList.path} component={TransactionList} />
      <RouteWithSidebar exact path={Routes.VietlottReward.path} component={Reward} />
      <RouteWithSidebar exact path={Routes.VietlottBigReward.path} component={BigReward} />

      {/* CLASSIC */}
      {/* <RouteWithClassicSidebar exact path={Routes.WithdrawSummaryApp.path} component={WithdrawSummaryApp} /> */}
      <RouteWithClassicSidebar exact path={Routes.ClassicListReward.path} component={ClassicListReward} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMNChannel.path} component={ClassicChannel} region={'MN'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMTChannel.path} component={ClassicChannel} region={'MT'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMNTicketSales.path} component={ClassicTicketSales} region={'MN'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMTTicketSales.path} component={ClassicTicketSales} region={'MT'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicResult.path} component={ClassicResult} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMNReward.path} component={ClassicReward} region={'MN'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMTReward.path} component={ClassicReward} region={'MT'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicTicket.path} component={ClassicTicket} />
      <RouteWithClassicSidebar exact path={Routes.ClassicTicketStorage.path} component={ClassicTicketStorage} />
      <RouteWithClassicSidebar exact path={Routes.ClassicStatistic.path} component={ClassicStatistic} />
      <RouteWithClassicSidebar exact path={Routes.ClassicStatisticByTicket.path} component={ClassicStatisticByTicket} />
      <RouteWithClassicSidebar exact path={Routes.ClassicStatisticByUnmarketableTicket.path} component={ClassicStatisticByUnmarketableTicket} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMNPeriod.path} component={ClassicPeriod} region={'MN'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMTPeriod.path} component={ClassicPeriod} region={'MT'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMNNewCart.path} component={ClassicNewCart} region={'MN'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMTNewCart.path} component={ClassicNewCart} region={'MT'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMNDoneCart.path} component={ClassicDoneCart} region={'MN'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicMTDoneCart.path} component={ClassicDoneCart} region={'MT'} />
      <RouteWithClassicSidebar exact path={Routes.ClassicSearch.path} component={ClassicSearch} />

      {/* LOTO */}
      <RouteWithLotoSidebar exact path={Routes.LotoChannel.path} component={LotoChannel} />
      <RouteWithLotoSidebar exact path={Routes.LotoPeriod.path} component={LotoPeriod} />
      <RouteWithLotoSidebar exact path={Routes.LotoSearch.path} component={LotoSearch} />
      <RouteWithLotoSidebar exact path={Routes.LotoDoneCart.path} component={LotoDoneCart} />
      <RouteWithLotoSidebar exact path={Routes.LotoNewCart.path} component={LotoNewCart} />
      <RouteWithLotoSidebar exact path={Routes.LotoReward.path} component={LotoReward} />
      <RouteWithLotoSidebar exact path={Routes.LotoStatisticByTicket.path} component={LotoStatisticByTicket} />

      {/* SETTING */}
      <RouteWithSettingSidebar exact path={Routes.SettingMaintained.path} component={Maintained} />
      <RouteWithSettingSidebar exact path={Routes.SettingDevices.path} component={Devices} />
      <RouteWithSettingSidebar exact path={Routes.SettingNotification.path} component={Notification} />
      <RouteWithSettingSidebar exact path={Routes.SettingGames.path} component={Games} />
      <RouteWithSettingSidebar exact path={Routes.SettingPaymentMethod.path} component={PaymentMethod} />
      <RouteWithSettingSidebar exact path={Routes.SettingAccount.path} component={Account} />
      <RouteWithSettingSidebar exact path={Routes.SettingBank.path} component={Bank} />
      <RouteWithSettingSidebar exact path={Routes.SettingBanner.path} component={Banner} />
      <RouteWithSettingSidebar exact path={Routes.SettingPrivacy.path} component={Privacy} />
      <RouteWithSettingSidebar exact path={Routes.SettingContact.path} component={Contact} />
      <RouteWithSettingSidebar exact path={Routes.SettingInvite.path} component={Invite} />
      <RouteWithSettingSidebar exact path={Routes.SettingEmployee.path} component={Employee} />
      <RouteWithSettingSidebar exact path={Routes.SettingSystemNotification.path} component={SystemNotification} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
}
