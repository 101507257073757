import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { paySmallReward } from '../../../../../controller/api/result'
import { Button, Modal } from '@themesberg/react-bootstrap';

export default (props) => {

  const userId = props.userId
  const transId = props.transId
  const ticketId = props.ticketId

  const [cookies] = useCookies(['lottmekong'])

  const [status, setStatus] = useState(0)

  const paidPress = async () => {
    setStatus(-1)
    await handlePaid()
  }

  const handlePaid = async () => {
    const response = await paySmallReward(cookies.lottmekong, transId, userId)

    if (response) {
      setStatus(1)
    } else {
      setStatus(2)
    }
  }

  useEffect(() => {
    if (status == 1) {
      props.onHide(status)
    }
  }, [status])

  useEffect(() => {
    if (props.show) {
      setStatus(0)
    }
  }, [props.show])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {`${userId} - ${ticketId}`}
        </Modal.Title>
      </Modal.Header>
       
      <Modal.Footer>
        <b>{status === 1 ? 'Xong' : status === 2 ? 'Không thành công' : status === -1 ? 'Đang xử lý' : ''}</b> &nbsp;
        <Button variant="success" onClick={paidPress}>Trả thưởng</Button>
        <Button variant="danger" onClick={() => {
          props.onHide(status)
        }}
        >Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
