
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import { DeviceItem } from './partials/DeviceItem'
import { useCookies } from "react-cookie";
import { getAllClassicWorkTime } from "../../../controller/api/worktime";
import topbar from "topbar";
import { getSolvedCart } from "../../../controller/api/__classic__/result";
import { ResultItem } from "./partials/ResultItem";

export default () => {
  const [cookies] = useCookies(['lottmekong']);

  const [list, setList] = useState([])

  const [listResult, setListResult] = useState([])

  const [loaded, setLoaded] = useState(true);

  const [isRollEnabled, setIsRollEnabled] = useState(false);

  const getAllFunc = useCallback(async () => {
    setLoaded(false)
    let list = await getAllClassicWorkTime(cookies.lottmekong)

    list = list.filter(it => it.classicCart.length > 0)

    const resp = await getSolvedCart(cookies.lottmekong)

    setLoaded(true)

    setIsRollEnabled(resp.isRollEnabled)

    setListResult(resp.listResult)

    setList(list)

  }, [cookies.lottmekong])

  useEffect(() => {
    if (loaded) {
      topbar.hide();
    } else {
      topbar.show();
    }
  }, [loaded])

  useEffect(() => {
    document.title = "Trang chủ"
  }, []);

  useEffect(() => {
    topbar.config({
      autoRun: true,
      barThickness: 3,
      barColors: {
        '0': 'rgba(26,  188, 156, .9)',
        '.25': 'rgba(52,  152, 219, .9)',
        '.50': 'rgba(241, 196, 15,  .9)',
        '.75': 'rgba(230, 126, 34,  .9)',
        '1.0': 'rgba(211, 84,  0,   .9)'
      },
      shadowBlur: 10,
      shadowColor: 'rgba(0,   0,   0,   .6)'
    })
    getAllFunc();
    const _intervalRef = setInterval(() => {
      getAllFunc();
    }, 3000);
    return () => {
      clearInterval(_intervalRef)
    }
  }, [getAllFunc])

  const renderList = useMemo(() => {

    const count = list.length

    let _index = 0

    let parent = []

    let childrens = []

    while (_index < count) {

      if (childrens.length === 4) {
        parent.push(childrens)
        childrens = []
      }

      childrens.push(list[_index])

      _index++

    }

    parent.push(childrens)

    return parent

  }, [list])

  return (
    <>
      <Col>
        <ResultItem listResult={listResult} isRollEnabled={isRollEnabled}  />
      </Col>
      <Col>
        {renderList.map((parent, i) => {
          return (
            <Row key={i}>
              {parent.map((children, j) => {
                return (
                  <Col key={j} md={3}>
                    <DeviceItem data={children} />
                  </Col>
                )
              })}
            </Row>
          )
        })}
      </Col>
    </>
  );
};
