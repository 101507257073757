import { Button } from '@themesberg/react-bootstrap';
import React from 'react'

export const TableRow = (props) => {
  const { ticket, user, netValue, isPaid, id } = props.data;

  const { onPaidPress } = props;

  return (
    <tr>
      <td className="text-center">
        {!isPaid &&
          <Button variant="danger" onClick={() => props.onCheckPress(ticket.id, ticket.gamePeriodId)}>
            Kiểm tra vé
          </Button>
        }
        &nbsp;
        &nbsp;
        &nbsp;
        <Button onClick={() => props.onShowDetailPress(props.data)}>
          Chi tiết
        </Button>
      </td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      {ticket && <td className='text-center'>
        <img alt='' src={require(`../../../../assets/img/logo-${ticket.gameId}.png`)} className="img-thumbnail" width={90} height={30}></img>
      </td>}
      <td className="text-center">{ticket && ticket.id}<br /><b>Kỳ quay: {ticket && ticket.gamePeriodId.split('-')[1]}</b></td>
      <td className="text-center text-success">{new Intl.NumberFormat('vn-VI', {}).format(netValue)}</td>
      <td className="text-center text-success">{isPaid ? 'Đã trả thưởng' : <Button variant="danger" className="m-1" onClick={() => onPaidPress(id, user.id, ticket.id)}>Trả thưởng</Button>}</td>
    </tr>
  );
};