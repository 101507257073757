import React from "react";
import { Button, Col, Modal, Row } from '@themesberg/react-bootstrap';
import RewardTable from '../../../reward/sub-screen/reward-detail/partials/reward-table'

export default (props) => {

  const result = props.result ? props.result : null

  const transPayReward = props.transactionPayReward && props.transactionPayReward.length > 0 ? props.transactionPayReward[0] : {}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {props['ticket-id'] && 'Mã vé: ' + props['ticket-id']}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>
          {
            props.status === 1 ? 'ĐÃ MUA' :
              [4].indexOf(props.status) >= 0 ? 'ĐÃ TRÚNG THƯỞNG' :
                [5].indexOf(props.status) >= 0 ? 'KHÔNG TRÚNG THƯỞNG' :
                  'ĐANG CHỜ XỬ LÝ'
          }
        </h5>
        <Row>
          <Col>
            <b>Thông tin vé</b>
          </Col>
        </Row>
        <Row>
          <Col>
            Kênh
          </Col>
          <Col>
            {props.channel && props.channel.name}
          </Col>
        </Row>
        <Row>
          <Col>
            Ngày quay số
          </Col>
          <Col>
            {result && result.resultDate}
          </Col>
        </Row>
        <Row>
          <Col>
            Dãy số
          </Col>
          <Col>
            {props.storage && props.storage.numbers}
          </Col>
        </Row>
        <Row>
          <Col>
            Số vé
          </Col>
          <Col>
            {props.amount}
          </Col>
        </Row>
        {
          props.status === 4 &&
          <>
            <Row>
              <Col>
                <b>Thông tin giải thưởng</b>
              </Col>
            </Row>
            <Row>
              <RewardTable
                rewards={props.rewards}
                rewardNumber={props.rewardNumber}
              />
            </Row>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(transPayReward.grossValue / props.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>x Số tờ:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(props.amount) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tổng tiền thưởng:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(transPayReward.grossValue) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Thuế:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(transPayReward.tax) || ""}</strong></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: 3, justifyContent: 'flex-end', textAlign: 'end' }}>Tiền thưởng sau thuế:</div>
              <div style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'end' }}><strong>{new Intl.NumberFormat('vn-VI', {}).format(transPayReward.netValue) || ""}</strong></div>
            </div>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
