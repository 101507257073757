import React from 'react'
import { Button } from '@themesberg/react-bootstrap'
import { disableTicketRoll, enableTicketRoll } from '../../../../controller/api/systemApi';
import { useCookies } from 'react-cookie';

export const ResultItem = ({ isRollEnabled }) => {

  const [cookies] = useCookies(['lottmekong']);

  const onEnableClick = async () => {
    await enableTicketRoll(cookies.lottmekong, '', false, true)
    window.location.reload()
  }

  const onDisableClick = async () => {
    await disableTicketRoll(cookies.lottmekong, '', false, true)
    window.location.reload()
  }

  return (
    <>
      {isRollEnabled ?
        <Button variant="danger" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }} onClick={onDisableClick}>
          Tắt chia vé
        </Button>
        :
        <Button variant="success" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25 }} onClick={onEnableClick}>
          Bật chia vé
        </Button>
      }
    </>
  );
};