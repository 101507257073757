import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-dayoff'

export const getAllDayOff = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const addDayOff = async (token, date, description, gameIds) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add`, {
        date: date,
        description: description,
        gameIds: gameIds,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const deleteDayOff = async (token, date) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/delete`, {
        date: date
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}