import React, { useCallback, useContext, useRef } from 'react'
import { Col, Row, Card, Form, InputGroup, Container, Button } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { Context } from '../Statistic'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _from = useRef(state.filter.from);
  const _to = useRef(state.filter.to);
  const _phone = useRef(state.filter.phone);
  const _channelIds = useRef(state.filter.channelIds);

  const _handleChangeFrom = useCallback((payload) => {
    _from.current = payload
  }, [])

  const _handleChangeTo = useCallback((payload) => {
    _to.current = payload
  }, [])

  const _handleChangePhone = useCallback((payload) => {
    _phone.current = payload.target.value
  }, [])

  const _handleChangeChannelIds = useCallback((payload) => {
    _channelIds.current = payload.target.value
  }, [])

  const _submitForm = useCallback(() => {
    dispatch({
      type: 'SUBMIT_FILTER',
      payload: {
        from: _from.current,
        to: _to.current,
        phone: _phone.current,
        channelIds: _channelIds.current
      }
    })
  }, [dispatch])

  const _exportForm = () => {
    dispatch({
      type: 'EXPORT_DATA'
    })
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>DOANH THU</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tìm kiếm</Button>
              <Button style={{ marginRight: 20 }} variant="success" type="button" onClick={_exportForm}>Xuất file</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <Row className='p-1'>
                  <Col sm={12} md={4}>
                    <Row>
                      <Col md={3} className='text-right align-self-center'>
                        <Form.Label>Từ ngày</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Datetime
                          timeFormat={false}
                          onChange={_handleChangeFrom}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={_from.current ? moment(_from.current).format("DD/MM/YYYY") : ""}
                                placeholder="dd/mm/yyyy"
                                onFocus={openCalendar}
                                onChange={() => { }} />
                            </InputGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} md={4}>
                    <Row>
                      <Col md={3} className='text-right align-self-center'>
                        <Form.Label>Đến ngày</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Datetime
                          timeFormat={false}
                          onChange={_handleChangeTo}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={_to.current ? moment(_to.current).format("DD/MM/YYYY") : ""}
                                placeholder="dd/mm/yyyy"
                                onFocus={openCalendar}
                                onChange={() => { }} />
                            </InputGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col md={3} className='text-right align-self-center'>
                    <Form.Label>Số điện thoại</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control onChange={_handleChangePhone} required type="text" placeholder="Nhập số điện thoại" />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col md={3} className='text-right align-self-center'>
                    <Form.Label>Mã đài</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control onChange={_handleChangeChannelIds} required type="text" placeholder="Nhập 1 hoặc nhiều mã đài (phân biệt bằng dấu ','    )" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)