
import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import { useCookies } from "react-cookie";
import { deleteAgent, getAllSecondAgent } from "../../../controller/api/agent";
import { calculatePage } from "../../../helper/helper";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    phone: '',
    code: '',
    firstAgent: '',
    page: 0
  },
  data: {
    list: [],
    total: 0,
    totalPage: 0
  },
  filterPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    let phone = state.filter.phone

    if (phone.indexOf('MB') < 0) {
      phone = phone.replace('0', '+84')
    }
    const response = await getAllSecondAgent(cookies.lottmekong, {
      phone: phone,
      referCode: state.filter.code,
      firstAgent: state.filter.firstAgent,
    }, state.filter.page)

    if (response) {
      dispatch({
        type: 'UPDATE_DATA',
        payload: {
          list: response.data,
          total: response.total,
          totalPage: calculatePage(response.total)
        }
      })
    }
  }, [cookies.lottmekong, state.filter.phone, state.filter.code, state.filter.page, state.filter.firstAgent])

  const deleteFunc = useCallback(async (code, name) => {
    const resp = window.confirm("Xác nhận xóa đại lý " + code)

    if (resp) {
      const response = await deleteAgent(cookies.lottmekong, code, 2)

      if (response) {
        alert("Đã xóa thành công")
        window.location.reload()
      } else {
        alert("Không thành công")
      }
    }
  }, [cookies.lottmekong])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {
    document.title = "Đại lý cấp 2"
  }, [])

  return (
    <Context.Provider value={{
      state,
      dispatch,
      deleteFunc
    }}>

      <FilterForm />

      <br />

      <Table />
    </Context.Provider>
  );
};
