import NodeRSA from "node-rsa";
import {RSA_PUBLIC_KEY} from './config';

export const ITEM_PER_PAGE = 30

export const  parseQuery = (queryString) => {
    var query = {};
    if (queryString) {
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = JSON.parse(decodeURIComponent(pair[1] || ''));
        }
    }
    return query;
}

export const calculatePage = (total) => {
    let pages = Math.floor(total / ITEM_PER_PAGE)
    if (total % ITEM_PER_PAGE === 0) {
        return pages
    }
    return pages + 1
}

export const downloadCsv = (data, callback) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', new Date().getTime() + '.txt'); //or any other extension
    document.body.appendChild(link);
    link.click();

    if (callback) {
        callback()
    }
}

export const parseWeekday = (day) => {
    switch (day) {
        case 'Mon':
            return 'Thứ hai'
        case 'Tue':
            return 'Thứ ba'
        case 'Wed':
            return 'Thứ tư'
        case 'Thu':
            return 'Thứ năm'
        case 'Fri':
            return 'Thứ sáu'
        case 'Sat':
            return 'Thứ bảy'
        case 'Sun':
            return 'Chủ nhật'
        default:
            return '';
    }
}

export const encryptMessage = (message) => {

    const key = new NodeRSA(RSA_PUBLIC_KEY);

    const encrypted = key.encrypt(message, 'base64');

    return encrypted;
}