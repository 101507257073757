import React, { useRef } from "react";
import { Col, Row, Form, InputGroup } from '@themesberg/react-bootstrap';
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const AddDayOffForm = (props) => {

  const _date = useRef(new Date());

  const _gameIds = useRef([])

  const _handleChangeDate = (payload) => {
    _date.current = payload
    props.setDate(payload)
  }

  const onDescriptionChange = (evt) => {
    props.setDescription(evt.target.value)
  }

  const onGameIdsChange = (evt, gameId) => {
    if (evt.target.checked) {
      _gameIds.current.push(gameId)
    } else {
      _gameIds.current = _gameIds.current.filter(it => it !== gameId)
    }
    props.setGameIds(_gameIds.current)
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Ngày</Form.Label>
            <Datetime
              timeFormat={false}
              onChange={_handleChangeDate}
              renderInput={(props, openCalendar) => (
                <InputGroup>
                  <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    value={_date.current ? moment(_date.current).format("DD/MM/YYYY") : ""}
                    placeholder="dd/mm/yyyy"
                    onFocus={openCalendar}
                    onChange={() => { }} />
                </InputGroup>
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Mô tả</Form.Label>
            <Form.Control required type="text" placeholder="Nhập mô tả" onChange={onDescriptionChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Trò chơi</Form.Label>
            <Form.Check onChange={(evt) => onGameIdsChange(evt, 'keno')} label="Keno" id="dayoff-keno" htmlFor="dayoff-keno" />
            <Form.Check onChange={(evt) => onGameIdsChange(evt, 'mega')} label="Mega 6/45" id="dayoff-mega" htmlFor="dayoff-mega" />
            <Form.Check onChange={(evt) => onGameIdsChange(evt, 'power')} label="Power 6/55" id="dayoff-power" htmlFor="dayoff-power" />
            <Form.Check onChange={(evt) => onGameIdsChange(evt, 'max3d')} label="Max3D" id="dayoff-max3d" htmlFor="dayoff-max3d" />
            <Form.Check onChange={(evt) => onGameIdsChange(evt, 'max3dpro')} label="Max3D Pro" id="dayoff-max3dpro" htmlFor="dayoff-max3dpro" />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};