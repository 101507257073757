import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Table, Form, Button } from '@themesberg/react-bootstrap'
import { changeEnableAuto, getAllAccounts, updateStatus } from '../../../../controller/api/account';
import AddAccount from '../sub-screen/add-account/AddAccount';
import { useCookies } from 'react-cookie';

const TableRow = (props) => {
  const { accountName, accountNumber, status, bank, onStatusChange, id, enableAuto, onEnableAutoChange } = props;

  const _enableAuto = enableAuto ? 1 : 0

  return (
    <tr>
      <td>
        <span>{accountName || ""}</span><br />
        <span className={`fw-900 text-info`}>{accountNumber || ""}</span>
      </td>
      <td>
        <span>{bank.shortName || ""}</span><br />
        <span className={`fw-900 text-info`}>{bank.viName || ""}</span>
      </td>
      <td style={{ width: 250 }}>
        <Form.Select value={_enableAuto} onChange={(evt) => onEnableAutoChange(evt, id)} >
          <option value={0}>Tắt</option>
          <option value={1}>Mở</option>
        </Form.Select>
      </td>
      <td style={{ width: 250 }}>
        <Form.Select value={status} onChange={(evt) => onStatusChange(evt, id)} >
          <option value={0}>Không hoạt động</option>
          <option value={1}>Hoạt động</option>
        </Form.Select>
      </td>
    </tr>
  );
};

export const AccountTable = () => {

  const [listAccount, setListAccount] = useState({})

  const [cookies] = useCookies(['lottmekong'])

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const getAllAccountFunc = async () => {
      const list = await getAllAccounts(cookies.lottmekong)

      if (list) {
        const res = {
          data: list.data,
          total: list.total,
          isOff: list.data.filter(item => item.status === 0).length,
          isOn: list.data.filter(item => item.status === 1).length,
        }
        setListAccount(res || {})
      }
    }
    getAllAccountFunc()
  }, [cookies.lottmekong])

  const onAddAccountPress = async () => {
    setModalShow(true)
  }

  const onStatusChange = async (evt, id) => {
    const newStatus = evt.target.value
    const resp = await updateStatus(cookies.lottmekong, id, newStatus)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }
  
  const onEnableAutoChange = async (evt, id) => {
    const newStatus = parseInt(evt.target.value)
    const resp = await changeEnableAuto(cookies.lottmekong, id, newStatus)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Tài khoản thanh toán ({listAccount.total})</h5>
            </Col>
            <Col className="text-end">
              <Button variant="primary" className="m-1" onClick={onAddAccountPress}>Thêm tài khoản</Button>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Tên tài khoản</th>
              <th scope="col">Tên ngân hàng</th>
              <th scope="col">Tự động nạp</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {listAccount.data && listAccount.data.map(pv => <TableRow onEnableAutoChange={onEnableAutoChange} onStatusChange={onStatusChange} key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
      <AddAccount
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};