import React, { useEffect, useRef } from "react";
import { Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap';

export default (props) => {

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const _onHide = () => {

    props.onHide()

  }

  const _db = useRef('');
  const _1 = useRef('');
  const _2 = useRef('');
  const _3_1 = useRef('');
  const _3_2 = useRef('');
  const _4_1 = useRef('');
  const _4_2 = useRef('');
  const _4_3 = useRef('');
  const _4_4 = useRef('');
  const _4_5 = useRef('');
  const _4_6 = useRef('');
  const _4_7 = useRef('');
  const _5 = useRef('');
  const _6_1 = useRef('');
  const _6_2 = useRef('');
  const _6_3 = useRef('');
  const _7 = useRef('');
  const _8 = useRef('');

  useEffect(() => {
    if (props.result && props.result.length > 0) {
      const _result = props.result
      const g3 = _result[3].split(' ')
      const g4 = _result[4].split(' ')
      const g6 = _result[6].split(' ')

      _db.current = _result[0]
      _1.current = _result[1]
      _2.current = _result[2]
      _3_1.current = g3[0]
      _3_2.current = g3[1]
      _4_1.current = g4[0]
      _4_2.current = g4[1]
      _4_3.current = g4[2]
      _4_4.current = g4[3]
      _4_5.current = g4[4]
      _4_6.current = g4[5]
      _4_7.current = g4[6]
      _5.current = _result[5]
      _6_1.current = g6[0]
      _6_2.current = g6[1]
      _6_3.current = g6[2]
      _7.current = _result[7]
      _8.current = _result[8]
      forceUpdate()
    }

  }, [props.result, forceUpdate])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Kết quả
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải đặc biệt</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_db.current || ''} placeholder="" className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải nhất</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_1.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải nhì</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_2.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải ba</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_3_1.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_3_2.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải tư</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_1.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_2.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_3.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_4.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_5.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_6.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_4_7.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải năm</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_5.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải sáu</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_6_1.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_6_2.current} placeholder=""  className="text-center" />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_6_3.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải bảy</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_7.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải tám</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" disabled defaultValue={_8.current} placeholder=""  className="text-center" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}