import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { getPeriodSummary } from "../../../../../controller/api/statistic/period";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const _onHide = () => {

    props.onHide()

  }

  useEffect(() => {
    if (props.show) {
      getPeriodSummary(cookies.lottmekong, {
        periodId: props.period.id
      }).then((result) => {
        setData(result)
      })
    }
  }, [props.show])



  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thống kê kỳ #{props.period && props.period.name}
          <br />- Số vé: {data.count}
          <br />- Giá trị: {new Intl.NumberFormat('vn-VI', {}).format(data.sum)}
          <br />- Giá trị thưởng: {new Intl.NumberFormat('vn-VI', {}).format(data.sumReward)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <th>Nhân viên</th>
            <th>Tổng số vé</th>
            <th>Tổng giá trị</th>
            <th>Số vé không trúng</th>
            <th>Số vé trúng</th>
            <th>Số tiền trúng</th>
          </thead>
          <tbody>
            {data.list && data.list.map(e => {
              return (
                <tr>
                  <td className="text-center">{e.name}</td>
                  <td>{e.totalTicket}</td>
                  <td>{new Intl.NumberFormat('vn-VI', {}).format(e.totalValue)}</td>
                  <td>{e.totalUnreward}</td>
                  <td>{e.totalReward}</td>
                  <td>{new Intl.NumberFormat('vn-VI', {}).format(e.totalRewardValue)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = {
  items: {
    width: 50,
    height: 50,
    fontWeight: 'bolder',
    fontSize: 18,
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: 'gray',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 100
  }
};
