import React, { useContext, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { TableRow } from '../component/TableRow'
import Pagination from '../../../../components/Pagination'
import { Context } from '../Reward'
import RewardDetail from '../sub-screen/reward-detail/RewardDetail'
import PayReward from '../sub-screen/pay-reward/PayReward'

const _Table = () => {

  const { state, dispatch } = useContext(Context);

  const [modalShow, setModalShow] = useState(false)

  const [payModalShow, setPayModalShow] = useState(false)

  const rewardRef = useRef({})

  const transIdRef = useRef('')
  const ticketIdRef = useRef('')
  const phoneRef = useRef('')

  const onShowDetailPress = (reward) => {
    rewardRef.current = reward
    setModalShow(true)
  }

  const onPaidPress = (id, phone, ticket) => {
    ticketIdRef.current = ticket
    transIdRef.current = id
    phoneRef.current = phone
    setPayModalShow(true)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Người chơi</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Vé</th>
              <th scope="col">Giải thưởng</th>
              <th scope="col">Thuế</th>
              <th scope="col">Phí</th>
              <th scope="col">Thực nhận</th>
            </tr>
          </thead>
          <tbody>
            {state.data.list && state.data.list.map((pv, index) => <TableRow onPaidPress={onPaidPress} onShowDetailPress={onShowDetailPress} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} data={pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={state.data.totalPage || 0}
                currentPage={state.filter.page || 0}
                onPageChange={(i) => {
                  dispatch({
                    type: 'UPDATE_PAGE',
                    payload: i
                  })
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <RewardDetail
        show={modalShow}
        reward={rewardRef.current}
        onHide={() => setModalShow(false)}
      />
      <PayReward
        show={payModalShow}
        transId={transIdRef.current}
        ticketId={ticketIdRef.current}
        phone={phoneRef.current}
        onHide={(status) => {

          if (status === 1) {
            const newData = JSON.parse(JSON.stringify(state.data))

            for (let index = 0; index < newData.list.length; index++) {
              const element = newData.list[index];

              if (element.id === transIdRef.current) {
                element.isPaid = true
                break;
              }
            }

            dispatch({
              type: 'UPDATE_DATA',
              payload: newData
            })

            setPayModalShow(false)

          } else {
            alert('Hãy trả thưởng')
          }
        }}
      />
    </>
  );
};

export default React.memo(_Table)