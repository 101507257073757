import React, { useEffect, useState } from 'react'
import { Card, Table } from '@themesberg/react-bootstrap'
import { getAllChannel } from '../../../../controller/api/__loto__/channel';
import { useCookies } from 'react-cookie';

const parseWeekdayName = (day) => {
  switch (day) {
    case 'Mon':
      return 'Thứ hai'
    case 'Tue':
      return 'Thứ ba'
    case 'Wed':
      return 'Thứ tư'
    case 'Thu':
      return 'Thứ năm'
    case 'Fri':
      return 'Thứ sáu'
    case 'Sat':
      return 'Thứ bảy'
    case 'Sun':
      return 'Chủ nhật'
    default:
      return ''
  }
}

const TableRow = (props) => {
  const { name, weekdays, id } = props;

  const weekDayNames = weekdays.map(it => {
    return parseWeekdayName(it)
  })

  return (
    <tr>
      <td className='text-danger'>
        <strong>{id}</strong>
      </td>
      <td>{name || ""}</td>
      <td>{weekDayNames.join(' , ')}</td>
    </tr>
  );
};

export const ChannelTable = ({ region }) => {

  const [listAccount, setListAccount] = useState({})

  const [cookies] = useCookies(['lottmekong'])

  useEffect(() => {
    const getAllAccountFunc = async () => {
      const list = await getAllChannel(cookies.lottmekong)

      if (list) {
        const res = {
          data: list.data,
        }
        setListAccount(res || {})
      }
    }
    getAllAccountFunc()
  }, [cookies.lottmekong, region])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Mã</th>
              <th scope="col">Tên</th>
              <th scope="col">Lịch quay số</th>
            </tr>
          </thead>
          <tbody>
            {listAccount.data && listAccount.data.map(pv => <TableRow key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
    </>
  );
};