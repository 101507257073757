import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap'
import { getInvite, updateInvite } from '../../../../controller/api/common';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useCookies } from 'react-cookie';

export const InviteEditor = () => {

  const [invite, setInvite] = useState("")

  const [cookies] = useCookies(['lottmekong'])

  useEffect(() => {
    const getInviteFunc = async () => {
      const _invite = await getInvite(cookies.lottmekong)

      if (_invite && _invite.data) {
        setInvite(_invite.data.content || "")
      }
    }
    getInviteFunc()
  }, [cookies.lottmekong])

  const onUpdatePress = async () => {
    try {
      const resp = await updateInvite(cookies.lottmekong, invite)
      if (resp) {
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } catch (e) {
      alert('error')
    }
  }

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Thông tin liên hệ</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <CKEditor
          editor={ClassicEditor}
          config={{
            fontFamily: {
              options: [
                'default',
                'Ubuntu, Arial, sans-serif',
                'Ubuntu Mono, Courier New, Courier, monospace'
              ]
            },
            toolbar: ['bold', 'italic', 'heading', 'bulletedList', 'numberedList', 'fontFamily', 'undo', 'redo']
          }}
          data={invite}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setInvite(data)
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
      </Card.Body>
      <Card.Footer>
        <Button onClick={onUpdatePress}>Lưu</Button>
      </Card.Footer>
    </Card>
  );
};