import React, {  } from "react";
import MaintainedComponent from "../../../components/MaintainedComponent";

export default () => {

  return (
    <>
      <MaintainedComponent />
    </>
  );
};
