import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { payAllSmallReward } from '../../../../../controller/api/result'
import { Button, Modal } from '@themesberg/react-bootstrap';

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const handlePaid = async () => {
    const response = await payAllSmallReward(cookies.lottmekong)

    window.location.reload()
  }

  useEffect(() => {
    if (props.show) {
      handlePaid()
    }
  }, [props.show])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {`Đang trả thưởng`}
        </Modal.Title>
      </Modal.Header>
    </Modal>
  );
}
