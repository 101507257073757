import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table, Form, Button } from '@themesberg/react-bootstrap'
import { getAllEmployees, updateStatus, logoutWorkTimeById } from '../../../../controller/api/employee';
import WorkTime from '../sub-screen/work-time/WorkTime'
import { useCookies } from 'react-cookie';
import AddNewEmployee from '../sub-screen/add-new/AddNewEmployee';

const TableRow = (props) => {
  const { username, workTime, onStatusChange, status, id, onShowWorkTime, onLogout } = props;
  return (
    <tr>
      <td>
        <Button variant="info" className="m-1" onClick={() => onShowWorkTime(id)}>Xem thời gian làm việc</Button>
        {workTime && workTime.length > 0 &&
          <Button variant="danger" className="m-1" onClick={() => onLogout(workTime[0].id, id)}>Đăng xuất</Button>
        }
      </td>
      <td>
        {username || ""}
      </td>
      <td style={{ width: 250 }}>
        <Form.Select value={status} onChange={(evt) => onStatusChange(evt, id)} >
          <option value={0}>Không hoạt động</option>
          <option value={1}>Hoạt động</option>
        </Form.Select>
      </td>
    </tr>
  );
};

export const EmployeeTable = () => {

  const [listEmployee, setListEmployee] = useState({})

  const [modalShow, setModalShow] = useState(false)
  
  const [addModalShow, setAddModalShow] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const employeeIdRef = useRef(null)

  const employeeNameRef = useRef(null)

  useEffect(() => {
    const getAllEmployeeFunc = async () => {
      const list = await getAllEmployees(cookies.lottmekong)

      if (list) {
        const res = {
          data: list.data,
          total: list.total,
          isOff: list.data.filter(item => item.status === 0).length,
          isOn: list.data.filter(item => item.status === 1).length,
        }
        setListEmployee(res || {})
      }
    }

    getAllEmployeeFunc()
  }, [cookies.lottmekong])

  const onShowWorkTime = (id, name) => {
    employeeIdRef.current = id
    employeeNameRef.current = name
    setModalShow(true)
  }

  const onLogout = async (id, employeeId) => {
    const resp = await logoutWorkTimeById(cookies.lottmekong, id)
    if (resp) {
      const _newData = JSON.parse(JSON.stringify(listEmployee))

      _newData.data = _newData.data.map(it => {
        if (it.id === employeeId) {
          it.workTimes = []
        }
        return {
          ...it
        }
      })
      setListEmployee(_newData)
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  const onStatusChange = async (evt, id) => {
    const newStatus = evt.target.value
    const resp = await updateStatus(cookies.lottmekong, id, newStatus)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  const onAddPress = async () => {
    setAddModalShow(true)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Nhân viên ({listEmployee.total})</h5>
            </Col>
            <Col className="text-end">
              <Button className='mb-3' style={{ marginRight: 20 }} variant="success" type="button" onClick={onAddPress}>Thêm nhân viên</Button>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Tên đăng nhập</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {listEmployee.data && listEmployee.data.map(pv => <TableRow onLogout={onLogout} onShowWorkTime={onShowWorkTime} onStatusChange={onStatusChange} key={`device-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Card>
      <WorkTime
        show={modalShow}
        employeeId={employeeIdRef.current}
        employeeName={employeeNameRef.current}
        onHide={() => setModalShow(false)}
      />
      <AddNewEmployee
        show={addModalShow}
        onHide={() => setAddModalShow(false)}
      />
    </>
  );
};