import React, { useCallback, useContext, useRef } from 'react'
import { Col, Row, Card, Form, Container, Button } from '@themesberg/react-bootstrap'
import { Context } from '../Search'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _ticketId = useRef(state.filter.ticketId);
  const _cartId = useRef(state.filter.cartId);

  const _handleChangeTicketId = useCallback((payload) => {
    _ticketId.current = payload.target.value
  }, [])
  
  const _handleChangeCartId = useCallback((payload) => {
    _cartId.current = payload.target.value
  }, [])

  const _submitForm = useCallback(() => {
    dispatch({
      type: 'SUBMIT_FILTER',
      payload: {
        ticketId: _ticketId.current,
        cartId: _cartId.current,
      }
    })
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>Tra cứu</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tìm kiếm</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <Row className='p-1'>
                  <Col>
                    <Row>
                      <Col md={3} className='text-right align-self-center'>
                        <Form.Label>Mã đơn hàng</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control onChange={_handleChangeCartId} required type="text" placeholder="LTxxx" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className='p-1'>
                  <Col>
                    <Row>
                      <Col md={3} className='text-right align-self-center'>
                        <Form.Label>Mã vé</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control onChange={_handleChangeTicketId} required type="text" placeholder="LTxxxx" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)