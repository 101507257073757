import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Modal, ProgressBar } from '@themesberg/react-bootstrap'
import { calculateReward } from '../../../../../controller/api/__loto__/result'
import { useCookies } from 'react-cookie'

export default (props) => {

  const [totalTicket, setTotalTicket] = useState(0)
  const [done, setDone] = useState(false)
  const firstTime = useRef(null)
  const prevShow = useRef(props.show)
  const totalRewardTicket = useRef(0)
  const totalUnrewardTicket = useRef(0)
  const totalReward = useRef(0)

  const [cookies] = useCookies(['lottmekong'])

  const _onCalculating = useCallback(async () => {

    const response = await calculateReward(cookies.lottmekong, 10, props.resultId)

    if (response) {
      console.log(response, 'response')
      totalRewardTicket.current += response.totalRewardTicket
      totalUnrewardTicket.current += response.totalUnrewardTicket
      totalReward.current += response.totalReward
      setTotalTicket(response.totalTicket)
      if (response.totalTicket === 0) {
        setDone(true)
      }
    }
  }, [cookies.lottmekong, props.resultId])

  useEffect(() => {
    if (!prevShow.current && props.show) {
      prevShow.current = props.show
      if (firstTime.current === null) {
        firstTime.current = new Date()
      }
      _onCalculating()

    }

    if (props.show) {
      if (totalTicket > 0) {
        _onCalculating()
      }
    }
  }, [props.show, _onCalculating, totalTicket])

  const _onHide = () => {
    if (done) {
      props.onHide(totalRewardTicket.current, totalUnrewardTicket.current, async () => {
        firstTime.current = null
        totalRewardTicket.current = 0
        totalUnrewardTicket.current = 0
        totalReward.current = 0
        setTotalTicket(0)
        setDone(false)
        window.location.reload()
      })
    } else {
      alert('Chưa tính thưởng xong')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Tính thưởng kỳ #{props.period && props.period.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {firstTime.current === null && 'Đang đếm vé'}
        {firstTime.current !== null && totalTicket > 0 &&
          <>
            <ProgressBar className={`progress-md`} variant={'success'} now={totalRewardTicket.current + totalUnrewardTicket.current} min={0} max={totalRewardTicket.current + totalUnrewardTicket.current + totalTicket} />
            {totalRewardTicket.current + totalUnrewardTicket.current} / <b>{totalRewardTicket.current + totalUnrewardTicket.current + totalTicket}</b><br />
            Vé trúng: <b>{totalRewardTicket.current}</b><br />
            Vé không trúng: <b> {totalUnrewardTicket.current}</b><br />
            Giải thưởng: <b> {totalReward.current}</b><br />
          </>
        }
        {firstTime.current !== null && totalTicket === 0 &&
          <>
            Không có vé mới
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
