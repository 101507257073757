import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { userName, userPhone, cartId, numbers, amount, createdAt, confirmAt, channelName, resultDate } = props;

  return (
    <tr>
      <td className='text-center'>{cartId}</td>
      <td>
        {userName || ""}<br />
        <span><strong className={`text-primary`}>{userPhone || ""}</strong></span>
      </td>
      <td className='text-center'>
        {channelName}<br />
        {resultDate}
      </td>
      <td className='text-center text-danger'><strong>{numbers.split('').join(' ')}</strong></td>
      <td className='text-center'>{amount}</td>
      <td className='text-center'>{moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
      <td className='text-center'>{moment(confirmAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
    </tr>
  );
};