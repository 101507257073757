import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'mbwebinapp'

export const getMBTransaction = async (token, id) => {
    const response = await axios.get(`${host}/${PLUGINS_NAME}/get-transaction?id=${id}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data.data || undefined
    }
    return undefined
}

export const refundMBTransaction = async (token, amount, id, desc) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/init-refund`, {
        amount: amount,
        transactionId: id,
        description: desc
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    debugger

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getMBDoisoatHistory = async (token, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-doi-soat-history`, {
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const uploadMBTCManual = async (token, file) => {

    var formData = new FormData();

    formData.append("file", file);

    const response = await axios.post(`${host}/${PLUGINS_NAME}/upload-tc-manual`, formData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const uploadMBTCAuto = async (token, date) => {

    const response = await axios.post(`${host}/${PLUGINS_NAME}/upload-tc-auto`, {
        date: date
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const uploadDoiSoat = async (token, id) => {

    const response = await axios.post(`${host}/${PLUGINS_NAME}/upload-doi-soat`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const doisoat = async (token, id) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/doi-soat`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        responseType: 'arraybuffer'
    })

    if (response && response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'doi_soat.zip');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return true
    }
    return false
}

export const downloadDoiSoat = async (token, id) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/download-doi-soat`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        responseType: 'arraybuffer'
    })

    if (response && response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'doi_soat.zip');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return true
    }
    return false
}
