import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { getUserAmountByPhone } from "../../../../../controller/api/user";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [user, setUser] = useState({})

  useEffect(() => {
    const getUserFunc = async () => {
      try {
        setUser({})
        const user = await getUserAmountByPhone(cookies.lottmekong, props.phone)

        if (user) {
          setUser(user)
        }
      } catch (e) {
        alert('Người dùng ko tồn tại, không thể lấy thông tin tài khoản')
      }
    }
    if (props.phone) {
      getUserFunc()
    }
  }, [props.phone, cookies.lottmekong])

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.gamePeriod && 'Kết quả kỳ: #' + props.gamePeriod.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <thead style={{ backgroundColor: 'gray', color: 'white' }}>
            <tr>
              <th>Ví chơi</th>
              <th>Ví Thưởng</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(user).length > 0 ?
              <tr>
                <td>{user.amount ? new Intl.NumberFormat('vn-VI', {}).format(user.amount) : ''}</td>
                <td>{user.rewardAmount ? new Intl.NumberFormat('vn-VI', {}).format(user.rewardAmount) : ''}</td>
              </tr>
              :
              <tr>
                <td colSpan={10}>Đang tải</td>
              </tr>
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="success" onClick={syncAmount}>Xem lịch sử tài khoản</Button> */}
        <Button variant="danger" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
