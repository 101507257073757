import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { user, createdAt, status, channel, id, resultDate, value, lotoType, numbers } = props;

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  return (
    <tr>
      <td className="text-center">{id}</td>
      <td className="text-center">
        <b>{(user && user.endPointId) || ""}</b><br />
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className="text-center">
        {channel}<br />
        <span><strong className={`text-primary`}>{resultDate}</strong></span>
      </td>
      <td className="text-center">
        Lô: {lotoType}<br />
        số: {numbers}<br />
        giá vé: {new Intl.NumberFormat('vn-VI', {}).format(value)}
      </td>
      <td className="text-center">{datedString}</td>
    </tr>
  );
};