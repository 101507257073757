
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import topbar from "topbar";
import { ResultItem } from "./partials/ResultItem";
import { getTicketRollStatus } from "../../../controller/api/systemApi";

export default () => {
  const [cookies] = useCookies(['lottmekong']);

  const [loaded, setLoaded] = useState(true);

  const [isRollEnabled, setIsRollEnabled] = useState(false);

  const getAllFunc = useCallback(async () => {
    setLoaded(false)

    const resp = await getTicketRollStatus(cookies.lottmekong, '', false, true)

    setLoaded(true)

    setIsRollEnabled(resp)

  }, [cookies.lottmekong])

  useEffect(() => {
    if (loaded) {
      topbar.hide();
    } else {
      topbar.show();
    }
  }, [loaded])

  useEffect(() => {
    document.title = "Trang chủ"
  }, []);

  useEffect(() => {
    topbar.config({
      autoRun: true,
      barThickness: 3,
      barColors: {
        '0': 'rgba(26,  188, 156, .9)',
        '.25': 'rgba(52,  152, 219, .9)',
        '.50': 'rgba(241, 196, 15,  .9)',
        '.75': 'rgba(230, 126, 34,  .9)',
        '1.0': 'rgba(211, 84,  0,   .9)'
      },
      shadowBlur: 10,
      shadowColor: 'rgba(0,   0,   0,   .6)'
    })
    getAllFunc();
  }, [getAllFunc])

  return (
    <>
      <Col>
        <ResultItem isRollEnabled={isRollEnabled} />
      </Col>
    </>
  );
};
