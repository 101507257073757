import React from "react";
import { Table } from '@themesberg/react-bootstrap';

const TableRow = (props) => {
  const { numbers, rIndex, playMethod, rewardAmount, rewards, unitValue, rewardNumbers } = props;

  const _rewardNumbers = rewardNumbers !== "" ? JSON.parse(rewardNumbers) : {}

  if (rewards && rewards.length > 0) {
    return (
      <tr>
        <td>
          {['A', 'B', 'C', 'D', 'E', 'F'][rIndex]}
        </td>
        <td>
          {numbers.split(' ').map(it => {
            return <span className={`text-'primary'}`}> &nbsp;{it} &nbsp;</span>
          })}
        </td>
        <td>{parsePlayMethod(playMethod, numbers.split(' ').length)}</td>
        <td>{new Intl.NumberFormat('vn-VI', {}).format(unitValue) || ""}</td>
        <td>
          {
            rewards.map((it, ind) => {
              return (
                <>
                  {ind + 1}. {it.name} x {_rewardNumbers[it.id]}
                  <br />
                  {'=>'} {new Intl.NumberFormat('vn-VI', {}).format(it.amount) || ""} x {_rewardNumbers[it.id]} x {unitValue / 10_000}
                  {ind < rewards.length - 1 &&
                    <hr />
                  }
                </>
              )
            })
          }
        </td>
        <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(rewardAmount) || ""}</td>
      </tr>
    );
  }
  return null;
};

export default ({ numberRows }) => {
  return (
    <Table responsive className="align-items-center table-bordered table-hover">
      <thead className="thead-light">
        <tr>
          <th scope="col">STT</th>
          <th scope="col">Dãy số</th>
          <th scope="col">Cách chơi</th>
          <th scope="col">Giá trị</th>
          <th scope="col">Giải thưởng</th>
          <th scope="col">Giá trị giải thưởng</th>
        </tr>
      </thead>
      <tbody>
        {numberRows && numberRows.map((pv, index) => <TableRow rIndex={index} key={`rows-keys-lottmekong-${pv.id}`} {...pv} />)}
      </tbody>
    </Table>
  );
}

const parsePlayMethod = (method, numbers = 0) => {
  switch (method) {
    case 'MULTIGROUP':
      return `Bao ${numbers} bộ số`
    default:
      return '';
  }
}


