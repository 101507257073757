
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import topbar from "topbar";
import { getCurrentPeriodTicket } from "../../../controller/api/gamePeriod";
import { disableTicketRoll, enableTicketRoll } from "../../../controller/api/systemApi";
import { LinkWeb } from "./partials/LinkWeb";

export default () => {
  const [cookies] = useCookies(['lottmekong']);

  const [data, setData] = useState({})

  const [loaded, setLoaded] = useState(true);

  const getAllFunc = useCallback(async () => {
    setLoaded(false)
    const resp = await getCurrentPeriodTicket(cookies.lottmekong)

    setData(resp)

    setLoaded(true)

  }, [cookies.lottmekong])

  useEffect(() => {
    if (loaded) {
      topbar.hide();
    } else {
      topbar.show();
    }
  }, [loaded])

  useEffect(() => {
    document.title = "Trang chủ"
  }, []);

  useEffect(() => {
    topbar.config({
      autoRun: true,
      barThickness: 3,
      barColors: {
        '0': 'rgba(26,  188, 156, .9)',
        '.25': 'rgba(52,  152, 219, .9)',
        '.50': 'rgba(241, 196, 15,  .9)',
        '.75': 'rgba(230, 126, 34,  .9)',
        '1.0': 'rgba(211, 84,  0,   .9)'
      },
      shadowBlur: 10,
      shadowColor: 'rgba(0,   0,   0,   .6)'
    })
    getAllFunc();
    return () => {
    }
  }, [getAllFunc])

  const onEnableRollClick = async (gameId) => {
    await enableTicketRoll(cookies.lottmekong, gameId, false)
    getAllFunc()
  }

  const onDisableRollClick = async (gameId) => {
    await disableTicketRoll(cookies.lottmekong, gameId, false)
    getAllFunc()
  }

  return (
    <>
      <Table responsive className="align-items-center table-bordered table-hover">
        <thead className='text-center'>
          <tr>
            <th scope="col">Sản phẩm</th>
            <th scope="col">Chia vé</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center"><b>KENO</b></td>
            <td className={"text-center " + (data.isRollKenoEnabled ? 'text-success' : 'text-danger')}>
              <h5>{data.isRollKenoEnabled ? 'Đã bật' : 'Đã tắt'}</h5>
              {data.isRollKenoEnabled ?
                <Button variant="danger" size="lg" onClick={() => onDisableRollClick('keno')}>Tắt chia vé</Button>
                :
                <Button variant="success" size="lg" onClick={() => onEnableRollClick('keno')}>Bật chia vé</Button>
              }
            </td>
          </tr>
          <tr>
            <td className="text-center"><b>MEGA</b></td>
            <td className={"text-center " + (data.isRollMegaEnabled ? 'text-success' : 'text-danger')}>
              <h5>{data.isRollMegaEnabled ? 'Đã bật' : 'Đã tắt'}</h5>
              {data.isRollMegaEnabled ?
                <Button variant="danger" size="lg" onClick={() => onDisableRollClick('mega')}>Tắt chia vé</Button>
                :
                <Button variant="success" size="lg" onClick={() => onEnableRollClick('mega')}>Bật chia vé</Button>
              }
            </td>
          </tr>
          <tr>
            <td className="text-center"><b>POWER</b></td>
            <td className={"text-center " + (data.isRollPowerEnabled ? 'text-success' : 'text-danger')}>
              <h5>{data.isRollPowerEnabled ? 'Đã bật' : 'Đã tắt'}</h5>
              {data.isRollPowerEnabled ?
                <Button variant="danger" size="lg" onClick={() => onDisableRollClick('power')}>Tắt chia vé</Button>
                :
                <Button variant="success" size="lg" onClick={() => onEnableRollClick('power')}>Bật chia vé</Button>
              }
            </td>
          </tr>
          <tr>
            <td className="text-center"><b>MAX3D</b></td>
            <td className={"text-center " + (data.isRollMax3dEnabled ? 'text-success' : 'text-danger')}>
              <h5>{data.isRollMax3dEnabled ? 'Đã bật' : 'Đã tắt'}</h5>
              {data.isRollMax3dEnabled ?
                <Button variant="danger" size="lg" onClick={() => onDisableRollClick('max3d')}>Tắt chia vé</Button>
                :
                <Button variant="success" size="lg" onClick={() => onEnableRollClick('max3d')}>Bật chia vé</Button>
              }
            </td>
          </tr>
          <tr>
            <td className="text-center"><b>MAX3D PRO</b></td>
            <td className={"text-center " + (data.isRollMax3dProEnabled ? 'text-success' : 'text-danger')}>
              <h5>{data.isRollMax3dProEnabled ? 'Đã bật' : 'Đã tắt'}</h5>
              {data.isRollMax3dProEnabled ?
                <Button variant="danger" size="lg" onClick={() => onDisableRollClick('max3dpro')}>Tắt chia vé</Button>
                :
                <Button variant="success" size="lg" onClick={() => onEnableRollClick('max3dpro')}>Bật chia vé</Button>
              }
            </td>
          </tr>
        </tbody>
      </Table>
      <LinkWeb />
    </>
  );
};
