import React, { useCallback, useContext, useRef } from 'react'
import { Col, Row, Card, Form, Container, Button, InputGroup } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { Context } from '../Cart'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _submitForm = useCallback(() => {
    dispatch({
      type: 'SUBMIT_FILTER',
      payload: {}
    })
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>{"Đơn mới"}</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tải lại</Button>
            </Col>
          </Row>
        </Card.Header>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)