import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import React, { useCallback, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { stopSales } from '../../../../controller/api/__classic__/result';

const notHaveResultYet = <td className="text-warning text-center">{"Chưa có kết quả"}</td>

const cannotGetResult = <td className="text-danger text-center">{"Không lấy được kết quả"}</td>

export const TableRow = (props) => {
  const { id, handleAddResult, resultDate, ClassicLotteryChannel, ClassicLotteryStorage, ClassicTicket, result, status, onCalculateRewardPress, onCalculateRewardUnmarketablePress, handleAddTicket, totalRewaredTickets, totalUnRewardTickets, totalRewaredUnmarketableTickets, totalUnRewardUnmarketableTickets } = props;

  const [cookies] = useCookies(['lottmekong'])

  const linkToStatistic = `/classic/statistic-by-ticket?resultId="${id}"`
  const linkToUnmarketableTicket = `/classic/statistic-by-unmarketable-ticket?resultId="${id}"`

  const [statusState, setStatusState] = useState(status)

  const [resultData, setResultData] = useState(result)

  const updateNewResult = useCallback((newResult) => {
    setResultData(newResult)
    if (statusState === 2) {
      setStatusState(3)
    }
  }, [statusState])

  const viewResult = (<td className="text-center">
    <Button variant='info' onClick={() => handleAddResult(id, resultData, updateNewResult, statusState < 3)}>
      Xem kết quả
    </Button>
  </td>);

  const addResult = (<td className="text-center">
    <Button variant='danger' onClick={() => handleAddResult(id, resultData, updateNewResult, true)}>
      Nhập kết quả
    </Button>
  </td>);

  const resultTd = useMemo(() => {
    let _resultTd = notHaveResultYet

    if (statusState === 1) {
      _resultTd = addResult
    } else if (statusState === 3 || statusState === 4 || statusState === 5) {
      if (resultData && resultData.length > 0) {
        _resultTd = viewResult
      } else {
        _resultTd = cannotGetResult
      }
    }

    return _resultTd
  }, [statusState, resultData, addResult, viewResult])

  // const realPhone = classicUser.filter(it => it.realClassicTicket).length > 0 ? classicUser.filter(it => it.realClassicTicket)[0].phone : ''
  // const unMarketPhone = classicUser.filter(it => it.unmarket).length > 0 ? classicUser.filter(it => it.unmarket)[0].phone : ''

  let inputAmount = 0
  let newAmount = 0
  let soldAmount = 0
  let doneAmount = 0
  let storageAmount = 0

  ClassicLotteryStorage.forEach(element => {
    inputAmount += element.inputAmount
    storageAmount += element.amount
  });

  ClassicTicket.forEach(element => {
    if (element.status === 1 || element.status === 2) {
      newAmount += element.amount
    } else {
      doneAmount += element.amount
    }
    soldAmount += element.amount
  });

  const onStopSale = useCallback(async (resultId) => {

    const resp = await stopSales(cookies.lottmekong, resultId)
    if (resp) {
      const result = setStatusState(2)
      if (result != null) {
        window.location.reload()
      }
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }, [cookies.lottmekong])

  const actionTd = useMemo(() => {
    switch (statusState) {
      case 2:
        return 'Đang chờ kết quả'
      case 3:
        if (inputAmount === 0) {
          return 'Không kinh doanh'
        }
        return <Button variant="primary" className="m-1" onClick={() => onCalculateRewardPress(id)}>Tính thưởng</Button>
      case 4:
      case 5:
        return (
          <>
            Vé bán: <b className='text-success'>{totalRewaredTickets}</b> | <b className='text-danger'>{totalUnRewardTickets}</b><br />
            Vé ế: <b className='text-success'>{totalRewaredUnmarketableTickets}</b> | <b className='text-danger'>{totalUnRewardUnmarketableTickets}</b><br />
            {storageAmount > 0 && (totalRewaredUnmarketableTickets + totalUnRewardUnmarketableTickets === storageAmount) &&
              <Button variant="primary" className="m-1">
                <Link
                  target={'_blank'}
                  style={{ color: '#fff' }}
                  to={linkToUnmarketableTicket}
                  key={id}
                >
                  {'Xem vé ế'}
                  <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
                </Link>
              </Button>
            }
            {storageAmount > 0 && (totalRewaredUnmarketableTickets + totalUnRewardUnmarketableTickets < storageAmount) &&
              <Button variant="warning" className="m-1" onClick={() => onCalculateRewardUnmarketablePress(id)}>Tính thưởng vé ế</Button>
            }
            <Button variant="warning" className="m-1">
              <Link
                target={'_blank'}
                style={{ color: '#fff' }}
                to={linkToStatistic}
                key={id}
              >
                {'Xem vé bán'}
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
              </Link>
            </Button>
          </>
        )
      default:
        return '';
    }
  }, [statusState, ClassicLotteryChannel.name, handleAddTicket, id, inputAmount, linkToStatistic, linkToUnmarketableTicket, onCalculateRewardPress, onCalculateRewardUnmarketablePress, onStopSale, resultDate, storageAmount, totalRewaredTickets, totalRewaredUnmarketableTickets, totalUnRewardTickets, totalUnRewardUnmarketableTickets])

  return (
    <tr>
      <td className="text-center">
        {actionTd}
      </td>
      <td className="text-center">
        {ClassicLotteryChannel.name}<br />
        {resultDate}
      </td>
      {resultTd}
      {inputAmount === 0 && <td colSpan={3} className="text-center"></td>}
      {inputAmount > 0 &&
        <>
          <td className="text-center">{inputAmount}</td>
          <td className="text-center">
            <b>Tổng cộng: {soldAmount}</b> <br />
            Mới: {newAmount} <br />
            Đã hoàn thành: {doneAmount}
          </td>
          <td className="text-center"><strong>{storageAmount}</strong></td>
        </>
      }
    </tr>
  );
};