import React, { useRef, useState } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import { AddAccountForm } from "./partials/AddAccountForm";
import { useCookies } from "react-cookie";
import { addAccountAPI } from "../../../../../controller/api/account";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [error, setError] = useState(false)

  const accountNameRef = useRef('')

  const accountNumberRef = useRef('')

  const bankRef = useRef(0)

  const nameRef = useRef(0)

  const onNameChange = (text) => {
    nameRef.current = text
  }

  const onAccountNameChange = (text) => {
    accountNameRef.current = text
  }

  const onAccountNumberChange = (text) => {
    accountNumberRef.current = text
  }

  const onBankSelect = (id) => {
    bankRef.current = id
  }

  const validateAddAccount = () => {
    // if (accountRef.current > 0 && valueRef.current.length > 0 && detectedUser) {
    //   return true
    // } else {
    //   return false
    // }

    return true
  }

  const onSubmit = async () => {
    if (validateAddAccount()) {
      const resp = await addAccountAPI(cookies.lottmekong, nameRef.current, bankRef.current, accountNameRef.current, accountNumberRef.current)
      if (resp) {
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } else {
      setError(true)
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm tài khoản
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <AddAccountForm
          setBank={onBankSelect}
          setName={onNameChange}
          setAccountName={onAccountNameChange}
          setAccountNumber={onAccountNumberChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm tài khoản</Button>
      </Modal.Footer>
    </Modal>
  );
}
