import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { rIndex, id, channel, numbers, amount, resultDate, createdAt } = props;

  const datedString = moment(createdAt || '').format('DD/MM/YYYY').toLocaleString()

  return (
    <tr>
      <td className='text-center fw-bold'>{rIndex + 1}</td>
      <td>{id}</td>
      <td className='text-center'>
        {channel}<br />
        {resultDate}
      </td>
      <td style={{ fontSize: 25 }} className='text-center text-danger fw-bold'>{numbers.split('').join(' ')}</td>
      <td className='text-center text-success fw-bold'>{amount}</td>
      <td className='text-center'>
        {datedString}
      </td>
    </tr>
  );
};
