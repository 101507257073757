import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getAllUsers, updateStatus } from '../../../../controller/api/user'
import UserAmount from '../sub-screen/user-amount/UserAmount'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../User'
import PinModal from '../sub-screen/pin-modal/PinModal'

const _Table = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const getAllFunc = useCallback(async () => {
    let phone = state.phone

    const response = await getAllUsers(cookies.lottmekong, {
      phone: phone
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.phone, page])

  const [modalShow, setModalShow] = useState(false)

  const [pinModalShow, setPinModalShow] = useState(false);

  const userPhoneRef = useRef(null)

  const _userId = useRef('');

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  const onShowUserAmount = (phone) => {
    userPhoneRef.current = phone
    setModalShow(true)
  }

  const onAssignPin = (id) => {
    _userId.current = id
    setPinModalShow(true)
  }

  const onStatusChange = async (evt, id) => {
    const newStatus = evt.target.value
    const resp = await updateStatus(cookies.lottmekong, id, newStatus)
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Tên khách hàng</th>
              <th scope="col">Số điện thoại</th>
              <th scope="col">Lần cuối mua vé</th>
              <th scope="col">Ngày đăng ký</th>
              <th scope="col">Nền tảng</th>
              <th scope="col">Hệ điều hành</th>
              <th scope="col">Phiên bản</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow rIndex={index} onStatusChange={onStatusChange} assignPin={onAssignPin} onShowUserAmount={onShowUserAmount} key={`user-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <UserAmount
        show={modalShow}
        phone={userPhoneRef.current}
        onHide={() => setModalShow(false)}
      />
      <PinModal
        show={pinModalShow}
        userId={_userId.current}
        onHide={() => setPinModalShow(false)}
      />
    </>
  );
};

export default React.memo(_Table)