import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React, { useContext } from 'react'
import { Context } from '../MBDoisoat'

export const TableRow = (props) => {
  const { dialDate, status, detail, id } = props.data;

  const { state } = useContext(Context);

  const { onDoisoat, onUpload, onDownload } = props;

  return (
    <tr>
      {state.id === id ? <td className="text-center">
      </td> : <td className="text-center">
        {(status === 0) &&
          <Button variant="danger" onClick={() => onDoisoat(id)}>
            Đối soát
          </Button>
        }
        {(status === 1) &&
          <Button variant="info" onClick={() => onUpload(id)}>
            Upload
          </Button>
        }
        {(status === 2) &&
          <Button variant="success" onClick={() => onDownload(id)}>
            Download
          </Button>
        }
      </td>}
      <td className='text-center'>
        {moment(dialDate || '').format('DD/MM/YYYY').toLocaleString()}
      </td>
      <td className="text-center">{detail}</td>
      {status === 0 ?
        <td className="text-center">Mới</td>
        :
        <td className="text-center text-warning">Đã đối soát</td>
      }
    </tr>
  );
};