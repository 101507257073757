import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import { useCookies } from "react-cookie";
import { getListClassicCart } from '../../../controller/api/__classic__/cart'
import { calculatePage } from "../../../helper/helper";
import Summary from "./partials/Summary";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    from: new Date(),
    to: new Date(),
    phone: '',
    page: 0
  },
  data: {
    list: [],
    total: 0,
    totalPage: 0
  },
  filterPressCount: 0,
  exportPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default ({ region }) => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {

    const response = await getListClassicCart(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
      isDone: 1,
      region: region,
      phone: state.filter.phone,
    }, state.filter.page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      dispatch({
        type: 'UPDATE_DATA',
        payload: res
      })
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.page, state.filter.phone, region])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {
    document.title = "Đơn hoàn thành " + `${region === 'MN' ? 'Miền Nam' : 'Miền Trung'}`
  }, [region]);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm region={region} />

      <br />

      <Summary region={region} />

      <br />

      <Table />
    </Context.Provider>
  );
};
