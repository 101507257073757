import React, { useCallback, useEffect, useReducer, useState } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import Summary from "./partials/Summary";
import ExportProcessingPopup from "./sub-screen/export-processing/ExportProcessingPopup";
import { useCookies } from "react-cookie";
import { getPrintedTicketStatistic } from "../../../controller/api/statistic/printTicket";
import { calculatePage } from "../../../helper/helper";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    from: new Date(),
    to: new Date(),
    phone: '',
    gameId: '',
    page: 0
  },
  data: {
    list: [],
    total: 0,
    totalPage: 0
  },
  filterPressCount: 0,
  exportPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'EXPORT_DATA':
      return {
        ...state,
        exportPressCount: state.exportPressCount + 1
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [showProcessingModal, setShowProcessingModal] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    let phone = state.filter.phone

    const response = await getPrintedTicketStatistic(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
      phone: phone,
      gameId: state.filter.gameId
    }, state.filter.page)

    if (response) {
      dispatch({
        type: 'UPDATE_DATA',
        payload: {
          list: response.data,
          total: response.total,
          totalPage: calculatePage(response.total)
        }
      })
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.page, state.filter.phone, state.filter.gameId])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {
    if (state.exportPressCount > 0) {
      setShowProcessingModal(true)
    }
  }, [state.exportPressCount])

  useEffect(() => {
    document.title = "Doanh thu in vé"
  }, [])

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Summary />

      <br />

      <Table />

      <ExportProcessingPopup
        total={state.data.total}
        show={showProcessingModal}
        onHide={() => setShowProcessingModal(false)}
      />
    </Context.Provider>
  );
};
