import React, { useState } from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { addKenoResults } from "../../../../../controller/api/gameResult";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const maxCount = 20

  const [listSelected, setListSelected] = useState([])

  const _onHide = () => {

    props.onHide()

  }

  const _generateRows = (cols, skip) => {
    let res = []
    let x = 0
    while (x < cols) {
      x++
      const number = x + skip
      const label = number < 10 ? `0${number}` : `${number}`

      const selected = listSelected.indexOf(label) >= 0

      res.push(<td key={label}><div onClick={() => _onItemPress(label)} style={{...styles.items, backgroundColor: selected ? 'red' : 'gray'}}>{label}</div></td>)
    }
    return res
  }

  const _onItemPress = (number) => {
    const index = listSelected.indexOf(number)
    if (index >= 0) {
      setListSelected(listSelected.filter(e => e !== number))
    } else {
      if (listSelected.length < maxCount) {
        listSelected.push(number)
        listSelected.sort()
        setListSelected(JSON.parse(JSON.stringify(listSelected)))
      }
    }
  }

  const validateAddAccount = () => {
    return listSelected.length === maxCount
  }

  const onSubmit = async () => {
    if (validateAddAccount()) {
      const resp = await addKenoResults(cookies.lottmekong, props.period.id, listSelected)
      if (resp) {
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } else {
      
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nhập kết quả kỳ #{props.period && props.period.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table borderless>
          <tbody>
            <tr>
              {_generateRows(10, 0)}
            </tr>
            <tr>
            {_generateRows(10, 10)}
            </tr>
            <tr>
            {_generateRows(10, 20)}
            </tr>
            <tr>
            {_generateRows(10, 30)}
            </tr>
            <tr>
            {_generateRows(10, 40)}
            </tr>
            <tr>
            {_generateRows(10, 50)}
            </tr>
            <tr>
            {_generateRows(10, 60)}
            </tr>
            <tr>
            {_generateRows(10, 70)}
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = {
  items: {
    width: 50,
    height: 50,
    fontWeight: 'bolder',
    fontSize: 18,
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: 'gray',
    color: 'white',
    alignItems:'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 100
  }
};
