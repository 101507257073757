import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../Statistic'
import { getStatisticSummary } from '../../../../controller/api/__classic__/statistic-by-unmarketable-ticket';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../../../../helper/helper';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const location = useLocation();

  const [data, setData] = useState({
    count: 0,
    reward: 0,
    unReward: 0
  })

  const getSummaryFunc = useCallback(async () => {

    const queryObj = parseQuery(location.search);

    if (queryObj.resultId) {

      let phone = state.filter.phone

      if (phone.indexOf('MB') < 0) {
        phone = phone.replace('0', '+84')
      }

      const response = await getStatisticSummary(cookies.lottmekong, {
        phone: phone,
        resultId: queryObj.resultId
      })

      if (response) {
        const res = {
          count: response.total,
          reward: response.totalReward || 0,
          unReward: response.totalUnReward || 0
        }
        setData(res || {})
      }
    }
  }, [cookies.lottmekong, state.filter.phone, location.search])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row>
            <Col md={4}>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Tổng số vé</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>{data.count}</Form.Label>
              </Row>
            </Col>
            <Col md={4}>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Trúng thưởng</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>{data.reward}</Form.Label>
              </Row>
            </Col>
            <Col md={4}>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Không trúng</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>{data.unReward}</Form.Label>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)