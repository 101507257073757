import React, { useContext, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { TableRow } from '../component/TableRow'
import Pagination from '../../../../components/Pagination'
import { Context } from '../Period'
import AddResultPopup from '../sub-screen/add-result-popup/AddResultPopup'
import CalculateRewardPopup from '../sub-screen/calculate-reward-popup/CalculateRewardPopup'

const _Table = () => {

  const { state, dispatch } = useContext(Context);

  const [addPopupShow, setAddPopupShow] = useState(false)

  const [modalCalculateRewardShow, setModalCalculateRewardShow] = useState(false)

  const resultIdRef = useRef(null)

  const resultRef = useRef(null)

  const onShowAddPopupPress = (id, result) => {
    resultIdRef.current = id
    resultRef.current = result
    setAddPopupShow(true)
  }

  const onShowCalculateRewardPopupPress = (resultId) => {
    resultIdRef.current = resultId
    setModalCalculateRewardShow(true)
  }

  const onHideCalculateRewardPopupPress = (totalRewardTicket, totalUnrewardTicket, callback) => {

    resultIdRef.current = null

    callback()

    setModalCalculateRewardShow(false)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th rowSpan={2} scope="col">Thao tác</th>
              <th rowSpan={2} scope="col">Thông tin</th>
              <th rowSpan={2} scope="col">Kết quả</th>
              <th colSpan={3} scope="col">Số lượng</th>
            </tr>
          </thead>
          <tbody>
            {state.data.list && state.data.list.map((pv, index) =>
              <TableRow
                onCalculateRewardPress={onShowCalculateRewardPopupPress}
                handleAddResult={onShowAddPopupPress}
                rIndex={index}
                key={`table-keys-lottmekong-${pv.id}`}
                {...pv}
              />
            )}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={state.data.totalPage || 0}
                currentPage={state.filter.page || 0}
                onPageChange={(i) => {
                  dispatch({
                    type: 'UPDATE_PAGE',
                    payload: i
                  })
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <AddResultPopup
        show={addPopupShow}
        id={resultIdRef.current}
        result={resultRef.current}
        onHide={() => {
          resultIdRef.current = null
          setAddPopupShow(false)
        }}
      />
      <CalculateRewardPopup
        show={modalCalculateRewardShow}
        resultId={resultIdRef.current}
        onHide={onHideCalculateRewardPopupPress}
      />
    </>
  );
};

export default React.memo(_Table)