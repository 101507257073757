import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { id, user, gameId, value, createdAt, status, reason } = props;
  const string_status = status === 6 ? "Đã hoàn tiền" : "Chưa hoàn tiền"
  return (
    <tr>
      <td>
        <span><strong className={`text-primary`}>${id}</strong></span>
      </td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className='text-center'>
        {gameId &&
          <img alt='' src={require(`../../../../assets/img/logo-${gameId}.png`)} className="img-thumbnail" width={90} height={30}></img>
        }
      </td>
      <td className='text-center'>{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td className='text-center'>{string_status}</td>
      <td className='text-center'>{moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
      <td className='text-center'>{reason}</td>
    </tr>
  );
};