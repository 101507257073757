import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import React from 'react'

export const TableRow = (props) => {
  const { gamePeriod, result, status, totalTickets, totalRewaredTickets, totalUnRewardTickets, onShowResultPress, onInputResultPress, onCalculateRewardPress, onConfirmNumberTicket } = props;

  const notHaveResultYet = <td className="text-warning">{"Chưa có kết quả"}</td>

  const cannotGetResult = <td className="text-danger">{"Không lấy được kết quả"}</td>

  let resultTd = notHaveResultYet

  if (result && result.length > 0) {
    resultTd = <td>
      <Button variant="info" className="m-1" onClick={() => onShowResultPress(result, gamePeriod)}>Xem kết quả</Button>
    </td>
  } else {
    if (status === 1) {
      resultTd = cannotGetResult
    }
  }

  let actionTd = null

  if (status === 1 && result.length === 0) {
    actionTd = <Button variant="danger" className="m-1" onClick={() => onInputResultPress(gamePeriod)}>Nhập kết quả</Button>
  } else if (status === 0) {
    actionTd = <Button variant="danger" className="m-1" onClick={() => onInputResultPress(gamePeriod)}>Nhập kết quả</Button>
  }

  return (
    <tr>
      <td>
        {actionTd === null &&
          <>
            <Button variant="info" className="m-1" onClick={() => onCalculateRewardPress(gamePeriod)}>Tính thưởng</Button>
          </>
        }
        {actionTd}
      </td>
      <td>
        <b>{'#' + gamePeriod.name || ""}</b><br />
        {gamePeriod.resultDate}<br />
        <span className="text-info">{gamePeriod.resultTime}</span>
      </td>
      <td>
        Tổng số vé: <b className='text-info'>{totalRewaredTickets + totalUnRewardTickets}</b><br />
        Trúng thưởng: <b className='text-success'>{totalRewaredTickets}</b><br />
        Không trúng thưởng: <b className='text-danger'>{totalUnRewardTickets}</b><br />
      </td>
      {resultTd}
      <td className='text-center'>
        {totalTickets !== 0 &&
          <FontAwesomeIcon className='text-success' icon={faCheckCircle} size="4x" />
        }
        {totalTickets === 0 && <Button variant="warning" className="m-1" onClick={() => onConfirmNumberTicket(gamePeriod.id, totalRewaredTickets + totalUnRewardTickets === 0 ? -1 : totalRewaredTickets + totalUnRewardTickets)}>Xong</Button>}
      </td>
    </tr>
  );
};