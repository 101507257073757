import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../Reward'
import { getSummaryReward } from '../../../../controller/api/statistic/wipReward'
import { useCookies } from 'react-cookie';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [summary, setSummary] = useState({
    gross: 0,
    tax: 0,
    fee: 0,
    net: 0,
  })

  const getSummaryFunc = useCallback(async () => {

    let phone = state.filter.phone

    const response = await getSummaryReward(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
      phone: phone,
      gameId: state.filter.gameId
    })

    if (response) {
      const res = {
        gross: response.gross,
        tax: response.tax,
        fee: response.fee,
        net: response.net
      }
      setSummary(res || {})
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.phone, state.filter.gameId])

  const listSumary = useMemo(() => {
    return (
      <>
        {/* <Col md={3} sm={12}> */}
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Giải thưởng"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.gross) || ""}</Form.Label>
          </Row>
        {/* </Col> */}
        {/* <Col md={3} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Thuế"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center text-danger'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.tax) || ""}</Form.Label>
          </Row>
        </Col> */}
        {/* <Col md={3} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Phí"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center text-warning'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.fee) || ""}</Form.Label>
          </Row>
        </Col> */}
        {/* <Col md={3} sm={12}>
          <Row md={12} className='text-center align-self-center'>
            <Form.Label>{"Thực nhận"}</Form.Label>
          </Row>
          <Row md={12} className='text-center align-self-center text-success'>
            <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(summary.net) || ""}</Form.Label>
          </Row>
        </Col> */}
      </>
    )
  }, [summary])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row>
            {listSumary}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)