import React from 'react'
import { Button } from '@themesberg/react-bootstrap';

export const TableRow = (props) => {
  const { user, secondLevelAgentId, secondLevelAgent, onDelete, userId } = props;

  return (
    <tr className='text-center'>
      <td className='text-center'>
        <Button variant="danger" className="m-1" onClick={() => onDelete(userId, user.name)}>Xóa</Button>
      </td>
      <td className='text-info'>{secondLevelAgent.firstLevelAgentId}</td>
      <td className='text-info'>{secondLevelAgentId}</td>
      <td>
        <span>{user.name || ""}</span><br />
        <span className={`fw-900 text-info`}>{user.identify || ""}</span><br />
        {user.phone}
      </td>
    </tr>
  );
};