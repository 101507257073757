import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { getListClassicSalesTicket } from '../../../../controller/api/__classic__/ticketStorage'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import { Context } from '../SalesTicketStorage'

const _Table = ({region}) => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const getAllFunc = useCallback(async () => {

    const response = await getListClassicSalesTicket(cookies.lottmekong, {
      channel: state.channel,
      region: region,
    }, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.phone, state.channel, region, page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Mã vé</th>
              <th scope="col">Kỳ quay</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Số lượng</th>
              <th scope="col">Ngày nhập</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage || 0}
                currentPage={page || 0}
                onPageChange={(i) => {
                  setPage(i)
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default React.memo(_Table)