import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table, Button } from '@themesberg/react-bootstrap'
import { confirmNumberTicket, getPowerResults } from '../../../../controller/api/gameResult'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import CalculateRewardPopup from '../sub-screen/calculate-reward-popup/CalculateRewardPopup'

const TableRow = (props) => {
  const { gamePeriod, result, status, totalTickets, totalRewaredTickets, totalUnRewardTickets, onCalculateRewardPress, onConfirmNumberTicket } = props;

  const resultArray = result.length > 0 ? result[0].split(' ') : []

  const specialNumber = resultArray.splice(-1)

  const baseArray = resultArray.join(' ')

  const notHaveResultYet = <td className="text-warning">{"Chưa có kết quả"}</td>

  const cannotGetResult = <td className="text-danger">{"Không lấy được kết quả"}</td>

  let resultTd = notHaveResultYet

  if (result && result.length > 0) {
    resultTd = <td>
      <h5><label className={"text-primary"}>{baseArray}</label> <label className={"text-warning"}>{specialNumber}</label></h5>
    </td>
  } else {
    if (status === 1) {
      resultTd = cannotGetResult
    }
  }

  return (
    <tr>
      <td>
        <Button variant="info" className="m-1" onClick={() => onCalculateRewardPress(gamePeriod)}>Tính thưởng</Button>
      </td>
      <td>
        <b>{'#' + gamePeriod.name || ""}</b><br />
        {gamePeriod.resultDate}<br />
        <span className="text-info">{gamePeriod.resultTime}</span>
      </td>
      <td>
        Tổng số vé: <b className='text-info'>{totalRewaredTickets + totalUnRewardTickets}</b><br />
        Trúng thưởng: <b className='text-success'>{totalRewaredTickets}</b><br />
        Không trúng thưởng: <b className='text-danger'>{totalUnRewardTickets}</b><br />
      </td>
      {resultTd}
      <td className='text-center'>
        {totalTickets !== 0 &&
          <FontAwesomeIcon className='text-success' icon={faCheckCircle} size="4x" />
        }
        {totalTickets === 0 && <Button variant="warning" className="m-1" onClick={() => onConfirmNumberTicket(gamePeriod.id, totalRewaredTickets + totalUnRewardTickets === 0 ? -1 : totalRewaredTickets + totalUnRewardTickets)}>Xong</Button>}
      </td>
    </tr>
  );
};

export const PowerTable = () => {

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const getAllFunc = useCallback(async () => {
    const response = await getPowerResults(cookies.lottmekong, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }

      setData(res || {})
      setLoaded(true)
    }
  }, [cookies.lottmekong, page])

  const onConfirmNumberTicket = async (periodId, numberTicket) => {
    const response = await confirmNumberTicket(cookies.lottmekong, periodId, numberTicket)

    if (response) {
      const _newData = JSON.parse(JSON.stringify(data))

      _newData.list = _newData.list.map(it => {
        if (it.gamePeriod.id === periodId) {
          it.totalTickets = numberTicket
        }
        return {
          ...it
        }
      })
      setData(_newData)
    }
  }

  const [modalCalculateRewardShow, setModalCalculateRewardShow] = useState(false)

  const gamePeriodRef = useRef(null)

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  const onShowCalculateRewardPopupPress = (gamePeriod) => {
    gamePeriodRef.current = gamePeriod
    setModalCalculateRewardShow(true)
  }

  const onHideCalculateRewardPopupPress = (totalRewardTicket, totalUnrewardTicket, callback) => {
    const _newData = JSON.parse(JSON.stringify(data))

    _newData.list = _newData.list.map(it => {
      if (it.gamePeriod.id === gamePeriodRef.current.id) {
        it.totalRewaredTickets += totalRewardTicket
        it.totalUnRewardTickets += totalUnrewardTicket
      }
      return {
        ...it
      }
    })
    setData(_newData)

    gamePeriodRef.current = null

    callback()

    setModalCalculateRewardShow(false)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Power 6/55 ({data.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Thống kê vé</th>
              <th scope="col">Kết quả quay số</th>
              <th scope="col">Đã tính thưởng</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow onConfirmNumberTicket={onConfirmNumberTicket} onCalculateRewardPress={onShowCalculateRewardPopupPress} rIndex={index} key={`result-status-${index}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setLoaded(false)
                  setPage(i)
                }}
              />
            </Col>
            <Col>
              {loaded ? '' : 'Đang tải dữ liệu'}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <CalculateRewardPopup
        show={modalCalculateRewardShow}
        period={gamePeriodRef.current}
        onHide={onHideCalculateRewardPopupPress}
      />
    </>
  );
};