import React, { useMemo } from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'

export const DeviceItem = ({ data }) => {

  const numberWaitingTicket = useMemo(() => {
    return data.classicCart.filter(e => e.status === 2).length
  }, [data])

  const numberPrintedTicket = useMemo(() => {
    return data.classicCart.filter(e => e.status > 2 && e.status < 6).length
  }, [data])

  return (
    <div className="shadow-md p-2 mb-4" style={{ backgroundColor: '#fff' }}>
      <div style={{ fontSize: 15 }}>
        <Row>
          <Col>
            {data.employee.name} ({data.employee.id})
          </Col>
          <Col className='text-right' style={{ color: numberWaitingTicket === 0 ? 'red' : 'green' }}>
            Đang chờ {numberWaitingTicket}
          </Col>
        </Row>
      </div>
      <hr className='m-0 mt-2 mb-3' />
      <div>
        <Row>
          <Col>
            Số đơn đã xuất
          </Col>
          <Col className='text-right'>
            {numberPrintedTicket}
          </Col>
        </Row>
      </div>
    </div>
  );
};