import React, { useCallback, useContext, useRef } from 'react'
import { Col, Row, Card, Form, InputGroup, Container, Button } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { Context } from '../MBDoisoat'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _from = useRef(state.from);

  const _handleChangeFrom = useCallback((payload) => {
    _from.current = payload
  }, [])

  const _submitForm = useCallback(() => {
    if (_from.current) {
      dispatch({
        type: 'UPLOAD_DATA_AUTO',
        payload: {
          date: _from.current,
        }
      })
    } else {
      alert('Chưa chọn ngày')
    }
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>UPLOAD MB TC TỰ ĐỘNG</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Upload</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Col>
              <Row className='p-1'>
                <Col>
                  <Row>
                    <Col md={3} className='text-right align-self-center'>
                      <Form.Label>Chọn ngày</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Datetime
                        timeFormat={false}
                        onChange={_handleChangeFrom}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={_from.current ? moment(_from.current).format("DD/MM/YYYY") : ""}
                              placeholder="dd/mm/yyyy"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)