import axios from 'axios'
import { host } from '../../helper/config'
import { ITEM_PER_PAGE } from '../../helper/helper'

const PLUGINS_NAME = 'admin-game-periods'

// add period
export const addPeriods = async (token, name, gameId, startDate, startTime, numberPeriod) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/add-period`, {
        name: name,
        gameId: gameId,
        startDate: startDate,
        startTime: startTime,
        numberPeriod: numberPeriod
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return true
    }
    return false
}

// keno
export const getKenoPeriods = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-keno`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

// max3d
export const getMax3dPeriods = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-max3d`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// max3dpro
export const getMax3dProPeriods = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-max3dpro`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// mega
export const getMegaPeriods = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-mega`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

// power
export const getPowerPeriods = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-power`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const getCurrentPeriodTicket = async (token) => {
    const response = await axios.get(`${host}/${PLUGINS_NAME}/get-current-period-ticket`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}
