import React from 'react'
import { Col, Row, Card } from '@themesberg/react-bootstrap'

const _FilterForm = ({ region }) => {

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>{region === 'MN' ? 'VÉ MIỀN NAM ĐANG BÁN' : 'VÉ MIỀN TRUNG ĐANG BÁN'}</h5>
            </Col>
          </Row>
        </Card.Header>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)