import React, { useCallback, useContext } from 'react'
import { Col, Row, Card } from '@themesberg/react-bootstrap'
import { Context } from '../MBDoisoat'
import './styles.css'

// drag drop file component
const DragDropFile = ({ handleFile }) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
        <div>
          <p>Kéo & Thả file xxx_TC.txt ở đây hoặc</p>
          <button className="upload-button" onClick={onButtonClick}>Nhấn ở đây</button>
        </div>
      </label>
      {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
    </form>
  );
};

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const handleFile = useCallback((files) => {
    const pattern = /PHZLKL_[0-9]{8}_TC.txt/gmi;

    const name = files[0].name;

    const result = pattern.test(name);

    if (result) {
      _submitForm(files[0]);
    } else {
      alert('File không đúng');
    }
  }, [])

  const _submitForm = useCallback((file) => {
    dispatch({
      type: 'UPLOAD_DATA',
      payload: {
        loading: true,
        file: file
      }
    })
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center' sm={12} md={10}>
              <h5>UPLOAD MB TC THỦ CÔNG</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body style={{ textAlign: 'center' }}>
          <DragDropFile handleFile={handleFile} />
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)