import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getMessaging, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyABWNfNQDWc4u57KJ7-8KBqe8F5-bVXjEQ",
  authDomain: "vietlott-stg.firebaseapp.com",
  databaseURL: "https://vietlott-stg-7ded4.asia-southeast1.firebasedatabase.app",
  projectId: "vietlott-stg",
  storageBucket: "vietlott-stg",
  messagingSenderId: "373241607270",
  appId: "1:373241607270:web:777ab0187acd9e506da9a7",
  measurementId: "G-VHC33YKPRJ"
};

// các tham số này là phần config lấy ra được từ phần 2. setting firebase nhé
const app = initializeApp(firebaseConfig)

export const storage = getStorage(app)

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

toast.success('🦄 Xin chào, Quản trị', {
  position: "top-right",
  autoClose: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);

  const title = payload.notification.title;

  document.querySelector('#' + title) && document.querySelector('#' + title).click()

  toast.warn(payload.notification.body, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  // ...
});

export default app;
