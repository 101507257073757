export const Routes = {

    // authen
    Signin: { path: "/login-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    ServerError: { path: "/server-error" },

    // trang chủ
    Overview: { path: "/" },
    CustomerOverview: { path: "/customer" },
    SettingOverview: { path: "/setting" },
    VietlottOverview: { path: "/vietlott" },
    ClassicOverview: { path: "/classic" },
    LotoOverview: { path: "/loto" },

    // CUSTOMER
    CustomerAddChargeApp: { path: "/customer/charge/app" },
    CustomerChargeHistoryApp: { path: "/customer/charge/history-app" },
    CustomerAddChargeWeb: { path: "/customer/charge/web" },
    CustomerChargeHistoryWeb: { path: "/customer/charge/history-web" },
    CustomerIssueCharge: { path: "/customer/charge/issue-charge" },
    CustomerChargeSummary: { path: "/customer/charge/summary" },
    CustomerChargeList: { path: "/customer/charge/list" },
    CustomerRefundApp: { path: "/customer/refund/app" },
    CustomerRefundHistoryApp: { path: "/customer/refund/history-app" },
    CustomerRefundWeb: { path: "/customer/refund/web" },
    CustomerRefundHistoryWeb: { path: "/customer/refund/history-web" },
    CustomerWithdrawRequestApp: { path: "/customer/withdraw/app" },
    CustomerWithdrawRequestAppConfirmed: { path: "/customer/withdraw/app-confirmed" },
    CustomerWithdrawRequestWeb: { path: "/customer/withdraw/web" },
    CustomerWithdrawRequestWebConfirmed: { path: "/customer/withdraw/web-confirmed" },
    CustomerWithdrawSummaryApp: { path: "/customer/withdraw/summary-app" },
    CustomerWithdrawSummaryWeb: { path: "/customer/withdraw/summary-web" },
    CustomerFirstAgent: { path: "/customer/account/first-agent" },
    CustomerSecondAgent: { path: "/customer/account/second-agent" },
    CustomerCollaborators: { path: "/customer/account/collaborator" },
    CustomerUser: { path: "/customer/account/user" },
    CustomerNewUserSummary: { path: "/customer/new-user-summary" },

    // VIETLOTT
    VietlottCart: { path: "/vietlott/search/cart" },
    VietlottTicket: { path: "/vietlott/search/ticket" },
    VietlottKenoResult: { path: "/vietlott/result/keno" },
    VietlottPowerResult: { path: "/vietlott/result/power" },
    VietlottMegaResult: { path: "/vietlott/result/mega" },
    VietlottMax3dResult: { path: "/vietlott/result/max3d" },
    VietlottMax3dProResult: { path: "/vietlott/result/max3d-pro" },
    VietlottWaitingTicket: { path: "/vietlott/statistic/waiting-ticket" },
    VietlottPrintedTicket: { path: "/vietlott/statistic/printed-ticket" },
    VietlottDoneTicket: { path: "/vietlott/statistic/done-ticket" },
    VietlottDeletedTicket: { path: "/vietlott/statistic/deleted-ticket" },
    VietlottTransactionSummary: { path: "/vietlott/statistics/transaction-summary" },
    VietlottTransactionList: { path: "/vietlott/statistics/transaction-list" },
    VietlottCashBack: { path: "/vietlott/statistics/cashback" },
    VietlottReward: { path: "/vietlott/reward/reward" },
    VietlottBigReward: { path: "/vietlott/reward/big-reward" },
    VietlottDayOff: { path: "/vietlott/period/day-off" },
    VietlottKenoPeriod: { path: "/vietlott/period/keno-period" },
    VietlottPowerPeriod: { path: "/vietlott/period/power-period" },
    VietlottMegaPeriod: { path: "/vietlott/period/mega-period" },
    VietlottMax3dPeriod: { path: "/vietlott/period/max3d-period" },
    VietlottMax3dProPeriod: { path: "/vietlott/period/max3d-pro-period" },

    // CLASSIC
    ClassicListReward: { path: "/classic-common/list-reward" },
    ClassicRegion: { path: "/classic-common/region" },
    ClassicMNChannel: { path: "/classic/mn/channel" },
    ClassicMTChannel: { path: "/classic/mt/channel" },
    ClassicMNTicketSales: { path: "/classic/mn/ticket-sales" },
    ClassicMTTicketSales: { path: "/classic/mt/ticket-sales" },
    ClassicMNReward: { path: "/classic/mn/reward" },
    ClassicMTReward: { path: "/classic/mt/reward" },
    ClassicCart: { path: "/classic-track/cart" },
    ClassicTicket: { path: "/classic/ticket" },
    ClassicResult: { path: "/classic/result" },
    ClassicTicketStorage: { path: "/classic/ticket-storage" },
    ClassicStatistic: { path: "/classic/statistic" },
    ClassicStatisticByTicket: { path: "/classic/statistic-by-ticket" },
    ClassicStatisticByUnmarketableTicket: { path: "/classic/statistic-by-unmarketable-ticket" },
    ClassicMNPeriod: { path: "/classic/mn/period" },
    ClassicMTPeriod: { path: "/classic/mt/period" },
    ClassicMNNewCart: { path: "/classic/mn/new-cart" },
    ClassicMTNewCart: { path: "/classic/mt/new-cart" },
    ClassicMNDoneCart: { path: "/classic/mn/done-cart" },
    ClassicMTDoneCart: { path: "/classic/mt/done-cart" },
    ClassicSearch: { path: "/classic/classic/search" },

    // LOTO
    LotoChannel: { path: '/loto/channel' },
    LotoPeriod: { path: "/loto/period" },
    LotoSearch: { path: "/loto/search" },
    LotoDoneCart: { path: "/loto/done-cart" },
    LotoNewCart: { path: "/loto/new-cart" },
    LotoReward: { path: "/loto/reward" },
    LotoStatisticByTicket: { path: "/loto/statistic-by-ticket" },

    // SETTING
    SettingDevices: { path: "/setting/devices" },
    SettingGames: { path: "/setting/games" },
    SettingBanner: { path: "/setting/banner" },
    SettingPaymentMethod: { path: "/setting/payment-method" },
    SettingAccount: { path: "/setting/account" },
    SettingBank: { path: "/setting/bank" },
    SettingNotification: { path: "/setting/notification" },
    SettingPrivacy: { path: "/setting/privacy" },
    SettingGameGuide: { path: "/setting/game-guide" },
    SettingContact: { path: "/setting/contact" },
    SettingInvite: { path: "/setting/invite" },
    SettingSystemNotification: { path: "/setting/system-notification" },
    SettingMaintained: { path: "/setting/maintained" },
    SettingEmployee: { path: "/setting/employee" },


    // ANOTHER
    MBRefund: { path: "/management/mb-refund" },
    MBDoisoat: { path: "/management/mb-doisoat" },
    NotFound: { path: '/not-found' }
};