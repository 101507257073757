import React from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';

export const AddBannerForm = (props) => {

  const onImageChange = (evt) => {
    if (evt.target.files[0]) {
      var file = evt.target.files[0];
      console.log(file);
      var reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = function () {
        console.log(btoa(reader.result));
        props.setImage(btoa(reader.result))
      };
      reader.onerror = function () {
        console.log('there are some problems');
      };
      // props.setImage(evt.target.files[0])
    }
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Hình ảnh</Form.Label>
            <Form.Control required type="file" placeholder="Nhập tên ngân hàng" onChange={onImageChange} />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};