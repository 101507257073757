import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../EwalletAndPaymentGatewaySummary';

export const TableRow = (props) => {
  const { count_, total_, fee_, handleFee_, value_, method, subMethod, from, to } = props;

  const { dispatch } = useContext(Context);

  const linkTo = `/statistics/by-ewallet-and-payment-gateway-detail?method="${method}"&from="${from}"&to="${to}"&subMethod="${subMethod}"`

  const _onExport = useCallback(() => {
    dispatch({
      type: 'EXPORT_DATA',
      method: method,
      subMethod: subMethod,
      exportTotal: count_
    })
  }, [method, dispatch, subMethod])

  return (
    <tr>
      <td className="text-center">
        <Button variant="success" className="m-1" onClick={_onExport}>Xuất excel</Button>
        &nbsp;
        &nbsp;
        <Button>
          <Link
            target={'_blank'}
            style={{ color: '#fff' }}
            to={linkTo}
            key={method}
          >
            {'Xem chi tiết'}
            <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
          </Link>
        </Button>
      </td>
      <td className='text-center'>
        {method} - {subMethod}
      </td>
      <td className='text-center'>
        {count_}
      </td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(value_) || ""}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(fee_) || ""}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(handleFee_) || ""}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(total_) || ""}</td>
    </tr>
  );
};