import React from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';

export const AddBankForm = (props) => {

  const onNameChange = (evt) => {
    props.setName(evt.target.value)
  }

  const onShortNameChange = (evt) => {
    props.setShortName(evt.target.value)
  }

  const onLogoChange = (evt) => {
    const FR = new FileReader();

    FR.addEventListener("load", function (ee) {
      props.setLogo(ee.target.result)
    });

    FR.readAsDataURL(evt.target.files[0]);
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Tên ngân hàng</Form.Label>
            <Form.Control required type="text" placeholder="Nhập tên ngân hàng" onChange={onNameChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Tên rút gọn</Form.Label>
            <Form.Control required type="text" placeholder="Nhập tên rút gọn" onChange={onShortNameChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Logo</Form.Label>
            <input type="file" alt="Submit" className="form-control" onChange={onLogoChange} />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};