
import React, { useEffect } from 'react';

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";

export default () => {

  useEffect(() => {
    document.title = "Kỳ quay Power 6/55"
  }, []);

  return (
    <>
      <FilterForm />
      <br />
      <Table />
    </>
  );
};
