
import React, { useEffect } from 'react';

import Table from "./partials/Table";

export default () => {

  useEffect(() => {
    document.title = "Kết quả Max3D Pro"
  }, []);

  return (
    <Table />
  );
};
