import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../NewUser'
import { getNewUserStatisticSummary } from '../../../../controller/api/statistic/newUserRegister';
import { useCookies } from 'react-cookie';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({
    count: 0
  })

  const getSummaryFunc = useCallback(async () => {
    const response = await getNewUserStatisticSummary(cookies.lottmekong, {
      from: state.from,
      endPointId: state.endPointId
    })

    if (response) {
      const res = {
        count: response.count,
        total: response.total
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.from, state.endPointId])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Col>
            <Row>
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>Số người đăng ký trong ngày</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{data.count}</Form.Label>
                </Row>
              </Col>
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>Tổng số khách hàng</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{data.total}</Form.Label>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)