import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { confirmNumberTicket, getMax3dResults } from '../../../../controller/api/gameResult'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import { calculatePage } from '../../../../helper/helper'
import Pagination from '../../../../components/Pagination'
import CalculateRewardPopup from '../sub-screen/calculate-reward-popup/CalculateRewardPopup'
import ResultInputPopup from '../sub-screen/result-input-popup/ResultInputPopup'
import ResultPopup from '../sub-screen/result-popup/ResultPopup'

export const _Table = () => {

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const getAllFunc = useCallback(async () => {
    const response = await getMax3dResults(cookies.lottmekong, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }
      setData(res || {})
      setLoaded(true)
    }
  }, [cookies.lottmekong, page])

  const onConfirmNumberTicket = async (periodId, numberTicket) => {
    const response = await confirmNumberTicket(cookies.lottmekong, periodId, numberTicket)

    if (response) {
      const _newData = JSON.parse(JSON.stringify(data))

      _newData.list = _newData.list.map(it => {
        if (it.gamePeriod.id === periodId) {
          it.totalTickets = numberTicket
        }
        return {
          ...it
        }
      })
      setData(_newData)
    }
  }

  const [modalCalculateRewardShow, setModalCalculateRewardShow] = useState(false)

  const [modalInputResultShow, setModalInputResultShow] = useState(false)

  const [modalShow, setModalShow] = useState(false)

  const resultRef = useRef(null)

  const gamePeriodRef = useRef(null)

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  const onShowResultPress = async (result, gamePeriod) => {
    resultRef.current = result
    gamePeriodRef.current = gamePeriod
    setModalShow(true)
  }

  const onShowCalculateRewardPopupPress = (gamePeriod) => {
    gamePeriodRef.current = gamePeriod
    setModalCalculateRewardShow(true)
  }

  const onShowInputResultPopupPress = (gamePeriod) => {
    gamePeriodRef.current = gamePeriod
    setModalInputResultShow(true)
  }

  const onHideCalculateRewardPopupPress = (totalRewardTicket, totalUnrewardTicket, callback) => {
    const _newData = JSON.parse(JSON.stringify(data))

    _newData.list = _newData.list.map(it => {
      if (it.gamePeriod.id === gamePeriodRef.current.id) {
        it.totalRewaredTickets += totalRewardTicket
        it.totalUnRewardTickets += totalUnrewardTicket
      }
      return {
        ...it
      }
    })
    setData(_newData)

    gamePeriodRef.current = null

    callback()

    setModalCalculateRewardShow(false)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Kỳ quay số</th>
              <th scope="col">Thống kê vé</th>
              <th scope="col">Kết quả quay số</th>
              <th scope="col">Đã tính thưởng</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map((pv, index) => <TableRow
              onConfirmNumberTicket={onConfirmNumberTicket}
              onInputResultPress={onShowInputResultPopupPress}
              onCalculateRewardPress={onShowCalculateRewardPopupPress}
              onShowResultPress={onShowResultPress}
              rIndex={index}
              key={`result-status-${index}`}
              {...pv}
            />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setLoaded(false)
                  setPage(i)
                }}
              />
            </Col>
            <Col>
              {loaded ? '' : 'Đang tải dữ liệu'}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <ResultPopup
        show={modalShow}
        result={resultRef.current}
        gamePeriod={gamePeriodRef.current}
        onHide={() => setModalShow(false)}
      />
      <ResultInputPopup
        show={modalInputResultShow}
        period={gamePeriodRef.current}
        onHide={() => setModalInputResultShow(false)}
      />
      <CalculateRewardPopup
        show={modalCalculateRewardShow}
        period={gamePeriodRef.current}
        onHide={onHideCalculateRewardPopupPress}
      />
    </>
  );
};

export default React.memo(_Table)