import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'

const PLUGINS_NAME = 'admin-loto-tickets'

export const searchTicket = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/search-list`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}