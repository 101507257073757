import React, { useCallback, useContext, useRef } from 'react'
import { Col, Row, Card, Form, Container, Button } from '@themesberg/react-bootstrap'
import { Context } from '../WithdrawRequest'

const _FilterForm = () => {

  const { state, dispatch } = useContext(Context);

  const _phone = useRef(state.filter.phone);

  const _handleChangePhone = useCallback((payload) => {
    _phone.current = payload.target.value
  }, [])

  const _submitForm = useCallback(() => {
    dispatch({
      type: 'SUBMIT_FILTER',
      payload: {
        phone: _phone.current
      }
    })
  }, [dispatch])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col className='align-self-center'>
              <h5>YÊU CẦU RÚT TIỀN</h5>
            </Col>
            <Col className='text-right'>
              <Button style={{ marginRight: 20 }} variant="info" type="submit" onClick={_submitForm}>Tìm kiếm</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Col>
              <Row className='p-1'>
                <Col>
                  <Row>
                    <Col md={3} className='text-right align-self-center'>
                      <Form.Label>Số điện thoại</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control onChange={_handleChangePhone} required type="text" placeholder="Nhập số điện thoại" />
                    </Col>
                  </Row>
                </Col>
                <Col>
                </Col>
              </Row>
            </Col>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_FilterForm)