import React, { useContext } from 'react'
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap'
import { TableRow } from '../component/TableRow'
import Pagination from '../../../../components/Pagination'
import { Context } from '../Search'

const _Table = () => {

  const { state, dispatch } = useContext(Context);

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Người chơi</th>
              <th scope="col">Mã đơn</th>
              <th scope="col">Kỳ quay</th>
              <th scope="col">Vé</th>
              <th scope="col">Trạng thái</th>
              <th scope="col">Ngày mua</th>
              <th scope="col">Ngày xuất</th>
            </tr>
          </thead>
          <tbody>
            {state.data.list && state.data.list.map((pv, index) => <TableRow rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={state.data.totalPage || 0}
                currentPage={state.filter.page || 0}
                onPageChange={(i) => {
                  dispatch({
                    type: 'UPDATE_PAGE',
                    payload: i
                  })
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default React.memo(_Table)