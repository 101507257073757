import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../WithdrawSummary'
import { getWithdrawStatisticSummary } from '../../../../controller/api/statistic/withdraw';
import { useCookies } from 'react-cookie';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [data, setData] = useState({
    total: '0',
    bank: '0',
    internal: '0',
  })

  const getSummaryFunc = useCallback(async () => {

    let phone = state.filter.phone

    const response = await getWithdrawStatisticSummary(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
      phone: phone,
    }, 'WEB')

    if (response) {
      const res = {
        total: response.total,
        bank: response.bank,
        internal: response.internal,
      }
      setData(res || {})
    }
  }, [cookies.lottmekong, state.filter.phone, state.filter.from, state.filter.to])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm mb-2">
        <Card.Body>
          <Row>
            <Col md={4}>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Tổng</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(data.total) || ""}</Form.Label>
              </Row>
            </Col>
            <Col md={4}>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Ngân hàng</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(data.bank) || ""}</Form.Label>
              </Row>
            </Col>
            <Col md={4}>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Ví ứng dụng</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(data.internal) || ""}</Form.Label>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)