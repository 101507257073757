
import React, { useEffect } from 'react';

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";

export default () => {

  useEffect(() => {
    document.title = "Kỳ quay Keno"
  }, []);

  return (
    <>

      <FilterForm />

      <br />

      <Table />
    </>
  );
};
