
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Form, Button, Alert } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import CurrencyInput from 'react-currency-input-field';
import { getMBTransaction, refundMBTransaction } from "../../../../controller/api/mb";

export const AddRefundForm = () => {

  const [cookies] = useCookies(['lottmekong'])

  const [amount, setAmount] = useState(0)

  const [refundAmount, setRefundAmount] = useState(null)

  const [status, setStatus] = useState('')

  const [refundStatus, setRefundStatus] = useState(null)

  const [reason, setReason] = useState('Thua MB')

  const valueRef = useRef('')
  const idRef = useRef('')

  const reasonRef = useRef('')

  const getTransaction = async (evt) => {
    if (evt.target.value.length === 12) {
      idRef.current = evt.target.value

      const response = await getMBTransaction(cookies.lottmekong, idRef.current)

      if (response) {
        setAmount(response.amount)
        setStatus(response.status)
        setRefundAmount(response.refundedAmount)
        setRefundStatus(response.refundStatus)
      }
    } else if (evt.target.value.length > 10) {
      return false
    }
  }

  const onValueChange = (value) => {
    valueRef.current = value
  }

  const onReasonChange = (evt) => {
    setReason(evt.target.value)
  }

  const onOtherReasonChange = (evt) => {
    reasonRef.current = evt.target.value
  }

  useEffect(() => {
    if (reason !== 'OTHER' && reason !== '') {
      reasonRef.current = reason
    }
  }, [reason])

  const onCommitCharge = async () => {
    if (validate()) {
      debugger
      const resp = await refundMBTransaction(cookies.lottmekong, parseInt(valueRef.current), idRef.current, reasonRef.current)
      if (resp) {
        alert('Thành công')
        window.location.reload()
      } else {
        alert('Có lỗi xảy ra')
        return false
      }
    } else {
      alert('Dữ liệu ko đúng')
      return false
    }
  }

  const validate = () => {
    return true;
    // debugger
    // if (valueRef.current.length > 0 && parseInt(valueRef.current) <= amount && reasonRef.current.length > 0) {
    //   return true
    // } else {
    //   return false
    // }
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Giao dịch MB</h5>
        <Form>
          <Row>
            <Col md={12} className="mb-2">
              <Form.Group id="firstName">
                <Form.Label>Mã giao dịch</Form.Label>
                <Form.Control required type="text" placeholder="Nhập mã giao dịch" onChange={getTransaction} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          </Row>
          {amount > 0 &&
            <Alert variant={status === 'PENDING' ? 'warning' : "success"}>
              Số tiền thanh toán: {new Intl.NumberFormat('vn-VI', {}).format(amount)} <br />
              Trạng thái: {status === 'PENDING' ? 'Đang xử lý' : 'Đã thanh toán'} <br />
              {refundAmount != null ? <>Số tiền hoàn: ${new Intl.NumberFormat('vn-VI', {}).format(refundAmount || 0)}<br /></> : ''} 
              {refundStatus != null ? `Trạng thái: ${refundStatus}` : ''}
            </Alert>
          }
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Số tiền</Form.Label>
                <CurrencyInput
                  className="form-control"
                  id="input-example"
                  name="input-name"
                  placeholder="Nhập số tiền"
                  min={0}
                  decimalsLimit={3}
                  onValueChange={(value) => onValueChange(value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Nội dung hoàn tiền</Form.Label>
                <Form.Select id="account-id" defaultValue={0} onChange={onReasonChange}>
                  <option value={'Thua MB'}>Thừa MB</option>
                  <option value={'OTHER'}>Lý do khác</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {reason === 'OTHER' &&
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group id="addressNumber">
                  <Form.Label>Lý do khác</Form.Label>
                  <Form.Control required type="text" placeholder="Nhập lý do" onChange={onOtherReasonChange} />
                </Form.Group>
              </Col>
            </Row>
          }
          <div className="mt-3">
            <Button variant="primary" type="button" onClick={onCommitCharge}>Hoàn tiền</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};