
import React, { useEffect, useState } from "react";
import { Col, Row, Form } from '@themesberg/react-bootstrap';
import { getAllBanks } from "../../../../../../controller/api/bank";
import { useCookies } from "react-cookie";

export const AddAccountForm = (props) => {

  const [listBank, setListBank] = useState([])

  const [cookies] = useCookies(['lottmekong'])

  useEffect(() => {
    const getParamsFunc = async () => {
      const response = await getAllBanks(cookies.lottmekong, 0)

      if (response && response.data && response.data.length > 0) {
        const res = {
          data: response.data,
          total: response.total
        }
        props.setBank(res.data[0].id)
        
        setListBank(res.data || [])
      }
    }
    getParamsFunc()
  }, [props, cookies.lottmekong])

  const onNameChange = (evt) => {
    props.setName(evt.target.value)
  }
  
  const onAccountNameChange = (evt) => {
    props.setAccountName(evt.target.value)
  }

  const onAccountNumberChange = (evt) => {
    props.setAccountNumber(evt.target.value)
  }

  const onBankSelect = (evt) => {
    props.setBank(evt.target.value)
  }

  return (
    <Form>
      <Row>
        <Col md={12} className="mb-2">
          <Form.Group id="firstName">
            <Form.Label>Tên tài khoản</Form.Label>
            <Form.Control required type="text" placeholder="Nhập tên tài khoản" onChange={onNameChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mb-3">
          <Form.Group className="mb-2">
            <Form.Label>Chọn ngân hàng</Form.Label>
            <Form.Select id="bank-id" defaultValue={0} onChange={onBankSelect}>
              {listBank.map(item => <option key={item.id} value={item.id}>{item.shortName + ' - ' + item.viName}</option>)}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mb-3">
          <Form.Group id="addressNumber">
            <Form.Label>Chủ tài khoản</Form.Label>
            <Form.Control required type="text" placeholder="Nhập tên chủ tài khoản" onChange={onAccountNameChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mb-3">
          <Form.Group id="addressNumber">
            <Form.Label>Số tài khoản</Form.Label>
            <Form.Control required type="number" placeholder="Nhập số tài khoản" onChange={onAccountNumberChange} />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};