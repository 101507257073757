import { Button } from '@themesberg/react-bootstrap';
import React from 'react'

export const AddTicketTableActionRow = (props) => {
  const { onAddSingle } = props
  return (
    <tr>
      <td colSpan={5} className="text-center">
        <Button variant='info' onClick={onAddSingle}>
          Thêm vé đơn
        </Button>
      </td>
    </tr>
  );
};