import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../Statistic'
import { getStatisticSummary } from '../../../../controller/api/__classic__/statistic';
import { useCookies } from 'react-cookie';

const _Summary = () => {

  const { state } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [summary, setSummary] = useState({
    total: 0,
    data: []
  })

  const getSummaryFunc = useCallback(async () => {

    let phone = state.filter.phone

    if (phone.indexOf('MB') < 0) {
      phone = phone.replace('0', '+84')
    }

    const response = await getStatisticSummary(cookies.lottmekong, {
      from: state.filter.from,
      to: state.filter.to,
      phone: phone,
      channelIds: state.filter.channelIds.toUpperCase()
    })

    if (response) {
      const res = {
        total: response.total,
        data: response.data || []
      }
      setSummary(res || {})
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.phone, state.filter.channelIds])

  const listSumary = useMemo(() => {
    if (summary.data.length > 0) {
      return (
        <>
          {summary.data.map(it => {
            return (
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{it.channelName}</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{it.amount}</Form.Label>
                </Row>
              </Col>
            )
          })}
        </>
      )
    }
    return <></>
  }, [summary])

  useEffect(() => {
    getSummaryFunc()
  }, [getSummaryFunc, state.filterPressCount])

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Row>
            <Col>
              <Row md={12} className='text-center align-self-center'>
                <Form.Label>Tổng số vé</Form.Label>
              </Row>
              <Row md={12} className='text-center align-self-center text-danger'>
                <Form.Label>{summary.total}</Form.Label>
              </Row>
            </Col>
            {listSumary}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)