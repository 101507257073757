import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { name, AppVersion, identify, phone, onShowUserAmount, createdAt, amount, lastBuyTicketAt, endPointId, assignPin, id } = props;

  return (
    <tr className='text-center'>
      <td>
        <Button variant={amount === null ? "danger" : "info"} className="m-1" onClick={() => onShowUserAmount(id)}>Xem tài khoản</Button>
        <Button style={{ marginRight: 20 }} variant="success" type="submit" onClick={() => assignPin(id)}>Cấp mã pin</Button>
      </td>
      <td>
        <span>{name || ""}</span><br />
        <span className={`fw-900 text-info`}>{identify || ""}</span>
      </td>
      <td>{phone}</td>
      <td>
        {moment(lastBuyTicketAt || '').format('DD/MM/YYYY HH:mm:ss')}
      </td>
      <td>
        {moment(createdAt || '').format('DD/MM/YYYY HH:mm:ss')}
      </td>
      <td>{endPointId}</td>
      {endPointId === 'APP' &&
        <td>{AppVersion ? AppVersion.platform : ''}</td>
      }
      {endPointId === 'APP' &&
        <td>{AppVersion ? AppVersion.code : ''}</td>
      }
      {endPointId === 'WEB' &&
        <td colSpan={2}></td>
      }
    </tr>
  );
};