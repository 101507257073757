import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { value, handleFee, fee, total, user, createdAt, methodId, thirdPartyTransId, referenceNumber, gameId } = props;

  return (
    <tr>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td>
        {methodId}
      </td>
      <td>
        {gameId}
      </td>
      <td className="fw-900 text-success text-right">{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td className="fw-900 text-success text-right">{new Intl.NumberFormat('vn-VI', {}).format(fee) || ""}</td>
      <td className="fw-900 text-success text-right">{new Intl.NumberFormat('vn-VI', {}).format(handleFee) || ""}</td>
      <td className="fw-900 text-success text-right">{new Intl.NumberFormat('vn-VI', {}).format(total) || ""}</td>
      <td>{thirdPartyTransId || referenceNumber || ''}</td>
      <td className="text-center">{moment(createdAt || '').format('DD/MM/YYYY HH:mm:ss')}</td>
    </tr>
  );
};
