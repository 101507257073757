import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Col, Row, Card, Table, Form, Button } from '@themesberg/react-bootstrap'
import { getAllBanks, update, deleteBank } from '../../../../controller/api/bank';
import AddBank from '../sub-screen/add-bank/AddBank';
import { useCookies } from 'react-cookie';
import { calculatePage } from '../../../../helper/helper';
import Pagination from '../../../../components/Pagination';

const TableRow = (props) => {
  const { name, shortName, status, token, id, logo } = props

  const [isModified, setIsModified] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [isDeleted, setIsDeleted] = useState(false)

  const _newName = useRef(name)

  const _newShortName = useRef(shortName)

  const _newLogo = useRef(logo)

  const _newStatus = useRef(status)

  const handleChange = (e) => {
    const FR = new FileReader();

    FR.addEventListener("load", function (ee) {
      _newLogo.current = ee.target.result
    });

    FR.readAsDataURL(e.target.files[0]);

  }

  const _onConfirm = useCallback(async () => {
    if (name === _newName.current && shortName === _newShortName.current && status === _newStatus.current && logo === _newLogo.current) {
      setIsModified(false)
    } else {
      setIsLoading(true)
      const resp = await update(token, id, _newName.current, _newShortName.current, _newStatus.current, _newLogo.current)

      if (resp) {
        setIsLoading(false)
        setIsModified(false)
      }
    }
  }, [id, logo, name, shortName, status, token])

  const _onDelete = useCallback(async () => {
    const confirmed = window.confirm(`Bạn muốn xóa ngân hàng ${name}`)

    if (confirmed) {
      setIsLoading(true)
      const resp = await deleteBank(token, id)

      if (resp) {
        setIsDeleted(true)
      }
    }
  }, [id, name, token])

  const normalView = useMemo(() => {
    if (!isModified) {
      return (
        <tr>
          {
            isDeleted ?
              <td className='text-danger' style={{ width: 250 }}>
                Đã xóa
              </td> :
              isLoading ?
                <td className='text-danger' style={{ width: 250 }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Đang xử lý</span>
                  </div>
                </td> :
                <td style={{ width: 250 }}>
                  <Button variant="info" className="m-1" onClick={() => { setIsModified(true) }}>Sửa</Button>
                  <Button variant="danger" className="m-1" onClick={_onDelete}>Xóa</Button>
                </td>
          }
          <td>
            {_newName.current || ""}
          </td>
          <td>
            {_newShortName.current || ""}
          </td>
          <td>
            {_newLogo.current &&
              <img alt='' src={_newLogo.current} className="img-thumbnail" width={60} height={30}></img>
            }
          </td>
          <td className={_newStatus.current === 0 ? 'text-danger' : 'text-success'} style={{ width: 250 }}>
            {_newStatus.current === 0 ? 'Không hoạt động' : 'Hoạt động'}
          </td>
        </tr>
      )
    }
    return null;
  }, [isModified, isDeleted, isLoading, _onDelete])

  const modifyView = useMemo(() => {
    if (isModified) {
      return (
        <tr>
          {isLoading ?
            <td style={{ width: 250 }}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Đang xử lý</span>
              </div>
            </td>
            :
            <td style={{ width: 250 }}>
              <Button variant="info" className="m-1" onClick={() => { setIsModified(false) }}>Hủy</Button>
              <Button variant="success" className="m-1" onClick={_onConfirm}>Xác nhận</Button>
            </td>
          }
          <td>
            <Form.Control defaultValue={name} type="text" placeholder="Nhập tên ngân hàng" onChange={(e) => { _newName.current = e.target.value }} />
          </td>
          <td>
            <Form.Control defaultValue={shortName || ""} type="text" placeholder="Nhập mã ngân hàng" onChange={(e) => { _newShortName.current = e.target.value }} />
          </td>
          <td>
            <input type="file" alt="Submit" width="48" height="48" onChange={handleChange} />
          </td>
          <td style={{ width: 250 }}>
            <Form.Select defaultValue={status} onChange={(evt) => { _newStatus.current = parseInt(evt.target.value) }} >
              <option value={0}>Không hoạt động</option>
              <option value={1}>Hoạt động</option>
            </Form.Select>
          </td>
        </tr>
      )
    }
    return null;
  }, [isModified, isLoading, _onConfirm, name, shortName, status])

  return (
    <>
      {normalView}
      {modifyView}
    </>
  );
};

export const BankTable = () => {

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const [modalShow, setModalShow] = useState(false)

  const getAllFunc = useCallback(async () => {
    const response = await getAllBanks(cookies.lottmekong, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }

      setData(res || {})
      setLoaded(true)
    }
  }, [cookies.lottmekong, page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  const onAddBankPress = async () => {
    setModalShow(true)
  }

  return (
    <>
      <Button className='mb-3' style={{ marginRight: 20 }} variant="success" type="button" onClick={onAddBankPress}>Thêm</Button>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Ngân hàng ({data.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Tên ngân hàng</th>
              <th scope="col">Tên rút gọn</th>
              <th scope="col">Logo</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map(pv => <TableRow token={cookies.lottmekong} key={`bank-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setLoaded(false)
                  setPage(i)
                }}
              />
            </Col>
            <Col>
              {loaded ? '' : 'Đang tải dữ liệu'}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <AddBank
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};