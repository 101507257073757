import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Col, Row, Card, Table, Form, Button } from '@themesberg/react-bootstrap'
import { getAll, deleteBanner } from '../../../../controller/api/banner';
import AddBank from '../sub-screen/add-banner/AddBanner';
import { useCookies } from 'react-cookie';
import { calculatePage } from '../../../../helper/helper';
import Pagination from '../../../../components/Pagination';

const TableRow = (props) => {
  const { images, status, token, id } = props

  const [isLoading, setIsLoading] = useState(false)

  const [isDeleted, setIsDeleted] = useState(false)

  const _newImage = useRef(images)

  const _newStatus = useRef(status)

  const _onDelete = useCallback(async () => {
    const confirmed = window.confirm(`Bạn muốn xóa quảng cáo`)

    if (confirmed) {
      setIsLoading(true)
      const resp = await deleteBanner(token, id)

      if (resp) {
        setIsDeleted(true)
      }
    }
  }, [id, token])

  const normalView = useMemo(() => {
    return (
      <tr>
        {
          isDeleted ?
            <td className='text-danger' style={{ width: 250 }}>
              Đã xóa
            </td> :
            isLoading ?
              <td className='text-danger' style={{ width: 250 }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Đang xử lý</span>
                </div>
              </td> :
              <td style={{ width: 250 }}>
                <Button variant="danger" className="m-1" onClick={_onDelete}>Xóa</Button>
              </td>
        }
        <td>
          <img src={_newImage.current} height="100" style={{ objectFit: 'contain' }} alt="ss" />
        </td>
        <td className={_newStatus.current === 0 ? 'text-danger' : 'text-success'} style={{ width: 250 }}>
          {_newStatus.current === 0 ? 'Không hoạt động' : 'Hoạt động'}
        </td>
      </tr>
    )
  }, [isDeleted, isLoading, _onDelete])

  return (
    <>
      {normalView}
    </>
  );
};

export const BannerTable = () => {

  const [data, setData] = useState({})

  const [page, setPage] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const [cookies] = useCookies(['lottmekong'])

  const [modalShow, setModalShow] = useState(false)

  const getAllFunc = useCallback(async () => {
    const response = await getAll(cookies.lottmekong, page)

    if (response) {
      const res = {
        list: response.data,
        total: response.total,
        totalPage: calculatePage(response.total)
      }

      setData(res || {})
      setLoaded(true)
    }
  }, [cookies.lottmekong, page])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc])

  const onAddPress = async () => {
    setModalShow(true)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Quảng cáo ({data.total})</h5>
            </Col>
          </Row>
        </Card.Header>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Hình ảnh</th>
              <th scope="col">Trạng thái</th>
            </tr>
          </thead>
          <thead className="thead-light">
            <tr>
              <th scope="col">
                <Button variant="primary" className="m-1" onClick={onAddPress}>Thêm</Button>
              </th>
              <th scope="col">
              </th>
              <th scope="col">
                <Form.Select>
                  <option defaultValue value={-2}>Chọn trạng thái</option>
                  <option value={0}>Không hoạt động</option>
                  <option value={1}>Hoạt động</option>
                </Form.Select>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.list && data.list.map(pv => <TableRow token={cookies.lottmekong} key={`banner-status-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={data.totalPage}
                currentPage={page}
                onPageChange={(i) => {
                  setLoaded(false)
                  setPage(i)
                }}
              />
            </Col>
            <Col>
              {loaded ? '' : 'Đang tải dữ liệu'}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <AddBank
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};