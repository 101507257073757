import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Button, Modal, ProgressBar } from '@themesberg/react-bootstrap'
import { useCookies } from 'react-cookie'
import { Context } from '../../ByPrintedTicket'
import { getPrintedTicketStatisticCsv } from "../../../../../controller/api/statistic/printTicket"
import { downloadCsv, ITEM_PER_PAGE } from "../../../../../helper/helper"

export default (props) => {

  const { state } = useContext(Context);

  const [current, setCurrent] = useState(0)
  const data = useRef('')

  const [cookies] = useCookies(['lottmekong'])

  const _callExportApi = useCallback(async () => {
    if (props.total > 0) {
      let phone = state.filter.phone

      const response = await getPrintedTicketStatisticCsv(cookies.lottmekong, {
        from: state.filter.from,
        to: state.filter.to,
        phone: phone,
        gameId: state.filter.gameId
      }, current, props.total)

      if (response) {
        if (response.count > 0) {
          setCurrent(current + 1)
          data.current = response.response
        } else {
          data.current = data.current + '\n' + response.response
          setCurrent(current + 1)
        }
      }
    }
  }, [cookies.lottmekong, state.filter.from, state.filter.to, state.filter.phone, state.filter.gameId, data, current, props.total])

  const _onHide = useCallback(async () => {
    if (current * ITEM_PER_PAGE >= props.total) {
      setCurrent(0)
      data.current = ''
      props.onHide()
    } else {
      alert('Chưa xuất file xong')
    }
  }, [current, props])

  const exportFunc = useCallback(async () => {
    console.warn(current, props.total, data.current, props.show)
    if (props.show) {
      if (current * ITEM_PER_PAGE < props.total) {
        await _callExportApi()
      } else {
        downloadCsv(data.current, _onHide)
      }
    }
  }, [current, props.total, props.show, _callExportApi, _onHide])

  useEffect(() => {
    exportFunc()
  }, [exportFunc])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Đang xuất file
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {current === 0 && props.total === 0 ? 'Đang tính số trang' :
          <>
            <ProgressBar className={`progress-md`} variant={'success'} now={current * ITEM_PER_PAGE} min={0} max={props.total} />
            {current * ITEM_PER_PAGE <= props.total ? current * ITEM_PER_PAGE : props.total} / <b>{props.total}</b><br />
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
