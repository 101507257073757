import React, { useCallback, useEffect, useReducer } from "react";

import Table from "./partials/Table";
import FilterForm from "./partials/FilterForm";
import Summary from "./partials/Summary";
import { useCookies } from "react-cookie";
import { getStatistic } from "../../../controller/api/__classic__/statistic-by-unmarketable-ticket";
import { calculatePage, parseQuery } from "../../../helper/helper";
import { useLocation } from "react-router-dom";

export const Context = React.createContext(null);

const initialData = {
  filter: {
    phone: '',
    page: 0
  },
  data: {
    list: [],
    total: 0,
    totalPage: 0
  },
  filterPressCount: 0,
  exportPressCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTER':
      return {
        ...state,
        filter: {
          ...action.payload,
          page: 0
        },
        filterPressCount: state.filterPressCount + 1
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...action.payload
        }
      }
    case 'EXPORT_DATA':
      return {
        ...state,
        exportPressCount: state.exportPressCount + 1
      }
    case 'UPDATE_PAGE':
      return {
        ...state,
        filter: {
          ...state.filter,
          page: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default () => {

  var [state, dispatch] = useReducer(reducer, initialData);

  const [cookies] = useCookies(['lottmekong'])

  const location = useLocation();

  const getAllFunc = useCallback(async () => {

    const queryObj = parseQuery(location.search);

    if (queryObj.resultId) {

      let phone = state.filter.phone

      if (phone.indexOf('MB') < 0) {
        phone = phone.replace('0', '+84')
      }

      const response = await getStatistic(cookies.lottmekong, {
        phone: phone,
        resultId: queryObj.resultId
      }, state.filter.page)

      if (response) {
        dispatch({
          type: 'UPDATE_DATA',
          payload: {
            list: response.data,
            total: response.total,
            totalPage: calculatePage(response.total)
          }
        })
      }
    }
  }, [cookies.lottmekong, location.search, state.filter.page, state.filter.phone])

  useEffect(() => {
    getAllFunc()
  }, [getAllFunc, state.filterPressCount, state.filter.page])

  useEffect(() => {
    document.title = "Doanh thu"
  }, []);

  return (
    <Context.Provider value={{
      state,
      dispatch
    }}>

      <FilterForm />

      <br />

      <Summary />

      <br />

      <Table />
    </Context.Provider>
  );
};
