import React, { useMemo } from "react";
import { Button, Modal, Table } from '@themesberg/react-bootstrap';

const TableRow = (props) => {
  const { value, numbers, rIndex } = props;

  return (
    <tr>
      <td>
        {rIndex + 1}
      </td>
      <td>{numbers}</td>
      <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
    </tr>
  );
};


export default (props) => {

  const numberRows = useMemo(() => {
    if (props.numberRows.length > 0) {
      const listPrizes = props.numberRows

      return listPrizes
    }
    return []
  }, [props.numberRows])

  if (numberRows.length === 0) {
    return null;
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'normal', fontSize: 15 }} id="contained-modal-title-vcenter">
          {props.ticketId && 'Mã vé: ' + props.ticketId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive className="align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Dãy số</th>
              <th scope="col">Giá trị</th>
            </tr>
          </thead>
          <tbody>
            {numberRows && numberRows.map((pv, index) => <TableRow rIndex={index} key={`rows-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>  
        <Button variant="primary" onClick={props.onDeleteTicket}>Hủy vé hoàn tiền</Button>
      </Modal.Footer>
    </Modal>
  );
}
