import React, { useRef, useState } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import { AddBankForm } from "./partials/AddBankForm";
import { useCookies } from "react-cookie";
import { add } from "../../../../../controller/api/bank";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [error, setError] = useState(false)

  const shortNameRef = useRef('')
  
  const logoRef = useRef('')

  const nameRef = useRef(0)

  const onNameChange = (text) => {
    nameRef.current = text
  }

  const onShortNameChange = (text) => {
    shortNameRef.current = text
  }
  
  const onLogoChange = (text) => {
    logoRef.current = text
  }

  const validate = () => {
    return true
  }

  const onSubmit = async () => {
    try {
      if (validate()) {
        const resp = await add(cookies.lottmekong, nameRef.current, shortNameRef.current, 1, logoRef.current)
        if (resp) {
          window.location.reload()
        } else {
          alert('Có lỗi xảy ra')
          return false
        }
      } else {
        setError(true)
      }
    } catch (e) {
      alert('error')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm ngân hàng
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <AddBankForm
          setShortName={onShortNameChange}
          setName={onNameChange}
          setLogo={onLogoChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm ngân hàng</Button>
      </Modal.Footer>
    </Modal>
  );
}
