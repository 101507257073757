import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { addMax3dProResults } from "../../../../../controller/api/gameResult";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [cellSelected, setCellSelected] = useState(0)
  const [cellValue0, setCellValue0] = useState('')
  const [cellValue1, setCellValue1] = useState('')
  const [cellValue2, setCellValue2] = useState('')
  const [cellValue3, setCellValue3] = useState('')
  const [cellValue4, setCellValue4] = useState('')
  const [cellValue5, setCellValue5] = useState('')
  const [cellValue6, setCellValue6] = useState('')
  const [cellValue7, setCellValue7] = useState('')
  const [cellValue8, setCellValue8] = useState('')
  const [cellValue9, setCellValue9] = useState('')
  const [cellValue10, setCellValue10] = useState('')
  const [cellValue11, setCellValue11] = useState('')
  const [cellValue12, setCellValue12] = useState('')
  const [cellValue13, setCellValue13] = useState('')
  const [cellValue14, setCellValue14] = useState('')
  const [cellValue15, setCellValue15] = useState('')
  const [cellValue16, setCellValue16] = useState('')
  const [cellValue17, setCellValue17] = useState('')
  const [cellValue18, setCellValue18] = useState('')
  const [cellValue19, setCellValue19] = useState('')

  const _onHide = () => {

    props.onHide()

  }

  const _getVal = (index) => {
    switch (index) {
      case 0:
        return cellValue0
      case 1:
        return cellValue1
      case 2:
        return cellValue2
      case 3:
        return cellValue3
      case 4:
        return cellValue4
      case 5:
        return cellValue5
      case 6:
        return cellValue6
      case 7:
        return cellValue7
      case 8:
        return cellValue8
      case 9:
        return cellValue9
      case 10:
        return cellValue10
      case 11:
        return cellValue11
      case 12:
        return cellValue12
      case 13:
        return cellValue13
      case 14:
        return cellValue14
      case 15:
        return cellValue15
      case 16:
        return cellValue16
      case 17:
        return cellValue17
      case 18:
        return cellValue18
      case 19:
        return cellValue19
      default:
        return ''
    }
  }

  const _setVal = (index, val) => {
    switch (index) {
      case 0:
        setCellValue0(val)
        return;
      case 1:
        setCellValue1(val)
        return;
      case 2:
        setCellValue2(val)
        return;
      case 3:
        setCellValue3(val)
        return;
      case 4:
        setCellValue4(val)
        return;
      case 5:
        setCellValue5(val)
        return;
      case 6:
        setCellValue6(val)
        return;
      case 7:
        setCellValue7(val)
        return;
      case 8:
        setCellValue8(val)
        return;
      case 9:
        setCellValue9(val)
        return;
      case 10:
        setCellValue10(val)
        return;
      case 11:
        setCellValue11(val)
        return;
      case 12:
        setCellValue12(val)
        return;
      case 13:
        setCellValue13(val)
        return;
      case 14:
        setCellValue14(val)
        return;
      case 15:
        setCellValue15(val)
        return;
      case 16:
        setCellValue16(val)
        return
      case 17:
        setCellValue17(val)
        return;
      case 18:
        setCellValue18(val)
        return;
      case 19:
        setCellValue19(val)
        return;
      default:
        return;
    }
  }

  const _onCellClick = (index) => {
    setCellSelected(index)
  }

  const _generateRows = (cols, skip) => {
    let res = []
    let x = 0
    while (x < cols) {
      const number = x + skip
      const label = `${number}`

      res.push(<td key={label}><div onClick={() => _onItemPress(label)} style={{ ...styles.items, backgroundColor: 'red' }}>{label}</div></td>)
      x++
    }
    return res
  }

  const _onItemPress = (number) => {
    if (cellSelected >= 0) {
      const val = _getVal(cellSelected)
      if (val.length === 3) {
        _setVal(cellSelected, `${number}`)
      } else {
        const newVal = `${val}${number}`
        _setVal(cellSelected, newVal)
        if (newVal.length === 3) {
          setCellSelected(cellSelected === 19 ? -1 : cellSelected + 1)
        }
      }
    } else {
      alert('Xong')
    }
  }

  const onSubmit = async () => {
    const resp = await addMax3dProResults(cookies.lottmekong, props.period.id, {
      g1: [cellValue0, cellValue1],
      g2: [cellValue2, cellValue3, cellValue4, cellValue5],
      g3: [cellValue6, cellValue7, cellValue8, cellValue9, cellValue10, cellValue11],
      g4: [cellValue12, cellValue13, cellValue14, cellValue15, cellValue16, cellValue17, cellValue18, cellValue19],
    })
    if (resp) {
      window.location.reload()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nhập kết quả kỳ #{props.period && props.period.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Table borderless>
              <tbody>
                <tr>
                  {_generateRows(10, 0)}
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table bordered style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <thead style={{ backgroundColor: 'gray', color: 'white' }}>
                <tr>
                  <th>Giải</th>
                  <th colSpan={12}>Bộ số</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nhất</td>
                  <td onClick={() => _onCellClick(0)} colSpan={6} style={{ border: cellSelected === 0 ? '2px solid blue' : null }}>{cellValue0 || '---'}</td>
                  <td onClick={() => _onCellClick(1)} colSpan={6} style={{ border: cellSelected === 1 ? '2px solid blue' : null }}>{cellValue1 || '---'}</td>
                </tr>
                <tr>
                  <td>Nhì</td>
                  <td onClick={() => _onCellClick(2)} colSpan={3} style={{ border: cellSelected === 2 ? '2px solid blue' : null }}>{cellValue2 || '---'}</td>
                  <td onClick={() => _onCellClick(3)} colSpan={3} style={{ border: cellSelected === 3 ? '2px solid blue' : null }}>{cellValue3 || '---'}</td>
                  <td onClick={() => _onCellClick(4)} colSpan={3} style={{ border: cellSelected === 4 ? '2px solid blue' : null }}>{cellValue4 || '---'}</td>
                  <td onClick={() => _onCellClick(5)} colSpan={3} style={{ border: cellSelected === 5 ? '2px solid blue' : null }}>{cellValue5 || '---'}</td>
                </tr>
                <tr>
                  <td rowSpan={3}>Ba</td>
                  <td onClick={() => _onCellClick(6)} colSpan={6} style={{ border: cellSelected === 6 ? '2px solid blue' : null }}>{cellValue6 || '---'}</td>
                  <td onClick={() => _onCellClick(7)} colSpan={6} style={{ border: cellSelected === 7 ? '2px solid blue' : null }}>{cellValue7 || '---'}</td>
                </tr>
                <tr>
                  <td onClick={() => _onCellClick(8)} colSpan={6} style={{ border: cellSelected === 8 ? '2px solid blue' : null }}>{cellValue8 || '---'}</td>
                  <td onClick={() => _onCellClick(9)} colSpan={6} style={{ border: cellSelected === 9 ? '2px solid blue' : null }}>{cellValue9 || '---'}</td>
                </tr>
                <tr>
                  <td onClick={() => _onCellClick(10)} colSpan={6} style={{ border: cellSelected === 10 ? '2px solid blue' : null }}>{cellValue10 || '---'}</td>
                  <td onClick={() => _onCellClick(11)} colSpan={6} style={{ border: cellSelected === 11 ? '2px solid blue' : null }}>{cellValue11 || '---'}</td>
                </tr>
                <tr>
                  <td rowSpan={2}>Tư</td>
                  <td onClick={() => _onCellClick(12)} colSpan={3} style={{ border: cellSelected === 12 ? '2px solid blue' : null }}>{cellValue12 || '---'}</td>
                  <td onClick={() => _onCellClick(13)} colSpan={3} style={{ border: cellSelected === 13 ? '2px solid blue' : null }}>{cellValue13 || '---'}</td>
                  <td onClick={() => _onCellClick(14)} colSpan={3} style={{ border: cellSelected === 14 ? '2px solid blue' : null }}>{cellValue14 || '---'}</td>
                  <td onClick={() => _onCellClick(15)} colSpan={3} style={{ border: cellSelected === 15 ? '2px solid blue' : null }}>{cellValue15 || '---'}</td>
                </tr>
                <tr>
                  <td onClick={() => _onCellClick(16)} colSpan={3} style={{ border: cellSelected === 16 ? '2px solid blue' : null }}>{cellValue16 || '---'}</td>
                  <td onClick={() => _onCellClick(17)} colSpan={3} style={{ border: cellSelected === 17 ? '2px solid blue' : null }}>{cellValue17 || '---'}</td>
                  <td onClick={() => _onCellClick(18)} colSpan={3} style={{ border: cellSelected === 18 ? '2px solid blue' : null }}>{cellValue18 || '---'}</td>
                  <td onClick={() => _onCellClick(19)} colSpan={3} style={{ border: cellSelected === 19 ? '2px solid blue' : null }}>{cellValue19 || '---'}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = {
  items: {
    width: 50,
    height: 50,
    fontWeight: 'bolder',
    fontSize: 18,
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: 'gray',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 100
  }
};
