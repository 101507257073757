import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import { getLink, updateLink } from '../../../../controller/api/vlurl';
import { useCookies } from 'react-cookie';

export const LinkWeb = () => {

  const [cookies] = useCookies(['lottmekong']);

  const [loaded, setLoaded] = useState(true);

  const [updating, setUpdating] = useState(false);

  const [url, setUrl] = useState('');

  const _url = useRef('')

  const getAllFunc = useCallback(async () => {
    setLoaded(false)

    const resp = await getLink(cookies.lottmekong)

    setUrl(resp)

    setLoaded(true)

  }, [cookies.lottmekong])

  useEffect(() => {
    getAllFunc()
  }, [])

  const onUpdate = () => {
    _url.current = url
    setUpdating(true)
  }

  const onCancel = () => {
    _url.current = url
    setUpdating(false)
  }

  const onConfirm = async () => {
    setLoaded(false)

    const resp = await updateLink(cookies.lottmekong, _url.current)

    setLoaded(true)

    if (resp) {
      setUrl(_url.current)
    } else {
      setUrl('Lỗi')
    }

    setUpdating(false)
  }

  const onChangeUrl = (evt) => {
    _url.current = evt.target.value
  }

  return (
    <Table responsive className="align-items-center table-bordered table-hover">
      <thead className='text-center'>
        <tr>
          <th scope="col" style={{ width: '50%' }}>LINK WEB</th>
          <th scope="col">THAO TÁC</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center">
            {!updating ?
              <b>{loaded ? url : 'Đang cập nhật'}</b>
              :
              <Form.Control required type="text" defaultValue={_url.current} onChange={onChangeUrl} />
            }
          </td>
          <td className="text-center">
            {!updating ?
              <Button variant="danger" size="lg" onClick={() => onUpdate()}>Sửa</Button>
              :
              <>
                <Button variant="warning" size="lg" onClick={() => onCancel()}>Hủy</Button>
                &nbsp;
                &nbsp;
                &nbsp;
                <Button variant="success" size="lg" onClick={() => onConfirm()}>Xác nhận</Button>
              </>
            }
          </td>
        </tr>
      </tbody>
    </Table>
  )
};