import axios from 'axios'
import { host } from '../../../helper/config'
import { ITEM_PER_PAGE } from '../../../helper/helper'

const PLUGINS_NAME = 'admin-classic-carts'

export const getListClassicCart = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-list-cart`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const getSummaryClassicCart = async (token, query, page) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-summary-cart`, {
        ...query,
        page: page,
        itemPerPage: ITEM_PER_PAGE
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}

export const reverseClassicCartStatus = async (token, cartId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/reverse-cart-status`, {
        cartId: cartId
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return null
}
