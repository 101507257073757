import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-games'

export const getAllGames = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-all`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const updateStatus = async (token, id, newStatus) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-status`, {
        id: id,
        status: newStatus
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const updateOrderNumber = async (token, id, newOrderNumber) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-order-number`, {
        id: id,
        orderNumber: newOrderNumber
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const updateGuide = async (token, id, guideId, content, gameId) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-guide`, {
        id: id,
        guideId: guideId || 0,
        content: content,
        gameId: gameId,
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}