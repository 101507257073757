import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { ClassicResult, user, ClassicLotteryStorage, ClassicCart, ClassicCartId, amount, status, createdAt, confirmAt, ClassicLotteryChannel } = props;


  const statusText = status === 1 ? 'Đã thanh toán' : status === 2 ? 'Đang chụp' : status === 3 ? 'Đã hoàn thành' : status === 4 ? 'Trúng thưởng' : status === 5 ? 'Không trúng' : 'Hủy'

  return (
    <tr>
      <td>
        {user.name || ""}<br />
        <span><strong className={`text-primary`}>{user.phone || ""}</strong></span>
      </td>
      <td className='text-center'>{ClassicCartId}</td>
      <td className='text-center'>
        <strong>{ClassicLotteryStorage.numbers.split('').join(' ')}</strong><br />
        {ClassicLotteryChannel.name}<br />
        {ClassicResult.resultDate}<br />
      </td>
      <td className='text-center'>{amount}</td>
      <td className={`text-center${status === 4 ? ' text-danger' : ''}`}>{statusText}</td>
      <td className='text-center'>{moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
      <td className='text-center'>{ClassicCart.confirmAt ? moment(ClassicCart.confirmAt || '').format('DD/MM/YYYY HH:mm').toLocaleString() : ''}</td>
    </tr>
  );
};