import { getWithdrawStatistic } from "../../../../controller/api/statistic/withdraw"

export const getAllFunc = async (cookies, method, from, to, phone, page, successCallback) => {
    const response = await getWithdrawStatistic(cookies.lottmekong, {
        from: from,
        to: to,
        phone: phone,
        method: method
    }, page, 'APP')

    if (response) {
        successCallback(response)
    }
}