import React from "react";
import { Table } from '@themesberg/react-bootstrap';

const TableRow = (props) => {
  const { numbers, rIndex, playMethod, rewardAmount, rewards, matchedNumbers } = props;

  if (rewards && rewards.length > 0) {
    return (
      <tr>
        <td>
          {['A', 'B', 'C', 'D', 'E', 'F'][rIndex]}
        </td>
        <td>
          {numbers.split(' ').map(it => {
            const isMatched = matchedNumbers.indexOf(it) >= 0
            return <span className={`text-${isMatched ? 'danger' : 'primary'}`}> &nbsp;{it} &nbsp;</span>
          })}
        </td>
        <td>{parsePlayMethod(playMethod)}</td>
        <td>{rewards[0].name}</td>
        <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(rewardAmount) || ""}</td>
      </tr>
    );
  }
  return null;
};

export default ({ numberRows }) => {
  return (
    <Table responsive className="align-items-center table-flush">
      <thead className="thead-light">
        <tr>
          <th scope="col">STT</th>
          <th scope="col">Dãy số</th>
          <th scope="col">Cách chơi</th>
          <th scope="col">Tên giải thưởng</th>
          <th scope="col">Giá trị</th>
        </tr>
      </thead>
      <tbody>
        {numberRows && numberRows.map((pv, index) => <TableRow rIndex={index} key={`rows-keys-lottmekong-${pv.id}`} {...pv} />)}
      </tbody>
    </Table>
  );
}

const parsePlayMethod = (method) => {
  switch (method) {
    case 'GROUP_18':
      return 'Bao 18'
    case 'GROUP_15':
      return 'Bao 15'
    case 'GROUP_14':
      return 'Bao 14'
    case 'GROUP_13':
      return 'Bao 13'
    case 'GROUP_12':
      return 'Bao 12'
    case 'GROUP_11':
      return 'Bao 11'
    case 'GROUP_10':
      return 'Bao 10'
    case 'GROUP_9':
      return 'Bao 9'
    case 'GROUP_8':
      return 'Bao 8'
    case 'GROUP_7':
      return 'Bao 7'
    case 'GROUP_5':
      return 'Bao 5'
    case 'GROUP_6':
      return 'Vé thường'
    default:
      break;
  }
}