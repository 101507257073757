
import React, { useEffect } from "react";
import { Col, Row } from '@themesberg/react-bootstrap';

import { AddRefundForm } from "./partials/AddRefundForm";

export default () => {

  useEffect(() => {
    document.title = "Thu hồi"
  }, []);

  return (
    <>
      <Row className="mt-4">
        <Col xs={12}>
          <AddRefundForm />
        </Col>
      </Row>
      
    </>
  );
};
