import React, { useRef, useState } from "react";
import { Button, Modal, Toast } from '@themesberg/react-bootstrap';
import { register } from "../../../../../controller/api/employee";
import { AddNewEmployeeForm } from "./partials/AddNewEmployeeForm";

export default (props) => {

  const [error, setError] = useState(false)

  const passwordRef = useRef('')

  const nameRef = useRef(0)

  const onNameChange = (text) => {
    nameRef.current = text
  }

  const onPasswordChange = (text) => {
    passwordRef.current = text
  }

  const validate = () => {
    return true
  }

  const onSubmit = async () => {
    try {
      if (validate()) {
        const resp = await register(nameRef.current, passwordRef.current)
        if (resp) {
          window.location.reload()
        } else {
          alert('Có lỗi xảy ra')
          return false
        }
      } else {
        setError(true)
      }
    } catch (e) {
      alert('error')
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Thêm nhân viên
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toast className="d-inline-block m-1" onClose={() => setError(false)} show={error} delay={3000} autohide bg='danger' key={3} >
          <Toast.Header>
            <strong className="me-auto">Lỗi</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Không thêm được</Toast.Body>
        </Toast>
        <AddNewEmployeeForm
          setPassword={onPasswordChange}
          setName={onNameChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button variant="primary" onClick={onSubmit}>Thêm</Button>
      </Modal.Footer>
    </Modal>
  );
}
