import React, { useCallback, useEffect, useRef } from "react";
import { Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap';
import { useCookies } from "react-cookie";
import { addResult } from "../../../../../controller/api/__classic__/result";

export default (props) => {

  const [cookies] = useCookies(['lottmekong'])

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const _onHide = () => {

    props.onHide()

  }

  const _db = useRef('');
  const _1 = useRef('');
  const _2 = useRef('');
  const _3_1 = useRef('');
  const _3_2 = useRef('');
  const _4_1 = useRef('');
  const _4_2 = useRef('');
  const _4_3 = useRef('');
  const _4_4 = useRef('');
  const _4_5 = useRef('');
  const _4_6 = useRef('');
  const _4_7 = useRef('');
  const _5 = useRef('');
  const _6_1 = useRef('');
  const _6_2 = useRef('');
  const _6_3 = useRef('');
  const _7 = useRef('');
  const _8 = useRef('');

  const _handleChangeDB = useCallback((payload) => {
    _db.current = payload.target.value
  }, [])
  const _handleChange1 = useCallback((payload) => {
    _1.current = payload.target.value
  }, [])
  const _handleChange2 = useCallback((payload) => {
    _2.current = payload.target.value
  }, [])
  const _handleChange31 = useCallback((payload) => {
    _3_1.current = payload.target.value
  }, [])
  const _handleChange32 = useCallback((payload) => {
    _3_2.current = payload.target.value
  }, [])
  const _handleChange41 = useCallback((payload) => {
    _4_1.current = payload.target.value
  }, [])
  const _handleChange42 = useCallback((payload) => {
    _4_2.current = payload.target.value
  }, [])
  const _handleChange43 = useCallback((payload) => {
    _4_3.current = payload.target.value
  }, [])
  const _handleChange44 = useCallback((payload) => {
    _4_4.current = payload.target.value
  }, [])
  const _handleChange45 = useCallback((payload) => {
    _4_5.current = payload.target.value
  }, [])
  const _handleChange46 = useCallback((payload) => {
    _4_6.current = payload.target.value
  }, [])
  const _handleChange47 = useCallback((payload) => {
    _4_7.current = payload.target.value
  }, [])
  const _handleChange5 = useCallback((payload) => {
    _5.current = payload.target.value
  }, [])
  const _handleChange61 = useCallback((payload) => {
    _6_1.current = payload.target.value
  }, [])
  const _handleChange62 = useCallback((payload) => {
    _6_2.current = payload.target.value
  }, [])
  const _handleChange63 = useCallback((payload) => {
    _6_3.current = payload.target.value
  }, [])
  const _handleChange7 = useCallback((payload) => {
    _7.current = payload.target.value
  }, [])
  const _handleChange8 = useCallback((payload) => {
    _8.current = payload.target.value
  }, [])

  useEffect(() => {
    if (props.result && props.result.length > 0) {
      const _result = props.result
      const g3 = _result[3].split(' ')
      const g4 = _result[4].split(' ')
      const g6 = _result[6].split(' ')

      _db.current = _result[0]
      _1.current = _result[1]
      _2.current = _result[2]
      _3_1.current = g3[0]
      _3_2.current = g3[1]
      _4_1.current = g4[0]
      _4_2.current = g4[1]
      _4_3.current = g4[2]
      _4_4.current = g4[3]
      _4_5.current = g4[4]
      _4_6.current = g4[5]
      _4_7.current = g4[6]
      _5.current = _result[5]
      _6_1.current = g6[0]
      _6_2.current = g6[1]
      _6_3.current = g6[2]
      _7.current = _result[7]
      _8.current = _result[8]
      forceUpdate()
    }

  }, [props.result, forceUpdate])

  const onSubmit = async () => {
    const result = [
      _db.current,
      _1.current,
      _2.current,
      _3_1.current + ' ' + _3_2.current,
      _4_1.current + ' ' + _4_2.current + ' ' + _4_3.current + ' ' + _4_4.current + ' ' + _4_5.current + ' ' + _4_6.current + ' ' + _4_7.current,
      _5.current,
      _6_1.current + ' ' + _6_2.current + ' ' + _6_3.current,
      _7.current,
      _8.current
    ]

    const resp = await addResult(cookies.lottmekong, props.id, result)
    if (resp) {
      props.callback(result)
      _onHide()
    } else {
      alert('Có lỗi xảy ra')
      return false
    }
  }

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={_onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nhập kết quả
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải đặc biệt</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_db.current || ''} placeholder="" onChange={_handleChangeDB} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải nhất</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_1.current} placeholder="" onChange={_handleChange1} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải nhì</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_2.current} placeholder="" onChange={_handleChange2} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải ba</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_3_1.current} placeholder="" onChange={_handleChange31} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_3_2.current} placeholder="" onChange={_handleChange32} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải tư</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_1.current} placeholder="" onChange={_handleChange41} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_2.current} placeholder="" onChange={_handleChange42} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_3.current} placeholder="" onChange={_handleChange43} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_4.current} placeholder="" onChange={_handleChange44} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_5.current} placeholder="" onChange={_handleChange45} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_6.current} placeholder="" onChange={_handleChange46} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_4_7.current} placeholder="" onChange={_handleChange47} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải năm</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_5.current} placeholder="" onChange={_handleChange5} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải sáu</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_6_1.current} placeholder="" onChange={_handleChange61} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_6_2.current} placeholder="" onChange={_handleChange62} />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_6_3.current} placeholder="" onChange={_handleChange63} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải bảy</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_7.current} placeholder="" onChange={_handleChange7} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group id="firstName">
              <Col md={12} className="mb-2">
                <Form.Label>Giải tám</Form.Label>
                <Row className="justify-content-center">
                  <Col md={3} className="mb-2">
                    <Form.Control required type="number" defaultValue={_8.current} placeholder="" onChange={_handleChange8} />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={_onHide}>Close</Button>
        {props.canUpdate &&
          <Button variant="primary" onClick={onSubmit}>Thêm</Button>
        }
      </Modal.Footer>
    </Modal>
  );
}