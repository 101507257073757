import React, { useContext } from 'react'
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap'
import { Context } from '../TransactionSummary'

const _Summary = () => {

  const { state } = useContext(Context);

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <Col>
            <Row>
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>Số lần thanh toán</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{state.summary.count}</Form.Label>
                </Row>
              </Col>
              <Col>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>Tổng tiền thanh toán</Form.Label>
                </Row>
                <Row md={12} className='text-center align-self-center'>
                  <Form.Label>{new Intl.NumberFormat('vn-VI', {}).format(state.summary.total) || ""}</Form.Label>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(_Summary)