
import React, {  } from "react";

export default () => {

  return (
    <>
      {/* <Col>
        <ResultItem isRollEnabled={isRollEnabled} />
      </Col> */}
    </>
  );
};
