import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { value, user, game, gamePeriod, status, onShowDetailPress, numberRows, id, deletedAt, reason, WorkTime } = props;

  const deletedDatedString = moment(deletedAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  return (
    <tr>
      <td>
        <Button variant="info" className="m-1" onClick={() => onShowDetailPress(id, numberRows, game.id, status)}>Chi tiết</Button>
      </td>
      <td>{id}</td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td className='text-center'>
        <img alt='' src={require(`../../../../assets/img/logo-${game.id}.png`)} className="img-thumbnail" width={90} height={30}></img>
      </td>
      <td className="align-items-right">
        {gamePeriod.name}
        <br />
        {gamePeriod.resultDate} {game.id === 'keno' ? gamePeriod.resultTime : ''}
      </td>
      <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td className="align-items-right">{status === 8 ? 'Đã hủy' : 'Trễ kỳ'}</td>
      <td>{reason}</td>
      <td>
        - NV: {WorkTime ? WorkTime.employee.name : ''}<br />
        - USB: {WorkTime ? WorkTime.device.code : ''}
      </td>
      <td>{deletedDatedString}</td>
    </tr>
  );
};
