import moment from 'moment';
import React from 'react';

export const TableRow = (props) => {
  const { transid, refid, total_, fee_, handleFee_, value_, method, subMethod, transDate } = props;

  return (
    <tr>
      <td className='text-center'>
        {transid}
      </td>
      <td className='text-center'>
        {refid}
      </td>
      <td className='text-center'>
        {method} - {subMethod}
      </td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(value_) || ""}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(fee_) || ""}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(handleFee_) || ""}</td>
      <td className='text-center text-success'>{new Intl.NumberFormat('vn-VI', {}).format(total_) || ""}</td>
      <td className="text-center">{moment(transDate || '').format('DD/MM/YYYY HH:mm:ss')}</td>
    </tr>
  );
};