import axios from 'axios'
import { host } from '../../helper/config'

const PLUGINS_NAME = 'admin-employees'

export const getAllEmployees = async (token) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-all`, {}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return {}
}

export const updateStatus = async (token, id, newStatus) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/update-status`, {
        id: id,
        status: newStatus
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const getWorkTimeByEmployeeId = async (token, id) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/get-work-time-by-employee-id`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const logoutWorkTimeById = async (token, id) => {
    const response = await axios.post(`${host}/${PLUGINS_NAME}/logout-work-time-by-id`, {
        id: id
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': token
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}

export const register = async (username, password) => {
    const response = await axios.post(`${host}/employee-lottmekong-register`, {
        username: username,
        password: password
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    })

    if (response && response.status === 200) {
        return response.data || {}
    }
    return false
}