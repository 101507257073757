import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { user, cartId, Channel, resultDate, status, createdAt, numbers, value, doneAt, LotoType, rewardValue } = props;


  const statusText = status === 1 ? 'Đã thanh toán' : status === 2 ? 'Đang chụp' : status === 3 ? 'Đã hoàn thành' : status === 4 ? 'Trúng thưởng' : status === 5 ? 'Không trúng' : 'Hủy'

  return (
    <tr>
      <td>
        {user.name || ""}<br />
        <span><strong className={`text-primary`}>{user.phone || ""}</strong></span>
      </td>
      <td className='text-center'>{cartId}</td>
      <td className="text-center">
        {Channel.name}<br />
        <span><strong className={`text-primary`}>{resultDate}</strong></span>
      </td>
      <td className="text-center">
        Lô: {LotoType}<br />
        số: {numbers}<br />
        giá vé: {new Intl.NumberFormat('vn-VI', {}).format(value)}
      </td>
      <td className={`text-center${status === 4 ? ' text-danger' : ''}`}>
        {statusText}<br />
        {status === 4 && new Intl.NumberFormat('vn-VI', {}).format(rewardValue)}
      </td>
      <td className='text-center'>{moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()}</td>
      <td className='text-center'>{doneAt ? moment(doneAt || '').format('DD/MM/YYYY HH:mm').toLocaleString() : ''}</td>
    </tr>
  );
};