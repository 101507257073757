import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react'

export const TableRow = (props) => {
  const { value, user, game, gamePeriod, cartId, createdAt, transactionDate, status, onShowDetailPress, numberRows, id, deletedAt, printedAt, WorkTime, images, TransactionPayReward = [] } = props;

  const datedString = moment(createdAt || '').format('DD/MM/YYYY HH:mm').toLocaleString()

  const transDatedString = transactionDate ? moment(transactionDate).format('DD/MM/YYYY HH:mm').toLocaleString() : ''

  const deletedDatedString = deletedAt ? moment(deletedAt || '').format('DD/MM/YYYY HH:mm').toLocaleString() : ''

  const printedDatedString = printedAt ? moment(printedAt || '').format('DD/MM/YYYY HH:mm').toLocaleString() : ''

  return (
    <tr>
      <td>
        <Button variant="info" className="m-1" onClick={() => onShowDetailPress(id, numberRows, game.id, gamePeriod.id, status, images, TransactionPayReward[0])}>Chi tiết</Button>
      </td>
      <td>{cartId}</td>
      <td>{id}</td>
      <td>
        {(user && user.name) || ""}<br />
        <span><strong className={`text-primary`}>{(user && user.phone) || ""}</strong></span>
      </td>
      <td>
        {game.name}
        <br />
        {gamePeriod.name}
        <br />
        {gamePeriod.resultDate} {game.id === 'keno' ? gamePeriod.resultTime : ''}
      </td>
      <td className="align-items-right">{new Intl.NumberFormat('vn-VI', {}).format(value) || ""}</td>
      <td>{status === 1 ? 'Đã thanh toán' : status === 2 ? 'Đang chờ in' : status === 3 ? 'Đã hoàn thành' : status === 4 ? 'Trúng thưởng' : status === 5 ? 'Không trúng' : status === 6 ? 'Đã hủy - Hoàn tiền' : status === 8 ? 'Nhân viên hủy' : status === 9 ? 'Đã in' : 'Đang chờ xử lý'}</td>
      <td>{datedString}</td>
      <td>{transDatedString}</td>
      <td>{deletedDatedString}</td>
      <td>
        {printedDatedString &&
          <>{printedDatedString}<br /></>
        }
      </td>
      <td className="text-center">
        {WorkTime &&
          <b>{WorkTime.employee.username}</b>
        }
      </td>
    </tr>
  );
};
