import React, { useContext, useRef, useState } from 'react'
import { Col, Row, Card, Table, Form, Button, Modal } from '@themesberg/react-bootstrap'
import { update } from '../../../../../../controller/api/withdraw-request'
import { useCookies } from 'react-cookie'
import { TableRow } from '../component/TableRow'
import Pagination from '../../../../../../components/Pagination'
import { Context } from '../WithdrawRequest'

const _Table = () => {

  const { state, dispatch } = useContext(Context);

  const [cookies] = useCookies(['lottmekong'])

  const [showMessageModal, setShowMessageModal] = useState(false)

  const messageRef = useRef('');

  const statusRef = useRef(0);

  const idRef = useRef(0);

  const confirmWithdraw = async (id) => {
    statusRef.current = 1
    const resp = await update(cookies.lottmekong, id, statusRef.current)

    if (resp) {
      const _clonedData = JSON.parse(JSON.stringify(state.data));

      _clonedData.list = _clonedData.list.map(it => {
        if (it.id === id) {
          return null
        }
        return it
      })

      _clonedData.list = _clonedData.list.filter(it => it !== null)

      dispatch({
        type: 'UPDATE_DATA',
        payload: _clonedData
      })

    } else {
      alert('Có lỗi xảy ra')
    }
  }

  const rejectWithdraw = (id) => {
    statusRef.current = 2
    idRef.current = id
    setShowMessageModal(true)
  }

  const onChangeMessage = (evt) => {
    messageRef.current = evt.target.value
  }

  const handleCommit = async () => {
    const resp = await update(cookies.lottmekong, idRef.current, statusRef.current, messageRef.current)
    if (resp) {
      const _clonedData = JSON.parse(JSON.stringify(state.data));

      _clonedData.list = _clonedData.list.map(it => {
        if (it.id === idRef.current) {
          return null
        }
        return it
      })

      _clonedData.list = _clonedData.list.filter(it => it !== null)

      dispatch({
        type: 'UPDATE_DATA',
        payload: _clonedData
      })

      setShowMessageModal(false)
    } else {
      alert('Có lỗi xảy ra')
    }
  }

  const handleClose = () => {
    messageRef.current = ''
    setShowMessageModal(false)
  }

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Table responsive className="align-items-center table-bordered table-hover">
          <thead className='text-center'>
            <tr>
              <th scope="col">Thao tác</th>
              <th scope="col">Số tiền</th>
              <th scope="col">Người chơi</th>
              <th scope="col">Thông tin đổi thưởng</th>
              <th scope="col">Lí do</th>
              <th scope="col">Thời gian</th>
            </tr>
          </thead>
          <tbody>
            {state.data.list && state.data.list.map((pv, index) => <TableRow onConfirm={confirmWithdraw} onReject={rejectWithdraw} rIndex={index} key={`table-keys-lottmekong-${pv.id}`} {...pv} />)}
          </tbody>
        </Table>
        <Card.Footer className='flex-row'>
          <Row className="align-items-center">
            <Col>
              <Pagination
                totalPage={state.data.totalPage || 0}
                currentPage={state.filter.page || 0}
                onPageChange={(i) => {
                  dispatch({
                    type: 'UPDATE_PAGE',
                    payload: i
                  })
                }}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Modal as={Modal.Dialog} centered show={showMessageModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Ghi chú</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control onChange={onChangeMessage} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCommit}>
            Thực hiện
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
            Hủy bỏ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(_Table)