
import React, { } from "react";
import { Col, Container, Nav, Row } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import { useCookies } from "react-cookie";
import { logout } from "../../controller/api/auth";
import { useHistory } from "react-router-dom";

export default () => {

  const [cookies, , removeCookie] = useCookies(['lottmekong']);

  const permission = localStorage.getItem('permission') ? localStorage.getItem('permission').split('') : []

  const history = useHistory()

  const handleLogout = async () => {
    try {
      await logout(cookies.lottmekong)
    } catch (error) {

    } finally {
      removeCookie('lottmekong')
      history.replace('/')
    }
  }

  return (
    <main style={{ height: '100vh' }}>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <h1 style={{ textAlign: 'center' }}>XIN VUI LÒNG CHỌN</h1>
          <Nav fill variant="pills" className="flex-column flex-lg-row">
            <Col md={12}>
              <Row>
                {permission[0] === '1' &&
                  <Col md={6}>
                    <Nav.Item>
                      <Nav.Link eventKey="home" href={Routes.CustomerOverview.path} className="mb-sm-3 mb-md-0" style={{ backgroundColor: 'brown' }}>
                        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                          KHÁCH HÀNG
                        </Container>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                }
                {permission[1] === '1' &&
                  <Col md={6}>
                    <Nav.Item>
                      <Nav.Link eventKey="home" href={Routes.SettingOverview.path} className="mb-sm-3 mb-md-0" style={{ backgroundColor: 'blue' }}>
                        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                          CÀI ĐẶT
                        </Container>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                }
                {permission[2] === '1' &&
                  <Col md={6}>
                    <Nav.Item>
                      <Nav.Link eventKey="home" href={Routes.VietlottOverview.path} className="mb-sm-3 mb-md-0" style={{ backgroundColor: 'red' }}>
                        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                          VIETLOTT
                        </Container>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                }
                {permission[3] === '1' &&
                  <Col lg={6}>
                    <Nav.Item>
                      <Nav.Link eventKey="settings" href={Routes.ClassicOverview.path} className="mb-sm-3 mb-md-0" style={{ backgroundColor: 'green' }}>
                        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                          XSKT
                        </Container>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                }
                {permission[4] === '1' &&
                  <Col lg={6}>
                    <Nav.Item>
                      <Nav.Link eventKey="settings" href={Routes.LotoOverview.path} className="mb-sm-3 mb-md-0" style={{ backgroundColor: 'orange' }}>
                        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                          LOTO
                        </Container>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                }
                <Col lg={6}>
                  <Nav.Item>
                    <Nav.Link eventKey="settings" onClick={handleLogout} className="mb-sm-3 mb-md-0" style={{ backgroundColor: 'grey' }}>
                      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, color: '#fff' }}>
                        ĐĂNG XUẤT
                      </Container>
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              </Row>
            </Col>
          </Nav>
        </Container>
      </section>
    </main>
  );
};
